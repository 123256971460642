import React, { useState, useEffect, useCallback } from 'react';
import './CreatePortfolio.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  Overview,
  PortfolioControl,
  PortfolioProjects,
  References,
  Languages,
  Certifications,
  WorkCultureComponent,
  Accomplishments,
  ProfessionalOrganizations,
} from '../index';
import {
  portfolioSwitchesMapping,
  requiredSections,
} from '../../config/portfolio-section-list';
import { Organization, Portfolio } from './types';
import {
  newAccomplishment,
  newCertification,
  newOrganization,
  newProject,
  newReference,
} from './initialValues';
import {
  addAccomplishmentsToPortfolioData,
  addCertificationsToPortfolioData,
  addLanguagesToPortfolioData,
  addOrganizationsToPortfolioData,
  addOverviewToPortfolioData,
  addProjectsToPortfolioData,
  addReferencesToPortfolioData,
  addWorkCultureToPortfolioData,
  calculatePercentage,
  canMoveToPreviousStep,
  isAccomplishmentFilled,
  isCertificationFilled,
  isLanguagesFilled,
  isLastStep,
  isOrganizationFilled,
  isOverviewFilled,
  isProjectFilled,
  isReferenceFilled,
  isWorkCultureFilled,
  prepareAndAppendFile,
  prepareAndAppendFiles,
} from './helpers';
import Preview from '../Preview';
import { API_ROUTES } from '../../config/api-routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { CURRENT_PLACEHOLDER_DATE } from '../../config/calendarMinMaxDates';
import axiosInstance from '../../api/api';

const CreatePortfolio: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [showEndDate, setShowEndDate] = useState<boolean[]>([true]);
  const [step, setStep] = useState<number>(0);
  const [switches, setSwitches] = useState<string[]>([
    'PortfolioControl',
    'Preview',
  ]);
  const [checkedSections, setCheckedSections] = useState<string[]>([]);
  const [percentage, setPercentage] = useState<number>(0);
  const [showCustomInput, setShowCustomInput] = useState<boolean>(false);
  const [customOptions, setCustomOptions] = useState<string[]>(['']);
  const [lastButton, setLastButton] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [updatedOrganizations, setUpdatedOrganizations] = useState<
    Organization[]
  >([newOrganization]);

  const [portfolio, setPortfolio] = useState<Portfolio>({
    overview: '',
    projects: [newProject],
    references: [newReference],
    languages: [],
    certifications: [newCertification],
    accomplishments: [newAccomplishment],
    organizations: [],
    workCulture: [],
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('mode') === 'edit';

  const initSwitches = (portfolio: Portfolio) => {
    const updatedCheckedSections: string[] = [];
    Object.entries(portfolio).forEach(([key, value]) => {
      if (value && portfolioSwitchesMapping[key]) {
        updatedCheckedSections.push(portfolioSwitchesMapping[key]);
      }
    });
    setCheckedSections(updatedCheckedSections);
  };

  const getPortfolioInfo = async () => {
    try {
      const response = await axiosInstance.get(
        API_ROUTES.PORTFOLIO.GET_PORTFOLIO,
      );

      if (!response || response.status !== 200) {
        setLoader(false);
        return;
      }
      const data = response.data;

      if (data?.portfolio?.id) {
        if (isEdit) {
          initSwitches(data.portfolio);
          setPortfolio(data.portfolio);
        } else {
          navigate('/user-profile');
        }
      }
    } catch (error: any) {
      console.error('Error fetching portfolio info:', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    getPortfolioInfo();
  }, []);

  useEffect(() => {
    const updatedOrganizationArray = updatedOrganizations.map(
      (organization) => {
        if (organization.endDate === '') {
          const { name, startDate, description, link } = organization;
          const updatedOrganization = {
            name: name,
            startDate: startDate,
            endDate: CURRENT_PLACEHOLDER_DATE,
            description: description,
            link: link,
          };
          return updatedOrganization;
        } else {
          return organization;
        }
      },
    );

    setPortfolio((prev) => ({
      ...prev,
      organizations: updatedOrganizationArray,
    }));
  }, [updatedOrganizations]);

  const isSectionFilled = useCallback(
    (section: string): boolean => {
      switch (section) {
        case 'Overview':
          return isOverviewFilled(portfolio.overview);
        case 'Projects':
          return portfolio.projects.every(isProjectFilled);
        case 'References':
          return portfolio.references.every(isReferenceFilled);
        case 'Languages':
          return portfolio.languages.every(isLanguagesFilled);
        case 'Certifications':
          return portfolio.certifications.every(isCertificationFilled);
        case 'Ideal Work Culture':
          return isWorkCultureFilled(portfolio.workCulture);
        case 'Accomplishments':
          return portfolio.accomplishments.every(isAccomplishmentFilled);
        case 'Professional Organizations':
          return portfolio.organizations.every(isOrganizationFilled);
        default:
          return false;
      }
    },
    [portfolio],
  );

  useEffect(() => {
    setPercentage(calculatePercentage(switches, step, isSectionFilled));
  }, [switches, step, isSectionFilled]);

  const handleNext = async () => {
    if (switches.length === 2) {
      return;
    }

    if (step === switches.indexOf('Preview') - 1) {
      setLastButton(true);
    }

    if (isLastStep(switches, step)) {
      completePortfolio();
      await sendFormData();
    } else if (canMoveToNextStep(switches, step)) {
      moveToNextStep();
    }
  };

  const handlePrevious = () => {
    if (canMoveToPreviousStep(step)) {
      moveToPreviousStep();
    }
  };

  const completePortfolio = () => {
    setPercentage(100);
    setPortfolio((prevData) => ({
      ...prevData,
      workCulture: prevData.workCulture.filter(
        (item) => item !== 'Other (Custom)',
      ),
    }));
  };

  const moveToNextStep = () => {
    setStep((prevStep) => prevStep + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const moveToPreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const canMoveToNextStep = (switches: string[], step: number) => {
    const isRequiredSectionIncomplete =
      requiredSections.includes(switches[step]) &&
      !isSectionFilled(switches[step]);
    return !isRequiredSectionIncomplete;
  };

  async function sendFormData() {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      return;
    }

    const portfolioData = new FormData();

    const {
      overview,
      projects,
      references,
      languages,
      certifications,
      workCulture,
      accomplishments,
      organizations,
    } = portfolio;

    const info = {
      overview: addOverviewToPortfolioData(overview),
      projects: addProjectsToPortfolioData(projects),
      references: addReferencesToPortfolioData(references),
      languages: addLanguagesToPortfolioData(languages),
      certifications: addCertificationsToPortfolioData(certifications),
      workCulture: addWorkCultureToPortfolioData(workCulture),
      accomplishments: addAccomplishmentsToPortfolioData(accomplishments),
      organizations: addOrganizationsToPortfolioData(organizations),
    };

    portfolioData.append('info', JSON.stringify(info));

    prepareAndAppendFiles(projects, info.projects, portfolioData);
    prepareAndAppendFiles(accomplishments, info.accomplishments, portfolioData);
    prepareAndAppendFile(certifications, info.certifications, portfolioData);

    setIsLoading(true);
    setError(false);

    try {
      const response = await axiosInstance.post(
        API_ROUTES.PORTFOLIO.CREATE_PORTFOLIO,
        portfolioData,
      );

      if (!response || response.status !== 200) {
        throw new Error('Failed to create portfolio');
      } else {
        navigate('/user-profile');
      }
    } catch (error: any) {
      setError(true);
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <main className="section-wrapper">
      {loader ? (
        <div className="prompt-spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {step !== 0 && (
            <ProgressBar
              now={percentage}
              variant="success"
              className="ProgressBar"
            />
          )}
          {step < 1 && (
            <PortfolioControl
              handleNext={handleNext}
              setSwitches={setSwitches}
              checkedSections={checkedSections}
              setCheckedSections={setCheckedSections}
              setPortfolio={setPortfolio}
              sections={switches}
              portfolioId={portfolio.id}
            />
          )}
          {switches.includes('Overview') &&
            step === switches.indexOf('Overview') && (
              <Overview
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                setOverview={setPortfolio}
                overview={portfolio.overview}
              />
            )}
          {switches.includes('Projects') &&
            step === switches.indexOf('Projects') && (
              <PortfolioProjects
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                projects={portfolio.projects}
                setProjects={setPortfolio}
              />
            )}
          {switches.includes('References') &&
            step === switches.indexOf('References') && (
              <References
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                references={portfolio.references}
                setReferences={setPortfolio}
              />
            )}
          {switches.includes('Languages') &&
            step === switches.indexOf('Languages') && (
              <Languages
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                setLanguages={setPortfolio}
                languages={portfolio.languages}
              />
            )}
          {switches.includes('Certifications') &&
            step === switches.indexOf('Certifications') && (
              <Certifications
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                setCertifications={setPortfolio}
                certifications={portfolio.certifications}
              />
            )}
          {switches.includes('Ideal Work Culture') &&
            step === switches.indexOf('Ideal Work Culture') && (
              <WorkCultureComponent
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                setWorkCulture={setPortfolio}
                setShowCustomInput={setShowCustomInput}
                showCustomInput={showCustomInput}
                customOptions={customOptions}
                workCulture={portfolio.workCulture}
                setCustomOptions={setCustomOptions}
              />
            )}
          {switches.includes('Accomplishments') &&
            step === switches.indexOf('Accomplishments') && (
              <Accomplishments
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                setAccomplishments={setPortfolio}
                accomplishments={portfolio.accomplishments}
              />
            )}
          {switches.includes('Professional Organizations') &&
            step === switches.indexOf('Professional Organizations') && (
              <ProfessionalOrganizations
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                setOrganizations={setUpdatedOrganizations}
                organizations={updatedOrganizations}
                setShowEndDate={setShowEndDate}
                showEndDate={showEndDate}
              />
            )}
          {switches.includes('Preview') &&
            step === switches.indexOf('Preview') && (
              <Preview
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                setPortfolio={setPortfolio}
                portfolio={portfolio}
                lastButton={lastButton}
                error={error}
                loader={isLoading}
              />
            )}
        </>
      )}
    </main>
  );
};

export default CreatePortfolio;
