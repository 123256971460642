import React, { useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { validationMessages } from '../../config/validationMessages';
import { Portfolio } from '../Create-Portfolio/types';
import PrevAndNextButtons from '../Prev-Next-Buttons';
import './Overview.css';

interface OverviewProps {
  handleNext: () => void;
  handlePrevious: () => void;
  setOverview: React.Dispatch<React.SetStateAction<Portfolio>>;
  overview: string;
}

const { PROVIDE_OVERVIEW } = validationMessages;

const Overview: React.FC<OverviewProps> = ({
  handleNext,
  handlePrevious,
  setOverview,
  overview,
}) => {
  const [validationError, setValidationError] = useState<string>('');

  const handleOverview = (overview: string) => {
    if (overview.length > 200) {
      setValidationError('Maximum 200 characters allowed');
    } else {
      setOverview((prevData) => ({
        ...prevData,
        overview: overview,
      }));

      if (overview.trim() !== '') {
        setValidationError('');
      }
    }
  };

  const validateForm = () => {
    if (overview.trim() === '') {
      setValidationError(`${PROVIDE_OVERVIEW}`);
      return false;
    }
    return true;
  };

  const onNextClick = () => {
    if (validateForm()) {
      handleNext();
    }
  };

  return (
    <section className="overview-container">
      <div className="container my-4">
        <h2>Overview</h2>
        <h6>
          A brief summary of your professional background, skills, and career
          goals. It serves as an introduction to who you are and what you bring
          to the table.
        </h6>
        <Form>
          <Form.Group className="mb-3">
            <Form.Control
              className="overview-textarea"
              as="textarea"
              rows={3}
              placeholder="Write a brief introduction about yourself here"
              value={overview ? overview : ''}
              onChange={(e) => handleOverview(e.target.value)}
              isInvalid={!!validationError}
            />
            {validationError && (
              <Alert variant="danger">{validationError}</Alert>
            )}
          </Form.Group>
        </Form>

        <PrevAndNextButtons
          handleNext={onNextClick}
          handlePrevious={handlePrevious}
        />
      </div>
    </section>
  );
};

export default Overview;
