import React, { useState, useEffect } from 'react';
import PrevAndNextButtons from '../Prev-Next-Buttons';
import TagListInput from '../Tag-List-Input';
import './Languages.css';
import { ReactComponent as CloseIcon } from '../../images/close-cross.svg';
import { Language, Portfolio } from '../Create-Portfolio/types';
import { levels } from '../../config/languages';
import {
  Card,
  Form,
  Button,
  Alert,
  Row,
  Col,
  Container,
} from 'react-bootstrap';
import { validationMessages } from '../../config/validationMessages';

interface LanguageProps {
  handleNext: () => void;
  handlePrevious: () => void;
  setLanguages: React.Dispatch<React.SetStateAction<Portfolio>>;
  languages: Language[];
}

const { CHOOSE_ONE_LANGUAGE, PROVIDE_LANGUAGE_LEVEL } = validationMessages;

const Languages: React.FC<LanguageProps> = ({
  handleNext,
  handlePrevious,
  setLanguages,
  languages,
}) => {
  const [validationError, setValidationError] = useState<string>('');
  const [levelsValidationError, setLevelsValidationError] =
    useState<string>('');
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [expandedStates, setExpandedStates] = useState<boolean[]>([]);

  useEffect(() => {
    if (languages.length > 0) {
      setValidationError('');
    }
  }, [languages]);

  useEffect(() => {
    const checkLevels = languages.every((lang) => lang.level !== '');
    if (checkLevels) {
      setLevelsValidationError('');
    }
  }, [languages]);

  const handleSelectedLanguage = (item: string) => {
    if (item) {
      const adjustedToLowerCase = item.toLowerCase();
      const adjustedFirstChar = adjustedToLowerCase.charAt(0).toUpperCase();
      const adjustedString = adjustedFirstChar + adjustedToLowerCase.slice(1);

      const thisLangIsInArray = languages.find(
        (lang) => lang.language === adjustedString,
      );

      if (thisLangIsInArray) {
        return; // If language exists, do nothing
      }

      const newItem = { level: '', language: adjustedString };

      setLanguages((prevPortfolio) => ({
        ...prevPortfolio,
        languages: [...languages, newItem], // Add new language
      }));

      setExpandedIndex(languages.length); // Expand the newly added language
    }
  };

  const toggleLanguageCard = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle the clicked card
  };

  const removeLanguage = (language: string) => {
    const updatedLanguages = languages.filter(
      (lang) => lang.language !== language,
    );

    setLanguages((prevPortfolio) => ({
      ...prevPortfolio,
      languages: updatedLanguages,
    }));
  };

  const selectLevel = (index: number, level: string) => {
    const updatedLanguages = languages.map((item, itemIndex) =>
      itemIndex === index ? { ...item, level } : item,
    );
    setLanguages((prevPortfolio) => ({
      ...prevPortfolio,
      languages: updatedLanguages,
    }));

    const checkLevels = updatedLanguages.every((lang) => lang.level !== '');
    if (checkLevels) {
      setLevelsValidationError('');
    }

    if (expandedIndex === index) {
      setExpandedIndex(null); // Collapse if already expanded
    } else {
      setExpandedIndex(index); // Expand the clicked card
    }
  };

  const validateForm = () => {
    const checkup = languages.some((lang) => lang.level === '');

    if (languages.length === 0) {
      setValidationError(`${CHOOSE_ONE_LANGUAGE}`);
      return false;
    }
    if (checkup) {
      setLevelsValidationError(`${PROVIDE_LANGUAGE_LEVEL}`);
      return false;
    }

    return true;
  };

  const onNextClick = () => {
    if (validateForm()) {
      handleNext();
    }
  };

  return (
    <section>
      <Container className="container my-4">
        <h2>Languages</h2>
        <h6>
          A section that highlights the languages you speak, along with your
          proficiency in each. It helps showcase your communication skills and
          ability to work in diverse environments.
        </h6>
        <TagListInput
          selectOption={handleSelectedLanguage}
          validationError={validationError}
        />
        <Row>
          <Col className="selected-languages-container">
            {languages.map((item, index) => (
              <Card
                key={index}
                border="secondary"
                className="portfolio-lang-card"
                style={{ marginBottom: '10px' }} // Add some space between cards
              >
                <Card.Header
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => toggleLanguageCard(index)} // Toggle expansion
                  style={{ cursor: 'pointer' }} // Make it clear it's clickable
                >
                  <Card.Title className="lang-text">{item.language}</Card.Title>
                  <div>
                    <Button
                      variant="link"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent collapse when clicking remove
                        removeLanguage(item.language);
                      }}
                    >
                      <CloseIcon style={{ width: '20px', height: '20px' }} />
                    </Button>
                  </div>
                </Card.Header>

                {/* Conditionally render Card.Body based on whether it's expanded or not */}
                {expandedIndex === index && (
                  <Card.Body>
                    <div>
                      <p>Choose your level:</p>
                      <Form>
                        {levels.map((level) => (
                          <Form.Check
                            type="radio"
                            label={level.level}
                            name={`level-${index}`}
                            key={level.level}
                            checked={item.level === level.level}
                            onChange={() => selectLevel(index, level.level)}
                          />
                        ))}
                      </Form>
                    </div>
                  </Card.Body>
                )}

                {/* Display the selected level when collapsed */}
                {expandedIndex !== index && (
                  <Card.Body>
                    <p>Level: {item.level || 'Not selected'}</p>
                  </Card.Body>
                )}
              </Card>
            ))}
          </Col>
        </Row>
        <Row>
          {levelsValidationError && (
            <Alert variant="danger" style={{ marginBottom: '50px' }}>
              {levelsValidationError}
            </Alert>
          )}
        </Row>
        <PrevAndNextButtons
          handleNext={onNextClick}
          handlePrevious={handlePrevious}
        />
      </Container>
    </section>
  );
};

export default Languages;
