import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import {
  Organization,
  PopupsForAddingState,
  PopupsState,
  ReturnedPortfolio,
} from '../Create-Portfolio/types';
import '../Preview/Preview.css';
import {
  CURRENT_PLACEHOLDER_DATE,
  maxDate,
  minDate,
} from '../../config/calendarMinMaxDates';
import {
  areObjectsEqual,
  formatDate,
  handleInputChange,
  handleNewItemFields,
} from '../UserProfile/helpers';
import { newOrganization } from '../Create-Portfolio/initialValues';

interface Props {
  index: number;
  portfolio: ReturnedPortfolio;
  setOpenedPopups: React.Dispatch<React.SetStateAction<PopupsState>>;
  setPopupsToAdd: React.Dispatch<React.SetStateAction<PopupsForAddingState>>;
  setPortfolioData: React.Dispatch<React.SetStateAction<ReturnedPortfolio>>;
  setSavePortfolioButton: React.Dispatch<React.SetStateAction<boolean>>;
  popupVariant: string;
}

const OrganizationsPopup: React.FC<Props> = ({
  index,
  portfolio,
  setOpenedPopups,
  setPopupsToAdd,
  setPortfolioData,
  setSavePortfolioButton,
  popupVariant,
}) => {
  const [organizations, setOrganizations] = useState(portfolio.organizations);
  const [organization, setNewOrganization] = useState<any>(newOrganization);
  const [isCurrent, setIsCurrent] = useState<boolean>(
    portfolio.organizations[index]?.endDate === CURRENT_PLACEHOLDER_DATE,
  );
  const [isNewSwitch, setNewSwitch] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(
    portfolio.organizations[index]?.startDate || '',
  );

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setPopupsToAdd((prev: PopupsForAddingState) => ({
          ...prev,
          organizations: false,
        }));

        setOpenedPopups((prev) => {
          const newOrganizationsPopups = [...prev.projects];
          newOrganizationsPopups[index] = false;
          return {
            ...prev,
            organizations: newOrganizationsPopups,
          };
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, setOpenedPopups, setPopupsToAdd, index]);

  useEffect(() => {
    setIsCurrent(
      portfolio.organizations[index]?.endDate === CURRENT_PLACEHOLDER_DATE,
    );
  }, [index, portfolio.organizations]);

  useEffect(() => {
    if (isNewSwitch) {
      setNewOrganization((prev: Organization) => ({
        ...prev,
        endDate: CURRENT_PLACEHOLDER_DATE,
      }));
    }
  }, [isNewSwitch]);

  const handleOrganizationSubmit = (index: number) => {
    if (organizations.length === undefined) {
      return;
    }

    if (areObjectsEqual(organizations[index], portfolio.organizations[index])) {
      return;
    }

    setPortfolioData((prev) => ({ ...prev, organizations: organizations }));
    setSavePortfolioButton(true);
    scrollToPortfolioButton();

    setOpenedPopups((prev) => {
      const newOrganizationsPopups = [...prev.projects];
      newOrganizationsPopups[index] = false;
      return {
        ...prev,
        organizations: newOrganizationsPopups,
      };
    });
  };

  const handleSubmitOfNewOrganization = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    const updatedOrganizations = [...organizations, organization];

    setOrganizations(updatedOrganizations);
    setPortfolioData((prev) => ({
      ...prev,
      organizations: updatedOrganizations,
    }));
    setSavePortfolioButton(true);
    scrollToPortfolioButton();

    setNewOrganization(newOrganization);

    setPopupsToAdd((prev: PopupsForAddingState) => ({
      ...prev,
      organizations: false,
    }));
  };

  const scrollToPortfolioButton = () => {
    const saveButton = document.getElementById('port-btn');
    if (saveButton) {
      saveButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleSwitch = () => {
    const updatedOrganizations = [...organizations];
    updatedOrganizations[index].endDate = isCurrent
      ? ''
      : CURRENT_PLACEHOLDER_DATE;
    setOrganizations(updatedOrganizations);
    setIsCurrent(!isCurrent);
  };

  const handleNewSwitch = () => {
    setNewSwitch((prev) => !prev);
  };

  const handleStartDateChange = (index: any, value: any) => {
    handleInputChange(
      index,
      'startDate',
      value,
      organizations,
      setOrganizations,
    );
    setStartDate(value);
  };

  const handleNewStartDateChange = (value: any) => {
    handleNewItemFields('startDate', value, setNewOrganization);
    setStartDate(value);
  };

  return (
    <Card
      className={`${popupVariant === 'adding' ? '' : 'user-popup'} p-3 popup-card`}
      ref={popupRef}
    >
      <Card.Body className="scrollable-card">
        {popupVariant !== 'adding' && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleOrganizationSubmit(index);
            }}
          >
            {/* Organization Name */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Name of organization
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  required
                  type="text"
                  defaultValue={portfolio.organizations[index].name}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'name',
                      e.target.value,
                      organizations,
                      setOrganizations,
                    )
                  }
                />
              </Col>
            </Form.Group>

            {/* Start and End Dates */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Start Date
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  required
                  type="date"
                  className="form-control"
                  defaultValue={formatDate(
                    portfolio.organizations[index].startDate,
                  )}
                  onChange={(e) => handleStartDateChange(index, e.target.value)}
                  min={minDate}
                  max={maxDate}
                />
              </Col>

              <Form.Label column sm={12}>
                End Date
              </Form.Label>
              {!isCurrent && (
                <Col sm={12}>
                  <Form.Control
                    required
                    type="date"
                    className="form-control"
                    defaultValue={formatDate(
                      portfolio.organizations[index].endDate,
                    )}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        'endDate',
                        e.target.value,
                        organizations,
                        setOrganizations,
                      )
                    }
                    min={startDate || minDate}
                    max={maxDate}
                  />
                </Col>
              )}

              <Col sm={12}>
                <Form.Check
                  type="switch"
                  label="Current"
                  checked={isCurrent}
                  onChange={handleSwitch}
                  value="Current"
                />
              </Col>
            </Form.Group>

            {/* Description */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Brief description
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  required
                  as="textarea"
                  max={50}
                  rows={6}
                  defaultValue={portfolio.organizations[index].description}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'description',
                      e.target.value,
                      organizations,
                      setOrganizations,
                    )
                  }
                />
              </Col>
            </Form.Group>

            {/* Organization Link */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Organization link
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  required
                  type="text"
                  defaultValue={portfolio.organizations[index].link}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'link',
                      e.target.value,
                      organizations,
                      setOrganizations,
                    )
                  }
                />
              </Col>
            </Form.Group>

            {/* Submit Button */}
            <Button variant="success" type="submit">
              Submit
            </Button>
          </Form>
        )}

        {/* Adding a New Organization */}
        {popupVariant === 'adding' && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitOfNewOrganization(e);
            }}
          >
            <Card.Title className="mb-3">Add organization</Card.Title>

            {/* Organization Name */}
            <Form.Group as={Row} className="mb-3">
              <Col sm={12}>
                <Form.Control
                  required
                  placeholder="Enter a name"
                  type="text"
                  onChange={(e) =>
                    handleNewItemFields(
                      'name',
                      e.target.value,
                      setNewOrganization,
                    )
                  }
                />
              </Col>
            </Form.Group>

            {/* Start and End Dates */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Start Date
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  required
                  type="date"
                  className="form-control"
                  onChange={(e) => handleNewStartDateChange(e.target.value)}
                  min={minDate}
                  max={maxDate}
                />
              </Col>

              <Form.Label column sm={12}>
                End Date
              </Form.Label>
              {!isNewSwitch && (
                <Col sm={12}>
                  <Form.Control
                    required
                    type="date"
                    className="form-control"
                    onChange={(e) =>
                      handleNewItemFields(
                        'endDate',
                        e.target.value,
                        setNewOrganization,
                      )
                    }
                    min={startDate || minDate}
                    max={maxDate}
                  />
                </Col>
              )}

              <Col sm={12}>
                <Form.Check
                  type="switch"
                  label="Current"
                  value="Current"
                  checked={isNewSwitch}
                  onChange={handleNewSwitch}
                />
              </Col>
            </Form.Group>

            {/* Description */}
            <Form.Group as={Row} className="mb-3">
              <Col sm={12}>
                <Form.Control
                  required
                  placeholder="Enter a description"
                  as="textarea"
                  max={50}
                  rows={3}
                  onChange={(e) =>
                    handleNewItemFields(
                      'description',
                      e.target.value,
                      setNewOrganization,
                    )
                  }
                />
              </Col>
            </Form.Group>

            {/* Organization Link */}
            <Form.Group as={Row} className="mb-3">
              <Col sm={12}>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter a link"
                  onChange={(e) =>
                    handleNewItemFields(
                      'link',
                      e.target.value,
                      setNewOrganization,
                    )
                  }
                />
              </Col>
            </Form.Group>

            {/* Submit Button */}
            <Button variant="success" type="submit">
              Submit
            </Button>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};

export default OrganizationsPopup;
