export const industries = [
  'Engineering',
  'Construction',
  'Education & Academic',
  'Energy, Environment, Sustainability & Utility',
  'Fashion & Apparel',
  'Finance & Banking',
  'Food & Agriculture',
  'Hospitality & Tourism',
  'Gaming & Esource',
  'Healthcare & Medical',
  'Information Technology',
  'Insurance',
  'Manufacturing & Industrial',
  'Oil & Gas',
  'Pharmaceutical & Biotechnology',
  'Professional Services (Law, Accounting, Consulting)',
  'Human Resources',
  'Real Estate & Property',
  'Research & Development',
  'Retail & Consumer Goods',
  'Sports & Recreation',
  'Telecommunication',
];
