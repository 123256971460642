import React, { useState, useRef, useEffect } from 'react';
import {
  Language,
  LeftSidePopupsForAdding,
  ReturnedPortfolio,
} from '../Create-Portfolio/types';
import { Col, Row, Form, Button, Card } from 'react-bootstrap';
import { levels } from '../../config/languages';
import { capitalizeFirstLetter } from '../UserProfile/helpers';
import TagListInput from '../Tag-List-Input';

interface Props {
  setPopupOpen: React.Dispatch<React.SetStateAction<LeftSidePopupsForAdding>>;
  setPortfolioData?: React.Dispatch<React.SetStateAction<ReturnedPortfolio>>;
  setSavePortfolioButton?: React.Dispatch<React.SetStateAction<boolean>>;
  languages: Language[];
}

const LanguagePopup: React.FC<Props> = ({
  setPopupOpen,
  languages,
  setPortfolioData,
  setSavePortfolioButton,
}) => {
  const [lang, setNewLanguage] = useState<Language>({
    language: '',
    level: '',
  });

  const popupRef = useRef<HTMLDivElement>(null);
  const [validationError, setValidationError] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [showTagList, setShowTagList] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setPopupOpen((prev) => ({ ...prev, languages: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, setPopupOpen]);

  useEffect(() => {
    if (languages.length > 0) {
      setValidationError('');
    }
  }, [languages]);

  const handleNewItemFields = (field: string, value: string) => {
    setNewLanguage((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const adjustedLanguage = capitalizeFirstLetter(lang.language);
    const adjustedLangItem = { ...lang, language: adjustedLanguage };
    const updatedLangArray = [...languages, adjustedLangItem];

    if (setPortfolioData && setSavePortfolioButton) {
      setPortfolioData((prev) => ({ ...prev, languages: updatedLangArray }));
      setSavePortfolioButton(true);
      scrollToPortfolioButton();
    }
    setPopupOpen((prev) => ({ ...prev, languages: !prev['languages'] }));
  };

  const scrollToPortfolioButton = () => {
    const saveButton = document.getElementById('port-btn');
    if (saveButton) {
      saveButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Card className="user-left-popup p-3" ref={popupRef}>
      <Card.Body className="scrollable-card">
        <Card.Title className="mb-3">Add language</Card.Title>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Form.Group as={Row} className="mb-3">
            <Col sm={12}>
              <Form.Control
                required
                type="text"
                disabled
                value={selectedLanguage} // Bind this to the selected language
                onChange={(e) => {
                  handleNewItemFields('language', e.target.value);
                  setSelectedLanguage(e.target.value);
                  setShowTagList(true);
                }}
              />
              {showTagList && (
                <TagListInput
                  selectOption={(selectedLanguage) => {
                    handleNewItemFields('language', selectedLanguage); // Still updating the selected language
                    setSelectedLanguage(selectedLanguage);
                    setShowTagList(false);
                  }}
                  validationError={validationError}
                />
              )}
            </Col>
          </Form.Group>
          <div className="mb-3">
            {levels &&
              levels.map((level) => (
                <Form.Check
                  type="radio"
                  label={level.level}
                  name="level"
                  key={level.level}
                  onChange={() => handleNewItemFields('level', level.level)}
                />
              ))}
          </div>

          <Button variant="success" type="submit">
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default LanguagePopup;
