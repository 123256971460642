import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { ReactComponent as CloseIcon } from '../../images/close-cross.svg';

interface TagListProps {
  skills: string[];
  onUpdateSkills: (updatedSkills: string[]) => void;
}

const SkillsInput: React.FC<TagListProps> = ({ skills, onUpdateSkills }) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (e.key === 'Enter') {
      e.preventDefault();

      const newSkill = target.value.trim();
      if (newSkill !== '' && !skills.includes(newSkill)) {
        onUpdateSkills([...skills, newSkill]);

        setInputValue('');
      }
    }
  };

  const handleSkillsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSkillRemoval = (index: number) => {
    const updatedSkills = skills.filter((_, i) => i !== index);
    onUpdateSkills(updatedSkills);
  };

  return (
    <>
      <Form.Control
        type="text"
        value={inputValue}
        onKeyDown={handleKeyDown}
        onChange={handleSkillsInputChange}
        placeholder="Enter a skill and press enter"
      />
      <div className="modal-skills-wrapper">
        {skills
          .filter((skill, index, array) => array.indexOf(skill) === index)
          .map((uniqueSkill, index) => (
            <div key={uniqueSkill} className="modal-skill-wrapper">
              <span
                style={{
                  marginRight: '5px',
                }}
              >
                {uniqueSkill}
              </span>
              <button
                type="button"
                className="modal-skills-cross-btn"
                onClick={() => handleSkillRemoval(index)}
              >
                <CloseIcon className="modal-skills-cross" />
              </button>
            </div>
          ))}
      </div>
    </>
  );
};

export default SkillsInput;
