import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {
  Container,
  Row,
  Col,
  Dropdown,
  Form,
  Button,
  Modal,
  Alert,
  Collapse,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import './Create-Prompt.css';
import { ReactComponent as EditIcon } from '../../images/pencil-square.svg';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';
import { ReactComponent as PlayIcon } from '../../images/play-fill.svg';
import { ReactComponent as ViewIcon } from '../../images/eye.svg';
import { primaryPrompts } from '../../config/generalPrompts';
import { industries } from '../../config/industries';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, API_ROUTES } from '../../config/api-routes';
import { validationMessages } from '../../config/validationMessages';
import PromptSelectionModal from '../PromptSelectionModal';
import RecordOrUploadModal from '../RecordOrUploadModal';
import UploadSupportingDocument from '../UploadSupportingDocument';
import PromptDescriptionModal from '../PromptDescriptionModal';
import { fetchPortfolioData } from '../UserProfile/api';

type LevelKeys = 'entry' | 'mid' | 'senior' | 'none' | '';

interface IErrors {
  noIndustry: boolean;
  noTitle: boolean;
  noLevel: boolean;
  videoFile: string;
}

type VideoFiles = {
  [key: number]: File;
};

const { PROVIDE_LEVEL, PROVIDE_JOB_TITLE, PROVIDE_INDUSTRY } =
  validationMessages;

const CreatePrompt: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [jobIndustry, setJobIndustry] = useState<string>('');
  const [jobTitle, setJobTitle] = useState<string>('');
  const [level, setLevel] = useState<LevelKeys>('');

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalStep, setModalStep] = useState<number>(1);
  const [recordOrUploadModal, setRecordOrUploadModal] =
    useState<boolean>(false);
  const [promptArray, setPromptArray] = useState<string[]>([...primaryPrompts]);

  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [showPortfolioModal, setShowPortfolioModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<IErrors>({
    noIndustry: false,
    noTitle: false,
    noLevel: false,
    videoFile: '',
  });
  const [openPromptIndex, setOpenPromptIndex] = useState<number | null>(null);
  const [videoFiles, setVideoFiles] = useState<VideoFiles>({});
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string | null>(null);
  const [documentFiles, setDocumentFiles] = useState<{ [key: number]: File }>(
    {},
  );
  const [descriptions, setDescriptions] = useState<{ [key: number]: string }>(
    {},
  );
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');
  const [open, setOpen] = useState(false);
  const [askAIRequestWorked, setAIRequestWorked] = useState(false);

  const [showWholePromptRemovalModal, setWholePromptRemovalModal] =
    useState(false);

  const [portfolioData, setPortfolioData] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (jobTitle !== '') {
      setErrors((prev) => ({ ...prev, noTitle: false }));
    }
  }, [jobTitle]);

  useEffect(() => {
    const getPortfolioData = async () => {
      try {
        if (localStorage.getItem('accessToken')) {
          const data = await fetchPortfolioData();
          setPortfolioData(data);
        }
      } catch (error: any) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate('/', { replace: true });
      }
    };

    getPortfolioData();
  }, [navigate]);

  const getPrompts = async () => {
    if (askAIRequestWorked && Object.keys(videoFiles).length > 0) {
      setLoader(false);
      return;
    }

    const accessToken = localStorage.getItem('accessToken');

    const response = await fetch(`${BASE_URL}prompts`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      setLoader(false);
      return;
    }

    const data = await response.json();

    if (data?.length > 0) {
      navigate('/user-profile');
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    getPrompts();
    setAIRequestWorked(true);
  }, [videoFiles]);

  const handleOpenDescriptionModal = (index: number) => {
    setCurrentDescription(descriptions[index] || '');
    setIsDescriptionModalOpen(true);
    setEditingIndex(index);
  };

  const handleCancelDescription = () => {
    setIsDescriptionModalOpen(false);
    setEditingIndex(null);
  };

  const togglePrompt = (index: number) => {
    if (openPromptIndex === index) {
      setOpenPromptIndex(null);
    } else {
      setOpenPromptIndex(index);
    }
  };

  const onModalClose = () => {
    setShowModal(false);
    setEditingIndex(null);
  };

  const onModalOpen = (index?: number) => {
    setModalStep(1);
    if (index || index === 0) {
      setEditingIndex(index);
      setShowModal(true);
    } else {
      setShowModal(true);
    }
  };

  const onSubmit = async () => {
    const formData = new FormData();
    const promptsData = promptArray.map((prompt, index) => ({
      content: prompt,
      description: descriptions[index] || '',
      jobTitle,
      jobIndustry,
      level,
      fileId: videoFiles[index] ? `file-${index}` : undefined,
      supportDocumentFileId: documentFiles[index] ? `doc-${index}` : undefined,
    }));

    formData.append('prompts', JSON.stringify(promptsData));

    Object.keys(videoFiles).forEach((key) => {
      const numericKey = parseInt(key, 10);
      if (videoFiles[numericKey]) {
        const file = videoFiles[numericKey];
        formData.append(`file-${key}`, file, file.name);
      }
    });

    Object.keys(documentFiles).forEach((keyString) => {
      const key = parseInt(keyString, 10);
      const file = documentFiles[key];
      if (file) {
        formData.append(`doc-${key}`, file, file.name);
      }
    });

    try {
      const accessToken = localStorage.getItem('accessToken');
      setLoader(true);

      const response = await fetch(`${BASE_URL}prompt`, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        setShowPortfolioModal(true);
      }
    } catch (error) {
      console.error('Network error:', error);
    } finally {
      setLoader(false);
    }
  };

  const handleDeletePrompt = (index: number) => {
    setOpenPromptIndex(null);

    if (promptArray.length > 4) {
      const updatedPrompts = promptArray.filter((_, i) => i !== index);
      setPromptArray(updatedPrompts);

      setVideoFiles((prevFiles: VideoFiles) => {
        const updatedFiles = { ...prevFiles };
        delete updatedFiles[index];

        const newFiles = Object.keys(updatedFiles).reduce(
          (acc: VideoFiles, key) => {
            const oldIndex = parseInt(key);
            const newIndex = oldIndex > index ? oldIndex - 1 : oldIndex;
            if (oldIndex > index) {
              acc[newIndex] = updatedFiles[oldIndex];
            } else {
              acc[oldIndex] = updatedFiles[oldIndex];
            }
            return acc;
          },
          {},
        );

        return newFiles;
      });
    }
  };

  const handleModalClose = () => {
    setRecordOrUploadModal(false);
  };

  const handlePromptSelect = (selectedPrompt: string) => {
    if (editingIndex || editingIndex === 0) {
      const updatedPrompts = [...promptArray];
      updatedPrompts[editingIndex] = selectedPrompt;
      setPromptArray(updatedPrompts);
      setShowModal(false);
      setModalStep(1);
      setEditingIndex(null);
    } else {
      setPromptArray([...promptArray, selectedPrompt]);
      setShowModal(false);
      setModalStep(1);
      setEditingIndex(null);
    }
  };

  const handleSelectIndustry = (eventKey: string | null) => {
    setJobIndustry(eventKey || '');
    setErrors((prev) => ({ ...prev, noIndustry: false }));
  };

  const handleChangeLevel = (level: LevelKeys) => {
    setLevel(level);
    setErrors((prev) => ({ ...prev, noLevel: false }));
  };

  const handleNextStep = async () => {
    let next;

    if (modalStep === 1) {
      if (jobIndustry === '' || jobTitle === '' || !level) {
        if (jobIndustry === '') {
          setErrors((prev) => ({ ...prev, noIndustry: true }));
        }

        if (jobTitle === '' && jobIndustry !== '') {
          setErrors((prev) => ({ ...prev, noTitle: true }));
        }

        if (!level && jobTitle !== '') {
          setErrors((prev) => ({ ...prev, noLevel: true }));
        }

        if (!next) {
          return;
        }

        return;
      }

      setCurrentStep(currentStep + 1);
    }

    const accessToken = localStorage.getItem('accessToken');
    setLoader(true);

    try {
      const response = await fetch(`${BASE_URL}${API_ROUTES.AI.ASK_QUESTION}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          question: `Give me the 4 most screening questions for ${jobTitle} (${jobIndustry}) for ${level} level role in a format of 1-4 without breaking them up into groups`,
        }),
      });

      const data = await response.json();

      if (response.ok && currentStep < 2) {
        setPromptArray((prevArray) => [...prevArray, ...data.answer]);
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      console.error('Network error:', error);
    } finally {
      setLoader(false);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 1) {
      setPromptArray([...primaryPrompts]);
      setCurrentStep(currentStep - 1);
    }
  };

  const handleDeleteWholePrompt = () => {
    setPromptArray([...primaryPrompts]);
    setVideoFiles({});
    setCurrentStep(1);
    setWholePromptRemovalModal(false);
  };

  const handleLevelExperience = (level: LevelKeys) => {
    switch (level) {
      case 'entry':
        return 'Entry Level (0-2 years)';
      case 'mid':
        return 'Mid Level (3-7 years)';
      case 'senior':
        return 'Senior Level (8+ years)';
      case 'none':
        return 'No Experience';
      default:
        return '';
    }
  };

  const navigateToUserProfile = () => {
    navigate('/user-profile');
  };

  const viewVideo = (index: number) => {
    const file = videoFiles[index];
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      setSelectedVideoUrl(videoUrl);
      setShowVideoPlayer(true);
    }
  };

  const closeVideoPlayer = () => {
    if (selectedVideoUrl) {
      URL.revokeObjectURL(selectedVideoUrl);
    }
    setShowVideoPlayer(false);
    setSelectedVideoUrl(null);
  };

  const allPromptsHaveVideos = () => {
    return promptArray.every((_, index) => videoFiles.hasOwnProperty(index));
  };

  return (
    <>
      {loader ? (
        <div className="prompt-spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Container className="form-infor-profile">
          <div className="prompt-header-container">
            {currentStep === 3 ? (
              <h2 className="title-dash flex2">My Screen Prompts</h2>
            ) : (
              <>
                <h2 className="title-dash flex2">Create Screening Prompts</h2>
              </>
            )}
            {currentStep === 2 && promptArray.length <= 5 && (
              <Button variant="success" onClick={() => onModalOpen()}>
                Add Prompt
              </Button>
            )}
          </div>
          {currentStep === 2 && (
            <h4 className="sub-text flex2">
              You can select between 4 and 6 prompts. To change your selections,
              click the edit button.
              <br></br>
              <p>Click the prompt to upload prompts.</p>
            </h4>
          )}
          {currentStep === 3 && (
            <h4 className="sub-text flex2">
              Click submit to save screening prompts.
            </h4>
          )}
          <div className="form-container">
            {currentStep === 1 && (
              <>
                <Row className="form-infor flex flat-form">
                  <Col sm={6} className="info-box info-wd">
                    <h4 className="title-user fw-7">Job Industry</h4>
                    <p className="subtitle">
                      Ensure the category you select aligns closely with the
                      specific job role you are applying for.
                    </p>
                  </Col>
                  <Col sm={6} className="info-box info-wd">
                    <Dropdown onSelect={handleSelectIndustry}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-industry"
                        className="prompt-dropdown-industries"
                      >
                        {jobIndustry || 'Select'}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          maxHeight: '300px',
                          overflowY: 'scroll',
                          width: '-webkit-fill-available',
                        }}
                      >
                        {industries.map((item) => (
                          <Dropdown.Item eventKey={item} key={item}>
                            {item}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    {errors.noIndustry && (
                      <Alert variant="danger" className="mt-2">
                        {PROVIDE_INDUSTRY}
                      </Alert>
                    )}
                  </Col>
                </Row>

                {jobIndustry && (
                  <Row className="form-infor flex flat-form">
                    <Col sm={6} className="info-box info-wd">
                      <h4 className="title-user fw-7">Update Job Title</h4>
                      <p className="subtitle">
                        Type the specific job role which is consistent with your
                        current role.
                      </p>
                    </Col>
                    <Col sm={6} className="info-box info-wd">
                      <Form.Control
                        type="text"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        placeholder="Enter job title"
                        required
                      />
                      {errors.noTitle && (
                        <Alert variant="danger" className="mt-2">
                          {PROVIDE_JOB_TITLE}
                        </Alert>
                      )}
                    </Col>
                  </Row>
                )}

                {jobTitle && (
                  <Row className="form-infor flex flat-form">
                    <Col sm={6} className="info-box info-wd">
                      <h4 className="title-user fw-7">Select Job Level</h4>
                      <p className="subtitle">
                        Select the level of the role that is consistent with
                        your experience and/or jobs you seek.
                      </p>
                    </Col>

                    <Col sm={6} className="info-box info-wd">
                      {(['entry', 'mid', 'senior', 'none'] as LevelKeys[]).map(
                        (currentLevel) => (
                          <Row
                            key={currentLevel}
                            style={{ display: 'flex', width: '100%' }}
                          >
                            <Col sm={1}>
                              <Form.Check
                                type="radio"
                                name="jobLevel"
                                checked={level === currentLevel}
                                onChange={() => handleChangeLevel(currentLevel)}
                                id={`${currentLevel}_level`}
                                isValid
                                className={`level_color`}
                              />
                            </Col>
                            <Col>
                              <span style={{ color: 'black' }}>
                                {handleLevelExperience(currentLevel)}
                              </span>
                            </Col>
                          </Row>
                        ),
                      )}
                      {errors.noLevel && (
                        <Alert variant="danger" className="mt-2">
                          {PROVIDE_LEVEL}
                        </Alert>
                      )}
                    </Col>
                  </Row>
                )}
              </>
            )}

            {currentStep === 2 && (
              <>
                {promptArray.map((item, index) => {
                  return (
                    <Row
                      className={`prompt-container-list ${index < 2 ? 'recommended-prompt' : ''}`}
                      key={index}
                    >
                      <Col sm={8} className="prompt-text-container-list">
                        <div className="prompt-header">
                          {index < 2 && (
                            <span className="star-icon">
                              ⭐ Most Recommended
                            </span>
                          )}
                          <p
                            className="prompt-text-list"
                            onClick={() => togglePrompt(index)}
                          >
                            {item}
                          </p>
                        </div>
                      </Col>
                      <Col sm={4} className="buttons-container-list">
                        {videoFiles[index] && (
                          <Button
                            className="prompt-button view-video-btn"
                            variant="primary"
                            onClick={() => viewVideo(index)}
                          >
                            <PlayIcon
                              width={22}
                              height={22}
                              className="prompt-edit-button"
                            />
                          </Button>
                        )}
                        <Button
                          className="prompt-button-list edit-btn-list"
                          variant="info"
                          onClick={() => onModalOpen(index)}
                        >
                          <EditIcon
                            width={22}
                            height={22}
                            className="prompt-edit-button"
                          />
                        </Button>
                        <Button
                          className="prompt-button-list delete-btn-lists"
                          variant="danger"
                          onClick={() => handleDeletePrompt(index)}
                        >
                          <DeleteIcon
                            width={22}
                            height={22}
                            className="prompt-delete-button"
                          />
                        </Button>
                      </Col>

                      {/* Modal for viewing and interacting with options */}
                      <Modal
                        show={openPromptIndex === index}
                        onHide={() => setOpenPromptIndex(null)}
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>{item}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="prompt-detail-container-list">
                            <button
                              onClick={() => {
                                setRecordOrUploadModal(true);
                                setEditingIndex(index);
                              }}
                              className="prompt-modal-btn"
                            >
                              Record/Upload Video Response
                            </button>
                            <button
                              onClick={() => viewVideo(index)}
                              disabled={!videoFiles[index]}
                              className="prompt-modal-btn"
                            >
                              View Video Response
                            </button>
                            <button
                              onClick={() => handleOpenDescriptionModal(index)}
                              className="prompt-modal-btn"
                            >
                              Enter/View Video Description
                            </button>

                            <UploadSupportingDocument
                              openPromptIndex={openPromptIndex}
                              documentFiles={documentFiles}
                              setDocumentFiles={setDocumentFiles}
                            />
                          </div>
                        </Modal.Body>
                      </Modal>
                    </Row>
                  );
                })}
              </>
            )}

            {currentStep === 3 && (
              <Row>
                <Col
                  sm={12}
                  className="p-4 prompt-info-color"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '3px solid lightgray',
                  }}
                >
                  <div>PROMPTS</div>
                  <div>ACTION</div>
                </Col>

                <Col sm={12} className="prompt-text-container modern-row">
                  <div className="prompt-info-color prompt-title">
                    <PlayIcon width={20} height={20} className="play-icon" />
                    {jobTitle}
                  </div>
                  <div className="action-buttons">
                    <button
                      className="prompt-expand-btn modern-btn"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      <ViewIcon width={30} height={30} />
                    </button>
                    <button
                      className="prompt-expand-btn modern-btn"
                      onClick={() => {
                        setWholePromptRemovalModal(true);
                      }}
                    >
                      <DeleteIcon width={30} height={30} />
                    </button>
                  </div>
                </Col>

                <Collapse in={open}>
                  <div id="example-collapse-text" className="modern-collapse">
                    {promptArray.map((item, index) => (
                      <div
                        key={index}
                        className="prompt-header-container p-4 prompt-divider modern-row"
                      >
                        <Col sm={11}>
                          <div>{item}</div>
                        </Col>
                        <div className="action-buttons">
                          <button
                            className="prompt-expand-btn modern-btn"
                            onClick={() => viewVideo(index)}
                          >
                            <PlayIcon width={30} height={30} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </Collapse>
              </Row>
            )}

            <div className="d-flex justify-content-between mt-4">
              {currentStep === 1 && (
                <Button variant="success" onClick={handleNextStep}>
                  Next
                </Button>
              )}
              {currentStep === 2 && (
                <>
                  <Button variant="success" onClick={handlePrevStep}>
                    Previous
                  </Button>
                  <div className="tooltip-container">
                    <Button
                      variant="success"
                      disabled={!allPromptsHaveVideos()}
                      onClick={handleNextStep}
                    >
                      Next
                    </Button>
                    {!allPromptsHaveVideos() && (
                      <span className="tooltip-text">
                        Upload videos for all prompts
                      </span>
                    )}
                  </div>
                </>
              )}
              {currentStep === 3 && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="success"
                    onClick={onSubmit}
                    style={{ backgroundColor: '#28a745' }}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Container>
      )}

      <Modal show={showModal} onHide={onModalClose} centered>
        <PromptSelectionModal
          jobIndustry={jobIndustry}
          jobTitle={jobTitle}
          level={level}
          onPromptSelect={handlePromptSelect}
        />
      </Modal>

      <Modal show={showPortfolioModal} centered>
        <Modal.Body className="modern-modal-body">
          <p className="modal-text">
            You have successfully created your screening Prompts!
          </p>
          {!portfolioData && (
            <>
              <p className="portfolio-explanation">
                Adding a portfolio allows you to showcase your best work and
                skills to potential employers or clients. It increases your
                chances of standing out and getting noticed.
              </p>
              <div className="prompt-modal-container-portfolio">
                <Button
                  variant="success"
                  className="modern-btn recommended-button"
                  onClick={() => {
                    setModalStep(2);
                    navigate('/create-portfolio');
                  }}
                >
                  Add Portfolio
                  <span className="recommended">⭐</span>
                </Button>

                <Button
                  variant="secondary"
                  className="modern-btn"
                  onClick={navigateToUserProfile}
                >
                  Not Now
                </Button>
              </div>
            </>
          )}
          {portfolioData && (
            <div className="prompt-modal-container-portfolio">
              <Button
                variant="secondary"
                className="modern-btn"
                onClick={navigateToUserProfile}
              >
                Done
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={recordOrUploadModal} onHide={handleModalClose} centered>
        <RecordOrUploadModal
          videoFiles={videoFiles}
          setVideoFiles={setVideoFiles}
          openPromptIndex={openPromptIndex}
          editingIndex={editingIndex}
          recordOrUploadModal={recordOrUploadModal}
          onModalClose={handleModalClose}
          errors={errors}
          setEditingIndex={setEditingIndex}
          setErrors={setErrors}
        />
      </Modal>

      <Modal show={showVideoPlayer} onHide={closeVideoPlayer} centered>
        <Modal.Body>
          {selectedVideoUrl && (
            <video
              controls
              autoPlay
              src={selectedVideoUrl}
              style={{ width: '100%' }}
            >
              Your browser does not support the video tag.
            </video>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={isDescriptionModalOpen}
        onHide={handleCancelDescription}
        centered
      >
        <PromptDescriptionModal
          onModalClose={handleCancelDescription}
          setDescriptions={setDescriptions}
          currentDescription={currentDescription}
          setCurrentDescription={setCurrentDescription}
          editingIndex={editingIndex}
        />
      </Modal>

      {showWholePromptRemovalModal && (
        <Modal
          show={showWholePromptRemovalModal}
          onHide={() => setWholePromptRemovalModal(false)}
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body
            style={{ overflowY: 'auto' }}
            className="prompt-modal-container p-5"
          >
            <div>
              <Row sm={12}>
                <Modal.Title className="mb-3">
                  <h4>
                    Are you sure you want to delete all prompts for {jobTitle}?
                  </h4>
                </Modal.Title>
                <Col
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    variant="success"
                    className="modal-sub-title"
                    onClick={handleDeleteWholePrompt}
                    style={{ marginRight: '10px' }}
                  >
                    Delete all
                  </Button>
                  <Button
                    variant="secondary"
                    className="modal-sub-title"
                    onClick={() => setWholePromptRemovalModal(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CreatePrompt;
