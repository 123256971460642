import React, { Dispatch, SetStateAction, useState } from 'react';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import PromptSelectionModal from '../PromptSelectionModal';
import UploadSupportingDocument from '../UploadSupportingDocument';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import RecordOrUploadModal from '../RecordOrUploadModal';
import PromptDescriptionModal from '../PromptDescriptionModal';
import Spinner from 'react-bootstrap/Spinner';
import axiosInstance from '../../api/api';

interface AddNewPromptStepperProps {
  prompts: any;
  setPromptsData: Dispatch<SetStateAction<any>>;
}

const AddNewPromptStepper: React.FC<AddNewPromptStepperProps> = ({
  prompts,
  setPromptsData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [promptText, setPromptText] = useState('');
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [errors, setErrors] = useState({});
  const [videoFiles, setVideoFiles] = useState<{
    [key: number]: File;
  }>({});
  const [documentFiles, setDocumentFiles] = useState<{ [key: number]: File }>(
    {},
  );
  const [currentDescription, setCurrentDescription] = useState('');
  const [loader, setLoader] = useState<boolean>(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentStep(1);
    setErrors({});
    setVideoFiles({});
    setDocumentFiles({});
    setPromptText('');
    setCurrentDescription('');
  };

  const handlePromptSelect = (prompt: string) => {
    setPromptText(prompt);
    setCurrentStep(2);
  };
  const handlePrevStep = () => setCurrentStep((step) => step - 1);
  const handleNextStep = () => setCurrentStep((step) => step + 1);

  const {
    id: editingIndex,
    jobTitle,
    jobIndustry,
    level,
  } = prompts[prompts.length - 1] || {};

  const onSubmit = async () => {
    const promptsData = [
      {
        content: promptText,
        description: currentDescription || '',
        jobTitle,
        jobIndustry,
        level,
        fileId: videoFiles[editingIndex] ? `file-${editingIndex}` : undefined,
        supportDocumentFileId: documentFiles[editingIndex]
          ? `doc-${editingIndex}`
          : undefined,
      },
    ];
    const formData = new FormData();

    formData.append('prompts', JSON.stringify(promptsData));

    Object.keys(videoFiles).forEach((key) => {
      const numericKey = parseInt(key, 10);
      if (videoFiles[numericKey]) {
        const file = videoFiles[numericKey];
        formData.append(`file-${key}`, file, file.name);
      }
    });

    Object.keys(documentFiles).forEach((keyString) => {
      const key = parseInt(keyString, 10);
      const file = documentFiles[key];
      if (file) {
        formData.append(`doc-${key}`, file, file.name);
      }
    });

    try {
      setLoader(true);

      const response = await axiosInstance.post('prompt', formData);

      const responseBody = response.data;

      if (response && response.status === 201) {
        setPromptsData((prev: any) => [
          ...prev,
          ...responseBody.createdPrompts,
        ]);
      }
      handleCloseModal();
    } catch (error) {
      console.error('Network error:', error);
    } finally {
      setLoader(false);
    }
  };

  const isDisabledCreate = prompts?.length >= 6;

  const isDisabledSubmit =
    (currentStep === 1 && !promptText) ||
    (currentStep === 2 && !Object.keys(videoFiles).length);

  const handleOpenPromptSelection = () => {
    if (!isDisabledCreate) {
      setCurrentDescription('');
      setShowModal(true);
    }
  };

  return (
    <>
      <div
        className={`user-info-edit-button pe-0 ${isDisabledCreate ? 'pe-none opacity-50' : ''}`}
        onClick={handleOpenPromptSelection}
      >
        <PlusIcon
          style={{ width: '25px', height: '25px', marginRight: '5px' }}
        />
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        {currentStep === 1 && (
          <PromptSelectionModal
            jobIndustry={jobIndustry}
            jobTitle={jobTitle}
            level={level}
            onPromptSelect={handlePromptSelect}
          />
        )}

        {currentStep === 2 && (
          <RecordOrUploadModal
            editingIndex={editingIndex}
            recordOrUploadModal={currentStep === 2}
            errors={errors}
            setErrors={setErrors}
            setVideoFiles={setVideoFiles}
            videoFiles={videoFiles}
            openPromptIndex={editingIndex}
            hideSubmit
          />
        )}

        {currentStep === 3 && (
          <>
            <Modal.Body
              style={{
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <UploadSupportingDocument
                documentFiles={documentFiles}
                setDocumentFiles={setDocumentFiles}
                openPromptIndex={editingIndex}
                variant="buttons"
              />
            </Modal.Body>
          </>
        )}

        {currentStep === 4 && (
          <PromptDescriptionModal
            currentDescription={currentDescription}
            setCurrentDescription={setCurrentDescription}
            editingIndex={editingIndex}
            hideFooter
          />
        )}

        {loader && (
          <div className="prompt-spinner">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        <Modal.Footer>
          <div className="d-flex justify-content-between w-100 g-10">
            {currentStep !== 1 ? (
              <Button variant="success" onClick={handlePrevStep}>
                Previous
              </Button>
            ) : (
              <div />
            )}
            <Button
              variant="success"
              onClick={currentStep === 4 ? onSubmit : handleNextStep}
              disabled={isDisabledSubmit}
            >
              {currentStep === 2 && 'Upload Documents'}
              {currentStep === 3 && 'Enter Prompt Description (or skip)'}
              {currentStep === 4
                ? 'Save'
                : currentStep !== 2 && currentStep !== 3 && 'Next'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNewPromptStepper;
