import React, { ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { fetchUserData } from '../Preview/api';
import Spinner from 'react-bootstrap/Spinner';

type Props = {
  children: ReactElement;
};

const PrivateRouteForSettings: React.FC<Props> = ({ children }) => {
  const [loader, setLoader] = useState<boolean>(true);
  const [userData, setUserData] = useState<boolean>(false);

  useEffect(() => {
    const checkUserData = async () => {
      try {
        const userData = await fetchUserData();

        if (userData.username && userData.profileImg) {
          setUserData(true);
        } else {
          setUserData(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setUserData(false);
      } finally {
        setLoader(false);
      }
    };

    checkUserData();
  }, []);

  if (loader) {
    return (
      <div className="prompt-spinner">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return userData ? children : <Navigate to="/" replace />;
};

export default PrivateRouteForSettings;
