import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Form, Button, Card, Alert } from 'react-bootstrap';
import {
  PopupsForAddingState,
  PopupsState,
  ReturnedPortfolio,
} from '../Create-Portfolio/types';
import '../Preview/Preview.css';
import {
  useFileAmountValidation,
  useFileSizeValidation,
} from '../Create-Portfolio/customHooks';
import { validationMessages } from '../../config/validationMessages';
import { handleNewItemFields } from '../UserProfile/helpers';
import { newlyAddedProject } from '../Create-Portfolio/initialValues';
import FileUploadComponent from '../FileUploadButton/SingleFileUploadButton';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';
import { v4 as uuidv4 } from 'uuid';
import { API_ROUTES } from '../../config/api-routes';
import axiosInstance from '../../api/api';

interface Props {
  index: number;
  portfolio: ReturnedPortfolio;
  setOpenedPopups: React.Dispatch<React.SetStateAction<PopupsState>>;
  setPopupsToAdd: React.Dispatch<React.SetStateAction<PopupsForAddingState>>;
  setPortfolioData: React.Dispatch<React.SetStateAction<ReturnedPortfolio>>;
  setSavePortfolioButton: React.Dispatch<React.SetStateAction<boolean>>;
  savePortfolioButton: boolean;
  popupVariant: string;
  getData: () => void;
}

const ProjectsPopup: React.FC<Props> = ({
  portfolio,
  setOpenedPopups,
  setPopupsToAdd,
  setPortfolioData,
  setSavePortfolioButton,
  savePortfolioButton,
  index,
  popupVariant,
  getData,
}) => {
  const [projects, setProjects] = useState(portfolio.projects);
  const [project, setNewProject] = useState<any>(newlyAddedProject);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [success, setAddingFileSuccess] = useState<boolean>(false);
  const [sizeError, setSizeError] = useState<boolean>(false);

  const popupRef = useRef<HTMLDivElement>(null);

  const { fileError } = useFileAmountValidation(
    projects[index]?.filesUrl,
    index,
  );

  const errorWithSizeInNewProject = useFileSizeValidation(
    project.filesUrl,
    index,
  );

  const errorWithAmountInNewProject = useFileAmountValidation(
    project.filesUrl,
    index,
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setPopupsToAdd((prev: PopupsForAddingState) => ({
          ...prev,
          projects: false,
        }));

        setOpenedPopups((prev) => {
          const newProjectsPopups = [...prev.projects];
          newProjectsPopups[index] = false;
          return {
            ...prev,
            projects: newProjectsPopups,
          };
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, setOpenedPopups, setPopupsToAdd, index]);

  const handleProjectSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    index: number,
  ) => {
    e.preventDefault();

    if (projects.length === undefined) {
      return;
    }

    setSavePortfolioButton(true);
    scrollToPortfolioButton();

    setPortfolioData((prev) => ({ ...prev, projects }));
    setOpenedPopups((prev) => {
      const newProjectsPopups = [...prev.projects];
      newProjectsPopups[index] = false;
      return {
        ...prev,
        projects: newProjectsPopups,
      };
    });
  };

  const scrollToPortfolioButton = () => {
    const saveButton = document.getElementById('port-btn');
    if (saveButton) {
      saveButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleSubmitOfNewProject = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updatedProjects = [...projects, project];

    setPortfolioData((prev) => ({ ...prev, projects: updatedProjects }));
    setSavePortfolioButton(true);
    scrollToPortfolioButton();

    setPopupsToAdd((prev: PopupsForAddingState) => ({
      ...prev,
      projects: false,
    }));
  };

  const handleDeleteFile = (projectIndex: number, fileIndex: number) => {
    const updatedFiles = projects[projectIndex].filesUrl.filter(
      (_, index: number) => index !== fileIndex,
    );

    const updatedItem = {
      ...projects[projectIndex],
      filesUrl: updatedFiles,
    };

    const updatedItems = [...projects];
    updatedItems[projectIndex] = updatedItem;

    setProjects(updatedItems);
    setDisabled(false);
    setSavePortfolioButton(true);
  };

  const handleInputChange = (
    index: number,
    field: string,
    value: string,
    array: any,
    setItems: React.Dispatch<React.SetStateAction<any>>,
  ) => {
    const itemIndex = array.findIndex(
      (_: any, prIndex: number) => prIndex === index,
    );

    if (itemIndex !== -1) {
      const updatedItems = [...array];
      updatedItems[itemIndex] = {
        ...updatedItems[itemIndex],
        [field]: value,
      };
      setItems(updatedItems);
      setDisabled(false);
      setSavePortfolioButton(true);
    }
  };

  const handleFileChange = async (index: number, file: File, array: any[]) => {
    const maxSizeInBytes = 10 * 1024 * 1024;

    if (file.size >= maxSizeInBytes) {
      setSizeError(true);
      return;
    }

    const data: FormData = new FormData();

    const info = {
      project: { id: array[index].id, fileId: uuidv4() },
    };

    data.append('info', JSON.stringify(info));

    data.append(info.project.fileId, file, file.name);

    try {
      const response = await axiosInstance.post(
        API_ROUTES.PORTFOLIO.UPLOAD_FILES,
        data,
      );

      if (!response || response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setAddingFileSuccess(true);
      await response.data;
      getData();
    } catch (error) {
      console.error('Failed to upload portfolio files:', error);
    }
  };

  return (
    <Card
      className={`${popupVariant === 'adding' ? '' : 'user-popup'} p-3 popup-card`}
      ref={popupRef}
    >
      <Card.Body className="scrollable-card">
        {popupVariant !== 'adding' && (
          <Form onSubmit={(e) => handleProjectSubmit(e, index)}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column xs={12}>
                Project Name
              </Form.Label>
              <Col xs={12}>
                <Form.Control
                  type="text"
                  required
                  defaultValue={portfolio.projects[index].name}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'name',
                      e.target.value,
                      projects,
                      setProjects,
                    )
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column xs={12}>
                Description
              </Form.Label>
              <Col xs={12} style={{ position: 'relative' }}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  className="popup-textarea"
                  required
                  maxLength={50}
                  defaultValue={portfolio.projects[index].description}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'description',
                      e.target.value,
                      projects,
                      setProjects,
                    )
                  }
                />
                <span className="textarea-placeholder">Max: 50 characters</span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column xs={12}>
                Upload Supporting Files
              </Form.Label>
              <Col xs={12} className="file-upload-section">
                {projects[index].filesUrl &&
                  projects[index].filesUrl.map((fileItem: any, fileIndex) => {
                    const fileName = fileItem?.name
                      ? fileItem.name.replace(/_/g, ' ')
                      : fileItem.toString().replace(/_/g, ' ').split('/').pop();

                    return (
                      <div className="selected-file" key={fileIndex}>
                        <p className="file-name">{fileName}</p>
                        <Button
                          variant="danger"
                          className="prompt-delete-button"
                          onClick={() => handleDeleteFile(index, fileIndex)}
                        >
                          <DeleteIcon
                            style={{ width: '20px', height: '20px' }}
                          />
                        </Button>
                      </div>
                    );
                  })}
                {!savePortfolioButton &&
                  projects[index].filesUrl.length < 2 && (
                    <div className="select-project-file">
                      <FileUploadComponent
                        handleFileChange={(file) =>
                          handleFileChange(index, file, projects)
                        }
                      />
                    </div>
                  )}
                {fileError && <Alert variant="danger">{fileError}</Alert>}
                {sizeError && (
                  <Alert variant="danger">
                    {validationMessages.FILES_EXCEEDED_LIMIT_10MB}
                  </Alert>
                )}
              </Col>
            </Form.Group>
            <Button variant="success" type="submit" disabled={disabled}>
              Submit
            </Button>
          </Form>
        )}
        {popupVariant === 'adding' && (
          <Form onSubmit={(e) => handleSubmitOfNewProject(e)}>
            <Card.Title className="mb-3">Add Project</Card.Title>
            <Form.Group as={Row} className="mb-3">
              <Col xs={12}>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter a name"
                  onChange={(e) =>
                    handleNewItemFields('name', e.target.value, setNewProject)
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col xs={12} style={{ position: 'relative' }}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  maxLength={50}
                  placeholder="Enter a description"
                  onChange={(e) =>
                    handleNewItemFields(
                      'description',
                      e.target.value,
                      setNewProject,
                    )
                  }
                />
                <span className="textarea-placeholder">Max: 50 characters</span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col xs={12} className="file-upload-section">
                <Button disabled variant="secondary-outlined">
                  To add file please save portfolio first.
                </Button>
                {/* {errorWithAmountInNewProject.fileError && (
                  <Alert variant="danger">{fileError}</Alert>
                )}
                {errorWithSizeInNewProject.sizeError && (
                  <Alert variant="danger">
                    {validationMessages.FILES_EXCEEDED_LIMIT_10MB}
                  </Alert>
                )} */}
              </Col>
            </Form.Group>
            <Button variant="success" type="submit">
              Submit
            </Button>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProjectsPopup;
