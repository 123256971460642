import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Alert, Spinner, Collapse } from 'react-bootstrap';
import { maxDate, minBirthDate } from '../../config/calendarMinMaxDates';
import { validationMessages } from '../../config/validationMessages';
import { API_ROUTES, BASE_URL } from '../../config/api-routes';
import 'react-phone-input-2/lib/style.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './CompleteProfile.css';
import axiosInstance from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

const CompleteProfile = () => {
  const [profile, setProfile] = useState({
    step: 0,
    firstName: '',
    lastName: '',
    company: '',
    phoneNumber: '',
    gender: '',
    dateOfBirth: '',
    username: '',
    country: '',
    state: '',
    city: '',
    linkedin: '',
    otherLink: '',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [countries, setCountries] = useState<string[]>([]);
  const [countryError, setCountryError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [openBasicInfo, setOpenBasicInfo] = useState(true);
  const [openContactInfo, setOpenContactInfo] = useState(false);
  const [openAdditionalInfo, setOpenAdditionalInfo] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [gender, setGender] = useState(profile.gender || '');
  const [otherGender, setOtherGender] = useState('');

  const navigate = useNavigate();

  const getUserInfo = async () => {
    try {
      const response = await axiosInstance.get('user/current-user');

      if (!response || response.status !== 200) {
        setLoader(false);
        return;
      }

      const data = response.data;

      if (
        data?.user?.firstName &&
        data?.user?.lastName &&
        data?.user?.username
      ) {
        navigate('/user-profile');
      }
    } catch (error: any) {
      console.error('Error fetching user info:', error);
    } finally {
      setLoader(false);
    }
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === 'Other') {
      setIsOtherSelected(true);
      setGender(value);
    } else {
      setIsOtherSelected(false);
      setGender(value);
    }
    setProfile({ ...profile, gender: value });
  };

  const handleOtherGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherGender(e.target.value);
    setProfile({ ...profile, gender: e.target.value });
  };

  const handleReset = () => {
    setIsOtherSelected(false);
    setOtherGender('');
    setGender('');
    setProfile({ ...profile, gender: '' });
  };

  const getCountries = async () => {
    try {
      const response = await axiosInstance.get('auth/get-countries');

      if (!response || response.status !== 200) {
        setLoader(false);
        return;
      }
      const data = response.data;

      setCountries(data);
    } catch (error: any) {
      console.error('Error fetching countries:', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    getCountries();
    getUserInfo();
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handlePhoneChange = (value: string) => {
    setProfile((prevProfile) => ({ ...prevProfile, phoneNumber: value }));

    setPhoneError(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const minLength = 10;
    const accessToken = localStorage.getItem('accessToken');

    if (profile.phoneNumber.length < minLength) {
      setPhoneError(true);
      return;
    }

    const countryCheckup = countries.some(
      (country) => country === profile.country,
    );

    if (!countryCheckup) {
      setCountryError(true);
      return;
    }

    setLoader(true);

    try {
      const response = await fetch(
        `${BASE_URL}${API_ROUTES.AUTH.COMPLETE_PROFILE}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            ...profile,
            dateOfBirth: moment(profile.dateOfBirth).format('MM-DD-YYYY'),
            step: 1,
          }),
        },
      );

      const responseBody = await response.json();

      if (!response.ok) {
        if (
          responseBody.message.includes(
            'duplicate key value violates unique constraint',
          )
        ) {
          setUsernameError(true);
          setError(false);
        }

        if (
          !responseBody.message.includes(
            'duplicate key value violates unique constraint',
          )
        ) {
          setError(true);
        }

        throw new Error(`Failed to complete profile: ${response.statusText}`);
      }

      navigate('/complete-profile-avatar', { replace: true });
      setError(false);
      setUsernameError(false);
    } catch (error: any) {
      console.error('Error:', error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="container my-4">
      {loader ? (
        <div className="prompt-spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <section className="pt-3 pb-3">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h1
                    className="d-inline-block ms-3"
                    style={{ position: 'relative' }}
                  >
                    <span
                      className="d-inline-block"
                      style={{
                        width: '4px',
                        height: '100%',
                        backgroundColor: '#28a745',
                        position: 'absolute',
                        left: '-20px',
                        top: '0',
                      }}
                    ></span>
                    Complete Profile
                  </h1>
                </div>
              </div>
            </div>
          </section>
          <form onSubmit={handleSubmit}>
            <div className="card mb-4">
              <div
                className="card-header d-flex justify-content-between align-items-center"
                onClick={() => setOpenBasicInfo(!openBasicInfo)}
                aria-controls="basicInfo"
                aria-expanded={openBasicInfo}
                style={{ cursor: 'pointer' }}
              >
                {' '}
                Basic Information{' '}
                <FontAwesomeIcon
                  icon={openBasicInfo ? faChevronUp : faChevronDown}
                />
              </div>
              <Collapse in={openBasicInfo}>
                <div className="card-body" id="basicInfo">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={profile.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        value={profile.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="phoneNumber" className="form-label">
                        Phone Number
                      </label>
                      <PhoneInput
                        country={'us'}
                        value={profile.phoneNumber}
                        onChange={handlePhoneChange}
                        inputProps={{
                          name: 'phoneNumber',
                          required: true,
                          autoFocus: false,
                        }}
                        inputClass="form-control"
                        containerClass="phone-input"
                      />
                      {phoneError && (
                        <Alert variant="danger" style={{ marginTop: '5px' }}>
                          {validationMessages.PHONE_LENGTH}
                        </Alert>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="username" className="form-label">
                        User Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        name="username"
                        value={profile.username}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="gender" className="form-label">
                        Gender
                      </label>
                      {isOtherSelected ? (
                        <div className="d-flex align-items-center">
                          <button
                            type="button"
                            className="btn btn-outline-secondary me-2"
                            onClick={handleReset}
                            style={{ padding: '0.375rem 0.75rem' }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                          <input
                            type="text"
                            className="form-control"
                            id="otherGender"
                            name="gender"
                            placeholder="Please specify"
                            value={otherGender}
                            onChange={handleOtherGenderChange}
                            required
                          />
                        </div>
                      ) : (
                        <select
                          className="form-select"
                          id="gender"
                          name="gender"
                          value={gender}
                          onChange={handleGenderChange}
                          required
                        >
                          <option value="">Select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="dateOfBirth" className="form-label">
                        Date Of Birth
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={profile.dateOfBirth}
                        onChange={handleChange}
                        required
                        min={minBirthDate}
                        max={maxDate}
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="company" className="form-label">
                        Current Company
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="company"
                        name="company"
                        value={profile.company}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="card mb-4">
              <div
                className="card-header d-flex justify-content-between align-items-center"
                onClick={() => setOpenContactInfo(!openContactInfo)}
                aria-controls="contactInfo"
                aria-expanded={openContactInfo}
                style={{ cursor: 'pointer' }}
              >
                Contact Information
                <FontAwesomeIcon
                  icon={openContactInfo ? faChevronUp : faChevronDown}
                />
              </div>
              <Collapse in={openContactInfo}>
                <div className="card-body" id="contactInfo">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <Typeahead
                        id="country"
                        options={countries}
                        selected={profile.country ? [profile.country] : []}
                        onChange={(selected) => {
                          setCountryError(false);
                          const country =
                            selected.length > 0 ? selected[0] : '';
                          setProfile({
                            ...profile,
                            country: country as string,
                          });
                        }}
                        placeholder="Choose a country..."
                        clearButton
                        className="typeahead"
                      />
                      {countryError && (
                        <Alert
                          variant="danger"
                          style={{ marginBottom: '50px' }}
                        >
                          Please choose a country.
                        </Alert>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="state" className="form-label">
                        State
                      </label>
                      <input
                        className="form-control"
                        id="state"
                        name="state"
                        value={profile.state}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="city" className="form-label">
                        City
                      </label>
                      <input
                        className="form-control"
                        id="city"
                        name="city"
                        value={profile.city}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="card mb-4">
              <div
                className="card-header d-flex justify-content-between align-items-center"
                onClick={() => setOpenAdditionalInfo(!openAdditionalInfo)}
                aria-controls="additionalInfo"
                aria-expanded={openAdditionalInfo}
                style={{ cursor: 'pointer' }}
              >
                Social Profile Links
                <FontAwesomeIcon
                  icon={openAdditionalInfo ? faChevronUp : faChevronDown}
                />
              </div>
              <Collapse in={openAdditionalInfo}>
                <div className="card-body" id="additionalInfo">
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label htmlFor="linkedin" className="form-label">
                        LinkedIn
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="linkedin"
                        name="linkedin"
                        value={profile.linkedin}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="otherLink" className="form-label">
                        Other
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="otherLink"
                        name="otherLink"
                        value={profile.otherLink}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
            {error && (
              <Alert variant="danger" style={{ marginBottom: '50px' }}>
                {validationMessages.DATA_FETCH_FAILED}
              </Alert>
            )}
            {usernameError && (
              <Alert variant="danger" style={{ marginBottom: '50px' }}>
                {validationMessages.USERNAME_ERROR}
              </Alert>
            )}
            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-success">
                Next
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default CompleteProfile;
