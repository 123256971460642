export const workCultureList = [
  {
    name: 'Innovative / Creative',
    description:
      'Thrives in environments that encourage new ideas and creativity.',
  },
  {
    name: 'Collaborative / Team-Oriented',
    description:
      'Thrives in environments that encourage new ideas and creativity.',
  },
  {
    name: 'Independent / Autonomy',
    description:
      'Seeks roles with independence and autonomy in decision-making.',
  },
  {
    name: 'Fast-Paced / Dynamic',
    description:
      'Enjoys a fast-moving environment with constant changes and challenges.',
  },
  {
    name: 'Stable / Structured',
    description:
      'Appreciates predictable, well-organized work settings with clear expectations.',
  },
  {
    name: 'Remote-Friendly / Flexible',
    description:
      'Looks for workplaces that support remote work and flexible schedules.',
  },
  {
    name: 'Social Impact / Purpose-Driven',
    description:
      'Wants to be part of an organization that makes a positive impact on society.',
  },
  {
    name: 'Entrepreneurial / Startup',
    description:
      'Interested in startup environments with a hands-on, entrepreneurial spirit.',
  },
  {
    name: 'Diverse / Inclusive',
    description:
      'Values diverse, inclusive cultures that respect and celebrate differences.',
  },
  {
    name: 'Learning / Growth Opportunities',
    description:
      'Prioritizes personal and professional development through continuous learning opportunities.',
  },
  {
    name: 'Other (Custom)',
  },
];

export const workCultureArray = [
  'Innovative / Creative',
  'Collaborative / Team-Oriented',
  'Independent / Autonomy',
  'Fast-Paced / Dynamic',
  'Stable / Structured',
  'Remote-Friendly / Flexible',
  'Social Impact / Purpose-Driven',
  'Entrepreneurial / Startup',
  'Diverse / Inclusive',
  'Learning / Growth Opportunities',
  'Other (Custom)',
];
