import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { validationMessages } from '../../config/validationMessages';
import { Accomplishment, Project } from '../Create-Portfolio/types';

export const useProjectFileChange = (
  project: Project,
  updateProject: (updatedProject: Project) => void,
) => {
  const handleFileChange = (files: File[]) => {
    const updatedFiles = [...project.files, ...files];

    const updatedProject = { ...project, files: updatedFiles };

    updateProject(updatedProject);
  };

  return { handleFileChange };
};

export const useProjectFileDelete = (
  project: Project,
  updateProject: (updatedProject: Project) => void,
) => {
  const handleDeleteFile = (fileIndex: number) => {
    const updatedFiles = [...project.files];
    updatedFiles.splice(fileIndex, 1);

    const updatedProject = { ...project, files: updatedFiles };

    updateProject(updatedProject);
  };

  return { handleDeleteFile };
};

export const useAccomplishmentFileChange = (
  accomplishment: Accomplishment,
  updateAccomplishment: (updatedAccomplishment: Accomplishment) => void,
) => {
  const handleFileChange = (files: File[]) => {
    const updatedFiles = [...accomplishment.files, ...files];

    const updatedAccomplishment = { ...accomplishment, files: updatedFiles };

    updateAccomplishment(updatedAccomplishment);
  };

  return { handleFileChange };
};

export const useAccomplishmentFileDelete = (
  accomplishment: Accomplishment,
  updateAccomplishment: (updatedAccomplishment: Accomplishment) => void,
) => {
  const handleDeleteFile = (fileIndex: number) => {
    const updatedFiles = [...accomplishment.files];
    updatedFiles.splice(fileIndex, 1);

    const updatedAccomplishment = { ...accomplishment, files: updatedFiles };

    updateAccomplishment(updatedAccomplishment);
  };

  return { handleDeleteFile };
};

export const useFileAmountValidation = (files: any = [], index: number) => {
  const [fileError, setFileError] = useState<string>('');
  const { SELECT_MAX_2_FILES } = validationMessages;

  useEffect(() => {
    if (files.length > 2) {
      setFileError(SELECT_MAX_2_FILES);
    } else {
      setFileError('');
    }
  }, [files, SELECT_MAX_2_FILES]);

  return { fileError };
};

export const useFileSizeValidation = (files: File[] = [], index: number) => {
  const [sizeError, setSizeError] = useState<boolean[]>([]);

  useEffect(() => {
    const maxSizeInBytes = 10 * 1024 * 1024;

    const invalidFiles = files.some(
      (file) => (file as File)?.size > maxSizeInBytes,
    );

    setSizeError((prev) => {
      const updatedErrors = [...prev];
      updatedErrors[index] = invalidFiles;
      return updatedErrors;
    });
  }, [files, index]);

  return { sizeError };
};

export const useCustomOptionsHandlers = (
  customOptions: string[],
  setCustomOptions: Dispatch<SetStateAction<string[]>>,
) => {
  const handleCustomOption = useCallback(
    (customOption: string, index: number) => {
      setCustomOptions((prev) => {
        const updatedCustomOptions = [...prev];
        updatedCustomOptions[index] = customOption;
        return updatedCustomOptions;
      });
    },
    [],
  );

  const handleAddExtraField = useCallback(() => {
    if (customOptions.length < 3) {
      setCustomOptions((prev) => [...prev, '']);
    }
  }, [customOptions]);

  const handleRemoveExtraField = useCallback((index: number) => {
    setCustomOptions((prev) => {
      const updatedCustomOptions = [...prev];
      updatedCustomOptions.splice(index, 1);
      return updatedCustomOptions;
    });
  }, []);

  return useMemo(
    () => ({
      handleAddExtraField,
      handleRemoveExtraField,
      handleCustomOption,
    }),
    [handleAddExtraField, handleRemoveExtraField, handleCustomOption],
  );
};
