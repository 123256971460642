import React, { useEffect, useState } from 'react';
import { API_ROUTES } from '../../config/api-routes';
import { validationMessages } from '../../config/validationMessages';
import './forgotPass.css';
import { Button, Card, CardBody, CloseButton } from 'react-bootstrap';
import axiosInstance from '../../api/api';

const { PASSWORD_RESET_SUCCESSFUL, ERROR_DURING_RESEND_EMAIL } =
  validationMessages;

interface ForgotPasswordProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ setShowModal }) => {
  const [message, setMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [canResend, setCanResend] = useState<boolean>(false);
  const [resendTimer, setResendTimer] = useState<number>(60);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    } else {
      setCanResend(true);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const handleForgotPassword = async (event: any) => {
    event.preventDefault();

    try {
      const response = await axiosInstance.post(
        API_ROUTES.AUTH.FORGOT_PASSWORD,
        { email },
      );
      const data = response.data;
      if (data.success) {
        setSuccessMessage(true);
        setCanResend(false);
        setResendTimer(60);
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      setMessage('Failed to send password reset email.');
    }
  };

  const handleResendEmail = async () => {
    try {
      const response = await axiosInstance.post(API_ROUTES.AUTH.RESEND_EMAIL, {
        email,
        type: 'passwordReset',
      });

      if (response.data.success) {
        setCanResend(false);
        setResendTimer(60);
      } else {
        setMessage(
          `Failed to resend verification email: ${response.data.message}`,
        );
      }
    } catch (error: any) {
      console.error('Error:', error);
      setMessage(`${ERROR_DURING_RESEND_EMAIL}`);
    }
  };

  return (
    <>
      {successMessage ? (
        <Card>
          <Card.Header
            className="p-3"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#14a077',
              alignItems: 'baseline',
            }}
          >
            <Card.Title>{PASSWORD_RESET_SUCCESSFUL}</Card.Title>
            <CloseButton onClick={() => setShowModal(false)} />
          </Card.Header>
          <CardBody>
            {message && (
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            )}
            <Card.Text>
              Thank you for initiating the password reset process. To proceed
              with resetting your password, please check your email inbox for a
              message from us.
              <br />
              <br />
              We have sent a password reset email to your registered email
              address. Open the email and follow the instructions provided to
              set a new password for your account.
              <br />
              <br />
              If you do not see the email in your inbox, please check your spam
              or junk folder.
              <br />
              <br />
              <strong>
                Please make sure you have received the password reset email
                before closing this modal. Closing this modal will disable the
                "Resend Password Reset Email" button.
              </strong>
              <br />
              <br />
              {canResend ? (
                <Button
                  variant="success"
                  onClick={handleResendEmail}
                  className="submit-btn-sign-up"
                >
                  Resend Password Reset Email
                </Button>
              ) : (
                <Button
                  variant="success"
                  className="submit-btn-sign-up"
                  disabled
                >
                  Resend available in {resendTimer}s
                </Button>
              )}
            </Card.Text>
          </CardBody>
        </Card>
      ) : (
        <div className="container">
          <Card.Header
            style={{
              marginBottom: '.5rem',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Card.Title>Reset Password</Card.Title>
            <CloseButton onClick={() => setShowModal(false)} />
          </Card.Header>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Enter your email*
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="d-grid gap-2">
            <button
              onClick={handleForgotPassword}
              type="submit"
              className="reset-pass"
            >
              Send
            </button>
          </div>
          {message && (
            <div className="alert alert-danger mt-3" role="alert">
              {message}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
