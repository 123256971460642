export const sectionList = [
  {
    name: 'Overview',
    mandatory: false,
  },
  {
    name: 'Projects',
    mandatory: false,
  },
  {
    name: 'References',
    mandatory: false,
  },
  {
    name: 'Ideal Work Culture',
    mandatory: false,
  },
  {
    name: 'Accomplishments',
    mandatory: false,
  },
  {
    name: 'Languages',
    mandatory: false,
  },
  {
    name: 'Certifications',
    mandatory: false,
  },
  {
    name: 'Professional Organizations',
    mandatory: false,
  },
];

export const sectionsOrder = [
  'PortfolioControl',
  'Overview',
  'Projects',
  'References',
  'Languages',
  'Certifications',
  'Ideal Work Culture',
  'Accomplishments',
  'Professional Organizations',
  'Preview',
];

export const requiredSections = [
  'Overview',
  'Projects',
  'References',
  'Languages',
  'Certifications',
  'Ideal Work Culture',
  'Accomplishments',
  'Professional Organizations',
];

export const portfolioSwitchesMapping: Record<string, string> = {
  isOverviewEnabled: 'Overview',
  isProjectsEnabled: 'Projects',
  isReferencesEnabled: 'References',
  isWorkCultureEnabled: 'Ideal Work Culture',
  isAccomplishmentsEnabled: 'Accomplishments',
  isLanguagesEnabled: 'Languages',
  isCertificationsEnabled: 'Certifications',
  isOrganizationsEnabled: 'Professional Organizations',
};
