export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const API_ROUTES = {
  AUTH: {
    REGISTER: `auth/register`,
    LOGIN: `auth/login`,
    LOGOUT: 'auth/logout',
    COMPLETE_PROFILE: `auth/complete-profile`,
    FORGOT_PASSWORD: `auth/forgot-password`,
    RESET_PASSWORD: `auth/reset-password`,
    RESEND_EMAIL: `auth/resend-verification`,
  },
  AI: {
    ASK_QUESTION: `ai/ask-question`,
  },
  USER: {
    GET_CURRENT_USER: `user/current-user`,
    UPDATE_USER: `user/update-user`,
  },
  PORTFOLIO: {
    CREATE_PORTFOLIO: `portfolio`,
    EDIT_PORTFOLIO: 'portfolio/update-portfolio',
    UPLOAD_FILES: `portfolio/upload-files`,
    GET_PORTFOLIO: 'portfolio',
    DELETE_PORTFOLIO: `portfolio/delete`,
  },
  PROMPTS: {
    CREATE_PROMPTS: 'prompts',
    GET_PROMPTS: 'prompts',
    EDIT_PROMPT: `prompt`,
    DELETE_PROMPT: `prompt`,
    DELETE_ALL_PROMPTS: `prompts`,
  },
  REFERENCE: {
    CREATE_REFERENCE: 'reference/create',
    UPDATE_REFERENCE: '/reference',
    DELETE_REFERENCE: '/reference',
    RESEND_CONFIRMATION_EMAIL: '/reference/resend-confirmation',
  },
};
