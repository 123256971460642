import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NotFoundIcon } from '../../images/folder-error.svg';

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container
      style={{
        height: 'calc(100vh - 80px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Row>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <NotFoundIcon
            style={{
              width: '50px',
              height: '50px',
              marginBottom: '20px',
            }}
          />
          <h1>404: Page Not Found</h1>
          <p
            style={{
              marginBottom: '40px',
            }}
          >
            Oops! It seems this page has disappeared.
          </p>
          <Button variant="secondary" onClick={handleGoBack}>
            Go Back
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
