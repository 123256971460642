import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Tab, Tabs, Row, Modal, Button } from 'react-bootstrap';
import { fetchUserData } from '../Preview/api';
import { fetchPortfolioData, fetchPromptsData } from './api';
import PortfolioCards from '../Preview/PortfolioCards';
import UserInformationList from '../Preview/UserInformationList';
import UserPanel from '../Preview/UserPanel';
import UserProfileTabContent from './UserProfileTabContent';
import { ReactComponent as CloseIcon } from '../../images/close-cross.svg';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { ReactComponent as EditIcon } from '../../images/edit.svg';
import Spinner from 'react-bootstrap/Spinner';

import { ReturnedPortfolio } from '../Create-Portfolio/types';
import { API_ROUTES } from '../../config/api-routes';
import PromptsTabContent from './PromptDataTabContent';
import axiosInstance from '../../api/api';
import AddNewPromptStepper from '../AddNewPromptStepper';

const UserProfile: React.FC = () => {
  const navigate = useNavigate();

  const [key, setKey] = useState<string>('prompts');
  const [portfolioId, setPortfolioId] = useState<number>(0);
  const [isPortfolioPresent, setIsPortfolioPresent] = useState<boolean>(false);
  const [savePortfolioButton, setSavePortfolioButton] =
    useState<boolean>(false);

  const [portfolioData, setPortfolioData] = useState<ReturnedPortfolio>({
    overview: '',
    projects: [],
    references: [],
    languages: [],
    certifications: [],
    workCulture: [],
    accomplishments: [],
    organizations: [],
  });

  const [userData, setUserData] = useState<any>(null);
  const [promptsData, setPromptsData] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deletePortfolioContext, setPortfolioContextRemoval] =
    useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const accessToken = localStorage.getItem('accessToken');

  const getData = async () => {
    setLoader(true);

    const portfolioData = await fetchPortfolioData();
    const userData = await fetchUserData();
    const promptsData = await fetchPromptsData();

    if (!userData.username) {
      setLoader(false);
      navigate('/complete-profile');
      return;
    }

    if (!userData.profileImg) {
      setLoader(false);
      navigate('/complete-profile-avatar');
      return;
    }

    if (userData.username && userData.profileImg && promptsData.length === 0) {
      setLoader(false);
      navigate('/create-prompt');
      return;
    }

    if (portfolioData) {
      setPortfolioData({
        isOverviewEnabled: portfolioData.isOverviewEnabled,
        isProjectsEnabled: portfolioData.isProjectsEnabled,
        isReferencesEnabled: portfolioData.isReferencesEnabled,
        isLanguagesEnabled: portfolioData.isLanguagesEnabled,
        isCertificationsEnabled: portfolioData.isCertificationsEnabled,
        isWorkCultureEnabled: portfolioData.isWorkCultureEnabled,
        isOrganizationsEnabled: portfolioData.isOrganizationsEnabled,
        isAccomplishmentsEnabled: portfolioData.isAccomplishmentsEnabled,
        overview: portfolioData.overview || '',
        projects: portfolioData.projects || [],
        references: portfolioData.references || [],
        languages: portfolioData.languages || [],
        certifications: portfolioData.certifications || [],
        workCulture: portfolioData.workCulture || [],
        accomplishments: portfolioData.accomplishments || [],
        organizations: portfolioData.organizations || [],
      });

      setPortfolioContextRemoval(false);
      setIsPortfolioPresent(true);
      setPortfolioId(portfolioData.id);
    }

    if (userData) {
      setUserData(userData);
    }
    if (promptsData) {
      setPromptsData(promptsData);
    }

    setLoader(false);
  };

  useEffect(() => {
    if (accessToken) {
      getData();
    }
  }, [accessToken]);

  const handleDeletePortfolio = async () => {
    if (!portfolioId) {
      console.log('Portfolio ID is not set');
      return;
    }

    const response = await await axiosInstance.delete(
      `${API_ROUTES.PORTFOLIO.DELETE_PORTFOLIO}/${portfolioId}`,
    );

    if (!response || response.status !== 200) {
      console.log('Error', response);
      return;
    }

    setPortfolioData({
      overview: '',
      projects: [],
      references: [],
      languages: [],
      certifications: [],
      workCulture: [],
      accomplishments: [],
      organizations: [],
    });
    setIsPortfolioPresent(false);
    setShowModal(false);
    setPortfolioContextRemoval(true);
    setKey('prompts');
  };

  const submitUpdatedPortfolio = async () => {
    if (portfolioId) {
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        console.error('Access token is missing');
        return;
      }

      if (portfolioData) {
        try {
          const response = await axiosInstance.post(
            `${API_ROUTES.PORTFOLIO.EDIT_PORTFOLIO}/${portfolioId}`,
            portfolioData,
          );

          if (!response || response.status !== 200) {
            if (setSavePortfolioButton) {
              setSavePortfolioButton(true);
              setShowModal(true);
            }
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          await response.data;

          if (setSavePortfolioButton) {
            setSavePortfolioButton(false);
          }

          if (getData) {
            getData();
            setShowSaveModal(true);
          }
        } catch (error) {
          console.error('Failed to update portfolio:', error);
        }
      } else {
        console.error('Portfolio data is missing');
      }
    }
  };

  const navigateToPortfolioCreationPage = () => {
    navigate('/create-portfolio');
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {loader ? (
        <div className="prompt-spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div
          style={
            {
              //padding: '50px 100px',
            }
          }
          className="container"
        >
          <Row>
            <Col className="mb-5">
              <Row className="row-sticky">
                <Col md={4} sm={12}>
                  <UserPanel
                    portfolio={portfolioData}
                    userData={userData}
                    savePortfolioButton={savePortfolioButton}
                    setSavePortfolioButton={setSavePortfolioButton}
                    portfolioId={portfolioId}
                    getData={getData}
                    setUserData={setUserData}
                    jobTitle={promptsData ? promptsData[0]?.jobTitle : ''}
                  />

                  <UserInformationList userData={userData} />
                  <PortfolioCards
                    portfolio={portfolioData}
                    userProfile="profile"
                    setPortfolioData={setPortfolioData}
                    setSavePortfolioButton={setSavePortfolioButton}
                  />
                </Col>
                <Col md={8} sm={12} className="sticky-col">
                  <div className="tab-container-with-button">
                    <Tabs
                      id="controlled-tab"
                      activeKey={key}
                      onSelect={(k) => {
                        if (k !== 'portfolio' || isPortfolioPresent) {
                          setKey(k as string); // Allow tab switching only if portfolio is present or another tab is clicked
                        }
                      }}
                      className="mb-3 tab-menu"
                    >
                      <Tab
                        eventKey="prompts"
                        title={
                          <div
                            className={`nav-item-wrapper d-flex align-items-center justify-content-between ${
                              key === 'prompts' ? 'active-tab' : ''
                            }`}
                          >
                            <span className="tab-title">Screening Prompts</span>
                            <AddNewPromptStepper
                              prompts={promptsData}
                              setPromptsData={setPromptsData}
                            />
                          </div>
                        }
                      >
                        <PromptsTabContent
                          prompts={promptsData}
                          setPromptsData={setPromptsData}
                        />
                      </Tab>
                      <Tab
                        eventKey="portfolio"
                        title={
                          <div
                            className={`nav-item-wrapper d-flex align-items-center justify-content-between ${
                              key === 'portfolio' ? 'active-tab' : ''
                            }`}
                          >
                            <span className="portfolio-title tab-title">
                              Portfolio
                            </span>
                            {isPortfolioPresent ? (
                              <div className="portfolio-actions">
                                <span className="user-info-edit-button">
                                  <EditIcon
                                    style={{ width: '18px', height: '18px' }}
                                    onClick={() =>
                                      navigate('/create-portfolio?mode=edit')
                                    }
                                  />
                                </span>
                                <span
                                  className="delete-button"
                                  onClick={handleShowModal}
                                >
                                  <CloseIcon
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                </span>
                              </div>
                            ) : (
                              <span
                                className="add-button"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent the tab from being activated
                                  navigateToPortfolioCreationPage();
                                }}
                              >
                                <PlusIcon
                                  style={{ width: '20px', height: '20px' }}
                                />
                              </span>
                            )}
                          </div>
                        }
                      >
                        <UserProfileTabContent
                          portfolio={portfolioData}
                          setPortfolioData={setPortfolioData}
                          setSavePortfolioButton={setSavePortfolioButton}
                          savePortfolioButton={savePortfolioButton}
                          deletePortfolioContext={deletePortfolioContext}
                          getData={getData}
                        />
                      </Tab>
                    </Tabs>

                    {savePortfolioButton && (
                      <Button
                        variant="success"
                        size="sm"
                        className="save-portfolio-button"
                        onClick={submitUpdatedPortfolio}
                        id="port-btn"
                        style={{ fontWeight: '500' }}
                      >
                        SAVE PORTFOLIO
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)}>
            <Modal.Header>
              <Modal.Title>Save Successful</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your changes have been saved successfully!</Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={() => setShowSaveModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to permanently delete your portfolio?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDeletePortfolio}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default UserProfile;
