export const validationMessages = {
  PROVIDE_NAME: 'Please provide a name.',
  PROVIDE_DATE: 'Please provide a date.',
  PROVIDE_EMAIL: 'Please provide an email.',
  PROVIDE_VALID_EMAIL: 'Please provide a valid email address.',
  PROVIDE_START_DATE: 'Please provide a start date.',
  PROVIDE_END_DATE: 'Please provide an end date.',
  PROVIDE_DESCRIPTION: 'Please provide a description.',
  PROVIDE_ROLE: 'Please provide a role.',
  PROVIDE_LINK: 'Please provide a link.',
  PROVIDE_FILE: 'Please provide a file.',
  PROVIDE_LEVEL: 'Please select the level of the role.',
  PROVIDE_JOB_TITLE: 'Please provide your job title.',
  PROVIDE_INDUSTRY: 'Please provide specific industry.',
  PROVIDE_OVERVIEW: 'Please provide an overview.',
  PROVIDE_SCHOOL_OR_ORGANIZATION: 'Please provide a school or an organization.',
  PROVIDE_LANGUAGE_LEVEL:
    'Please indicate the proficiency level for all selected languages.',
  UPLOAD_IMAGE: 'Please upload a profile image.',
  ENSURE_ALL_LINKS_PROVIDED: 'Please ensure that all links are provided.',
  SELECT_MAX_2_FILES: 'Select no more than 2 files.',
  FILES_EXCEEDED_LIMIT_10MB: 'Some files exceed the 10MB size limit.',
  FILE_EXCEEDED_LIMIT_4MB:
    'File size exceeds 4MB. Please select a smaller file.',
  CHOOSE_VALUE_GREATER_THAN_0: 'Please choose a value greater than 0.',
  CHOOSE_MIN_ONE_OPTION: 'Please choose at least one option.',
  CHOOSE_ONE_LANGUAGE: 'Please choose at least one language.',
  SHARE_OPTION: 'Please share your option.',
  USERNAME_PASSWORD_CANT_BE_EMPTY: 'Username and password cannot be empty!',
  ERROR_DURING_LOGIN: 'An error occurred during login.',
  ERROR_DURING_REGISTRATION: 'An error occurred during registration.',
  LOGIN_FAILED: 'Login failed.',
  LOGIN_SUCCESS: 'Login successful.',
  LOGOUT_FAILED: 'Logout failed.',
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match!',
  PASSWORD_MUST_CONTAIN_8_CHARACTERS:
    'Password must be at least 8 characters long.',
  REGISTRATION_SUCCESSFUL: 'Registration successful!',
  REGISTRATION_FAILED: 'Registration failed:',
  PASSWORD_RESET_SUCCESSFUL: 'Password reset email sent successfully!',
  PASSWORD_RESET_FAILED: 'Failed to reset password.',
  ERROR_DURING_PASSWORD_RESET: 'An error occurred during password reset.',
  ERROR_DURING_RESEND_EMAIL:
    'Failed to resend verification email. Please try again',
  DATA_FETCH_FAILED:
    'Oops, something went wrong... Please check your internet connection and try again!',
  DATE_OUT_OF_RANGE: 'The date must be between 01/01/1960 and today.',
  END_DATE_BEFORE_START_DATE: 'The end date cannot be before the start date.',
  RECORDING_ABORTED: 'Recording was aborted.',
  PERMISSION_DENIED: 'Permission denied.',
  NO_MEDIA_FOUND: 'No specified media found.',
  MEDIA_IN_USE: 'Media is in use.',
  INVALID_MEDIA_CONSTRAINS: 'Invalid media constraints.',
  PHONE_LENGTH: 'Phone number must be at least 10 characters.',
  USERNAME_ERROR:
    'User with such username already exists. Please choose another username.',
  SALARY_ERROR:
    'Salary cannot be empty, zero, negative, or contain leading zeros.',
  TIME_ERROR:
    'Time to start cannot be empty, zero, negative, or contain leading zeros',
};
