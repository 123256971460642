import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface RemoveModalProps {
  showModal: boolean;
  onCloseModal: () => void;
  onSubmit: () => void;
}

const RemoveModal: React.FC<RemoveModalProps> = ({
  showModal,
  onCloseModal,
  onSubmit,
}) => {
  return (
    <Modal show={showModal} onHide={onCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onSubmit}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveModal;
