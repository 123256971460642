import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Button, Modal } from 'react-bootstrap';
import { generalPrompts } from '../../config/generalPrompts';
import { API_ROUTES } from '../../config/api-routes';
import axiosInstance from '../../api/api';

interface PromptSelectionModalProps {
  jobIndustry: string;
  jobTitle: string;
  level: string;
  onPromptSelect: (prompt: string) => void;
}

const PromptSelectionModal: React.FC<PromptSelectionModalProps> = ({
  jobIndustry,
  jobTitle,
  level,
  onPromptSelect,
}) => {
  const [roleSpecificPrompts, setRoleSpecificPrompts] = useState<string[]>([]);
  const [modalStep, setModalStep] = useState<number>(1);

  const [roleSpecificLoader, setRoleSpecificLoader] = useState<boolean>(false);

  const handleRoleSpecificPrompts = async () => {
    setRoleSpecificLoader(true);

    try {
      const response = await axiosInstance.post(API_ROUTES.AI.ASK_QUESTION, {
        question: `Give me the 20 most screening questions for ${jobTitle} (${jobIndustry}) for ${level} level role in a format of 1-20 without breaking them up into groups`,
      });

      const data = response.data;

      if (response && response.status === 200) {
        setRoleSpecificPrompts(data.answer);
        setModalStep(3);
      }
    } catch (error) {
      console.error('Error fetching role specific prompts:', error);
    } finally {
      setRoleSpecificLoader(false);
    }
  };

  return (
    <>
      {modalStep === 1 && (
        <Modal.Body
          style={{
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {roleSpecificLoader ? (
            <div className="prompt-spinner-edit">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div className="prompt-modal-container">
              <div className="prompt-modal-content">
                <Button
                  variant="success"
                  className="modal-sub-title prompt-button-list"
                  onClick={() => setModalStep(2)}
                >
                  General Prompts
                </Button>
                <Button
                  variant="success"
                  className="modal-sub-title prompt-button-list"
                  onClick={() => handleRoleSpecificPrompts()}
                >
                  Role Specific Prompts
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      )}

      {modalStep === 2 && (
        <Modal.Body style={{ height: '500px', overflowY: 'auto' }}>
          <h2 className="prompt-modal-heading">General Prompts</h2>
          {generalPrompts.map((prompt, index) => (
            <div key={index} className="prompt-modal-item">
              <p
                className="prompt-modal-text"
                onClick={() => onPromptSelect(prompt)}
              >
                {prompt}
              </p>
              {index < generalPrompts.length - 1 && (
                <hr className="prompt-divider" />
              )}
            </div>
          ))}
        </Modal.Body>
      )}

      {modalStep === 3 && (
        <Modal.Body style={{ height: '500px', overflowY: 'auto' }}>
          <h2 className="prompt-modal-heading">Role-Specific Prompts</h2>
          {roleSpecificPrompts.map((prompt, index) => {
            return (
              <div key={index} className="prompt-modal-item">
                <p
                  className="prompt-modal-text"
                  key={index}
                  onClick={() => onPromptSelect(prompt)}
                >
                  {prompt}
                </p>
                <hr className="prompt-divider" />
              </div>
            );
          })}
        </Modal.Body>
      )}
    </>
  );
};

export default PromptSelectionModal;
