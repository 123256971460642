import { months } from '../../config/months';

export function extractYearAndAverageMonth(dateString: string | Date) {
  const parts = dateString.toString().split('-');

  const year = parts[0];
  const monthNumber = Number(parts[1]) - 1;

  const month = months[monthNumber];

  if (year === '9999') {
    return {
      year: 'Present',
      month: '',
      formattedDate: 'Present', // Return "Present" for 9999 dates
    };
  }

  return {
    year,
    month,
    formattedDate: `${month} ${year}`,
  };
}

export function convertDaysToReadableTime(days: number) {
  if (days < 7) {
    return `${days} day${days === 1 ? '' : 's'}`;
  } else if (days < 30) {
    const weeks = Math.ceil(days / 7);
    return `${weeks} week${weeks === 1 ? '' : 's'}`;
  } else if (days < 365) {
    const months = Math.ceil(days / 30);
    return `${months} month${months === 1 ? '' : 's'}`;
  } else {
    const years = Math.ceil(days / 365);
    return `${years} year${years === 1 ? '' : 's'}`;
  }
}

export const sanitizeInput = (input: string) => {
  return input.replace(/[^a-zA-Z0-9\-_\.]/g, '_');
};
