import React from 'react';
import { Col, Row, Card, CardLink, Accordion } from 'react-bootstrap';
import { CURRENT_PLACEHOLDER_DATE } from '../../config/calendarMinMaxDates';
import {
  isAccomplishmentFilled,
  isCertificationFilled,
  isOrganizationFilled,
  isProjectFilled,
} from '../Create-Portfolio/helpers';
import { Portfolio } from '../Create-Portfolio/types';
import { extractYearAndAverageMonth, sanitizeInput } from './helpers';
import './Preview.css';

interface PortfolioTabProps {
  portfolio: Portfolio;
}

const PortfolioTabContent: React.FC<PortfolioTabProps> = ({ portfolio }) => {
  const { overview, projects, certifications, accomplishments, organizations } =
    portfolio;

  return (
    <Card style={{ backgroundColor: 'transparent', minHeight: '415px' }}>
      <Card.Body>
        {overview && (
          <Row className="mb-4">
            <Card.Title as="h3">Overview</Card.Title>
            <Card.Text className="mb-4">{overview}</Card.Text>
          </Row>
        )}
        {projects.every(isProjectFilled) && (
          <Row className="mb-4">
            <Card.Title as="h3">Projects</Card.Title>
            <Accordion>
              {projects.map(({ name, description, files }, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>
                    <Card.Title as="h5" className="item-title">
                      {name}
                    </Card.Title>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Row className="mb-4">
                      <Col xs={1}>
                        <div className="vertical-divider"></div>
                      </Col>
                      <Col xs={11}>
                        <Card.Text>{description}</Card.Text>

                        {files.map((file, fileIndex) => {
                          const fileUrl = URL.createObjectURL(file);
                          return (
                            <div key={fileIndex}>
                              <button
                                type="button"
                                className="view-project-btn"
                                onClick={() => window.open(fileUrl, '_blank')}
                              >
                                {sanitizeInput(file.name)}
                              </button>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Row>
        )}

        {accomplishments.every(isAccomplishmentFilled) && (
          <Row className="mb-4">
            <Card.Title as="h3">Accomplishments</Card.Title>
            <Accordion>
              {accomplishments.map(
                ({ name, date, description, links, files }, index) => (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Card.Title as="h5" className="item-title">
                          {name}
                        </Card.Title>
                        <span className="preview-font-weight">
                          {extractYearAndAverageMonth(date).formattedDate}
                        </span>
                      </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      <Row>
                        <Col xs={1}>
                          <div className="vertical-divider"></div>
                        </Col>
                        <Col xs={11}>
                          <div>
                            <Card.Text>{description}</Card.Text>

                            {links.map((link, linkIndex) => (
                              <CardLink
                                href={link}
                                key={linkIndex}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Link
                              </CardLink>
                            ))}

                            {files.map((file, fileIndex) => {
                              const fileUrl = URL.createObjectURL(file);
                              return (
                                <div key={fileIndex}>
                                  <button
                                    type="button"
                                    className="view-project-btn"
                                    onClick={() =>
                                      window.open(fileUrl, '_blank')
                                    }
                                  >
                                    {sanitizeInput(file.name)}
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                ),
              )}
            </Accordion>
          </Row>
        )}

        {organizations.every(isOrganizationFilled) && (
          <Row className="mb-4">
            <Card.Title as="h3">Professional Organizations</Card.Title>
            <Accordion>
              {organizations.map(
                ({ name, startDate, endDate, description, link }, index) => {
                  const finish =
                    endDate !== CURRENT_PLACEHOLDER_DATE
                      ? extractYearAndAverageMonth(endDate).formattedDate
                      : 'Present';
                  return (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>
                        <Card.Title as="h5" className="item-title">
                          {name}
                        </Card.Title>
                      </Accordion.Header>

                      <Accordion.Body>
                        <Row>
                          <Col xs={1}>
                            <div className="vertical-divider"></div>
                          </Col>
                          <Col xs={11}>
                            <div
                              style={{ marginBottom: '1rem' }}
                              className="preview-font-weight"
                            >
                              <span>
                                {
                                  extractYearAndAverageMonth(startDate)
                                    .formattedDate
                                }
                              </span>
                              <span> - </span>
                              <span>{finish as string}</span>
                            </div>
                            <Card.Text>{description}</Card.Text>

                            <CardLink
                              href={link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Link
                            </CardLink>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                },
              )}
            </Accordion>
          </Row>
        )}

        {certifications.every(isCertificationFilled) && (
          <Row className="mb-4">
            <Card.Title as="h3">Certifications</Card.Title>
            <Accordion>
              {certifications.map(({ name, date, file }, index) => {
                const { formattedDate } = extractYearAndAverageMonth(date);

                return (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>
                      <Card.Title as="h5" className="item-title">
                        {name}
                      </Card.Title>
                    </Accordion.Header>

                    <Accordion.Body>
                      <Row className="mb-4">
                        <Col xs={1}>
                          <div className="vertical-divider"></div>
                        </Col>
                        <Col xs={11}>
                          <div>
                            <span className="preview-font-weight">
                              {formattedDate}
                            </span>

                            <div>
                              <button
                                type="button"
                                className="view-project-btn"
                                onClick={() =>
                                  window.open(
                                    URL.createObjectURL(file as File),
                                    '_blank',
                                  )
                                }
                              >
                                {file
                                  ? sanitizeInput(file.name)
                                  : 'certification'}
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default PortfolioTabContent;
