import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import { Alert, Form, Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { maxDate, minDate } from '../../config/calendarMinMaxDates';
import { API_ROUTES, BASE_URL } from '../../config/api-routes';
import { validationMessages } from '../../config/validationMessages';
import 'react-phone-input-2/lib/style.css';
import './Settings.css';
import { useNavigate } from 'react-router-dom';
import { usePreviousLocation } from './usePreviousLocation';
import { ReactComponent as BackIcon } from '../../images/arrow-back.svg';
import axiosInstance from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Settings = () => {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    gender: '',
    company: '',
    dateOfBirth: '',
    username: '',
    country: '',
    state: '',
    city: '',
    linkedin: '',
    workArrangements: '',
    timeToStart: 0,
    flexibleType: '',
    salary: '',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [countries, setCountries] = useState<string[]>([]);
  const [salaryInput, setSalaryInput] = useState<boolean>(false);
  const [salaryKeeper, setSalaryKeeper] = useState<string>('');
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [gender, setGender] = useState(userData.gender || '');
  const [otherGender, setOtherGender] = useState('');
  const [alertState, setAlertState] = useState({
    timeToStart: false,
    salary: false,
  });

  const getUserInfo = async () => {
    try {
      const response = await axiosInstance.get('user/current-user');

      if (!response || response.status !== 200) {
        setLoader(false);
        return;
      }

      const data = response.data;
      const isChooseCompensation = !Number.isNaN(data.user.salary);
      if (isChooseCompensation) {
        setSalaryInput(true);
        setSalaryKeeper(data.user.salary);
      }
      setUserData({
        ...data.user,
        salary: isChooseCompensation ? 'Choose compensation' : data.salary,
      });
      setLoader(false);
    } catch (error: any) {
      console.error('Error fetching user info:', error);
      setLoader(false);
    }
  };

  const getCountries = async () => {
    try {
      const response = await axiosInstance.get('auth/get-countries');

      if (!response || response.status !== 200) {
        setLoader(false);
        return;
      }
      const data = response.data;

      setCountries(data);
      setLoader(false);
    } catch (error: any) {
      console.error('Error fetching countries:', error);
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    getUserInfo();
    getCountries();
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (value === 'Other') {
      setIsOtherSelected(true);
      setUserData({ ...userData, [name]: value });
      setIsChanged(true);
    } else {
      setIsOtherSelected(false);
      setUserData({ ...userData, [name]: value });
      setIsChanged(true);
    }
  };

  const handleBlur = (e: any) => {
    const { name, value } = e.target;

    const isInvalid = value === '' || Number(value) <= 0;

    const hasLeadingZeros = /^[0]+[0-9]+$/.test(value);

    setAlertState((prevState) => ({
      ...prevState,
      [name]: isInvalid || hasLeadingZeros,
    }));
  };

  const handlePhoneChange = (value: string) => {
    setUserData((prevProfile) => ({ ...prevProfile, phoneNumber: value }));
    setPhoneError(false);
    setIsChanged(true);
  };

  const handleSalaryChange = (e: any) => {
    handleChange(e);
    setSalaryInput(e.target.value === 'Choose compensation');
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === 'Other') {
      setIsOtherSelected(true);
      setGender(value);
      setIsChanged(true);
    } else {
      setIsOtherSelected(false);
      setGender(value);
      setIsChanged(true);
    }
    setUserData((prevProfile) => ({ ...prevProfile, gender: value }));
  };

  const handleOtherGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherGender(e.target.value);
    setUserData((prevProfile) => ({ ...prevProfile, gender: e.target.value }));
  };

  const handleReset = () => {
    setIsOtherSelected(false);
    setOtherGender('');
    setGender('');
    setUserData((prevProfile) => ({ ...prevProfile, gender: '' }));
  };

  const handleSalaryInputChange = (e: any) => {
    setSalaryKeeper(e.target.value);
    setIsChanged(true);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const minLength = 10;
    const accessToken = localStorage.getItem('accessToken');

    if (userData.phoneNumber.length < minLength) {
      setPhoneError(true);
      return;
    }

    setLoader(true);

    try {
      const response = await fetch(
        `${BASE_URL}${API_ROUTES.USER.UPDATE_USER}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            ...userData,
            salary: salaryInput ? salaryKeeper : userData.salary,
            dateOfBirth: moment(userData.dateOfBirth).format('MM-DD-YYYY'),
          }),
        },
      );

      const responseBody = await response.json();

      if (!response.ok) {
        if (
          responseBody.message.includes(
            'duplicate key value violates unique constraint',
          )
        ) {
          setUsernameError(true);
          setError(false);
        }

        if (
          !responseBody.message.includes(
            'duplicate key value violates unique constraint',
          )
        ) {
          setError(true);
        }

        throw new Error(`Failed to complete profile: ${response.statusText}`);
      }

      setError(false);
      setIsEditing(false);
      setIsChanged(false);
      setUsernameError(false);
    } catch (error: any) {
      setIsEditing(false);
      setIsChanged(false);
    } finally {
      setLoader(false);
    }
  };

  const navigate = useNavigate();
  const previousLocation = usePreviousLocation();

  const handleGoBack = () => {
    if (
      previousLocation &&
      !previousLocation.pathname.includes(
        process.env.REACT_APP_BASE_URL as string,
      )
    ) {
      navigate('/user-profile');
    } else if (
      window.history.length > 1 &&
      previousLocation.pathname.includes(
        process.env.REACT_APP_BASE_URL as string,
      )
    ) {
      navigate(-1);
    } else {
      navigate('/user-profile');
    }
  };

  return (
    <div className="container my-4">
      {loader ? (
        <div className="prompt-spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <section className="pt-3 pb-3">
            <button onClick={handleGoBack} className="go-back-btn">
              <BackIcon width={50} height={50} />
            </button>
            <div className="container">
              <div className="row">
                <div className="settings-header-wrapper col">
                  <h1
                    className="d-inline-block ms-3"
                    style={{ position: 'relative' }}
                  >
                    <span
                      className="d-inline-block"
                      style={{
                        width: '4px',
                        height: '100%',
                        backgroundColor: '#198754',
                        position: 'absolute',
                        left: '-20px',
                        top: '0',
                      }}
                    ></span>
                    User Settings
                  </h1>

                  <button
                    className="settings-header-edit-btn btn btn-primary mb-3"
                    onClick={() => setIsEditing(true)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </section>

          <form onSubmit={handleSubmit}>
            <div className="card mb-4">
              <div className="card-header">Basic Information</div>
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      value={userData.firstName}
                      onChange={handleChange}
                      required
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      value={userData.lastName}
                      onChange={handleChange}
                      required
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number
                    </label>
                    <PhoneInput
                      country={'us'}
                      value={userData.phoneNumber}
                      onChange={handlePhoneChange}
                      inputProps={{
                        name: 'phoneNumber',
                        required: true,
                        autoFocus: false,
                      }}
                      inputClass="form-control"
                      containerClass="phone-input"
                      disabled={!isEditing}
                    />
                    {phoneError && (
                      <Alert variant="danger" style={{ marginTop: '5px' }}>
                        {validationMessages.PHONE_LENGTH}
                      </Alert>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="username" className="form-label">
                      User Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      value={userData.username}
                      onChange={handleChange}
                      required
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="gender" className="form-label">
                      Gender
                    </label>
                    {isOtherSelected ? (
                      <div className="d-flex align-items-center">
                        <button
                          type="button"
                          className="btn btn-outline-secondary me-2"
                          onClick={handleReset}
                          style={{ padding: '0.375rem 0.75rem' }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <input
                          type="text"
                          className="form-control"
                          id="otherGender"
                          name="gender"
                          placeholder="Please specify"
                          value={otherGender}
                          onChange={handleOtherGenderChange}
                          required
                        />
                      </div>
                    ) : (
                      <select
                        className="form-select"
                        id="gender"
                        name="gender"
                        value={gender}
                        onChange={handleGenderChange}
                        required
                        disabled={!isEditing}
                      >
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="dateOfBirth" className="form-label">
                      Date Of Birth
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      value={userData.dateOfBirth}
                      onChange={handleChange}
                      required
                      min={minDate}
                      max={maxDate}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="company" className="form-label">
                      Current Company
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="company"
                      name="company"
                      value={userData.company}
                      onChange={handleChange}
                      disabled={!isEditing}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">Contact Information</div>
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <Typeahead
                      clearButton
                      id="country"
                      options={countries}
                      selected={userData.country ? [userData.country] : []}
                      onChange={(selected) => {
                        const country = selected.length > 0 ? selected[0] : '';
                        setUserData({
                          ...userData,
                          country: country as string,
                        });
                        setIsChanged(true);
                      }}
                      disabled={!isEditing}
                      className="typeahead"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <input
                      className="form-control"
                      id="state"
                      name="state"
                      value={userData.state}
                      onChange={handleChange}
                      required
                      disabled={!isEditing}
                    />
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <input
                      className="form-control"
                      id="city"
                      name="city"
                      value={userData.city}
                      onChange={handleChange}
                      required
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">Work information</div>
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="country" className="form-label">
                      Preferred work arrangements
                    </label>
                    <Form.Select
                      aria-label="Select work arrangement"
                      name="workArrangements"
                      onChange={handleChange}
                      value={userData.workArrangements}
                      disabled={!isEditing}
                    >
                      <option value="">Select</option>
                      <option value="Hybrid">Hybrid</option>
                      <option value="On-site">On-site</option>
                      <option value="Remote">Remote</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="state" className="form-label">
                      Time needed to start
                    </label>
                    <Form.Control
                      type="number"
                      name="timeToStart"
                      min={1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={userData.timeToStart}
                      disabled={!isEditing}
                    />
                    {alertState.timeToStart && (
                      <Alert variant="danger" style={{ marginTop: '5px' }}>
                        {validationMessages.TIME_ERROR}
                      </Alert>
                    )}
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="city" className="form-label">
                      Salary
                    </label>
                    <Form.Select
                      aria-label="Select salary"
                      name="salary"
                      onChange={handleSalaryChange}
                      value={userData.salary}
                      className="mb-3"
                      disabled={!isEditing}
                    >
                      <option value="Not indicated">
                        I don’t want this added
                      </option>
                      <option value="Can negotiate">
                        I prefer to negotiate during the interview
                      </option>
                      <option value="Choose compensation">
                        Choose compensation
                      </option>
                    </Form.Select>
                    {salaryInput && (
                      <div style={{ position: 'relative' }}>
                        <span className="doll-sign">$</span>
                        <Form.Control
                          style={{ paddingLeft: '30px' }}
                          type="number"
                          name="salary"
                          placeholder="Enter your desired compensation"
                          onChange={handleSalaryInputChange}
                          onBlur={handleBlur}
                          value={salaryKeeper}
                          min={1}
                          disabled={!isEditing}
                        />
                        {alertState.salary && (
                          <Alert variant="danger" style={{ marginTop: '5px' }}>
                            {validationMessages.SALARY_ERROR}
                          </Alert>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="city" className="form-label">
                      Flexibility
                    </label>
                    <Form.Select
                      aria-label="Select flexibility"
                      name="flexibleType"
                      onChange={handleChange}
                      value={userData.flexibleType}
                      disabled={!isEditing}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">Contact Information</div>
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-md-12">
                    <label htmlFor="linkedin" className="form-label">
                      LinkedIn
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="linkedin"
                      name="linkedin"
                      value={userData.linkedin}
                      onChange={handleChange}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
            </div>
            {error && (
              <Alert variant="danger" style={{ marginBottom: '50px' }}>
                {validationMessages.DATA_FETCH_FAILED}
              </Alert>
            )}
            {usernameError && (
              <Alert variant="danger" style={{ marginBottom: '50px' }}>
                {validationMessages.USERNAME_ERROR}
              </Alert>
            )}
            <div className="d-grid gap-2">
              <button
                type="submit"
                className="btn btn-success"
                disabled={!isEditing || !isChanged}
              >
                Save
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default Settings;
