import React, { useState, useCallback } from 'react';
import './PortfolioProjects.css';
import PrevAndNextButtons from '../Prev-Next-Buttons';
import ProjectItem from './ProjectItem';
import PortfolioItemsWrapper from '../Portfolio-Items-Wrapper';
import { Portfolio, Project } from '../Create-Portfolio/types';
import { newProject } from '../Create-Portfolio/initialValues';
import { validationMessages } from '../../config/validationMessages';

interface PortfolioProjectProps {
  handleNext: () => void;
  handlePrevious: () => void;
  projects: Project[];
  setProjects: React.Dispatch<React.SetStateAction<Portfolio>>;
}

const { PROVIDE_NAME, PROVIDE_DESCRIPTION } = validationMessages;

const PortfolioProjects: React.FC<PortfolioProjectProps> = ({
  handleNext,
  handlePrevious,
  projects,
  setProjects,
}) => {
  const [validationErrors, setValidationErrors] = useState<string[][]>(
    projects.map(() => ['', '', '']),
  );
  const [sizeError, setSizeError] = useState<boolean[]>([false]);

  const addProjectItem = () => {
    const allProjects = [...projects, newProject];

    setProjects((prevPortfolio) => ({
      ...prevPortfolio,
      projects: allProjects,
    }));

    setValidationErrors((prev) => [...prev, ['', '', '']]);
    setSizeError((prev) => [...prev, false]);
  };

  const removeProjectItem = (index: number) => {
    const updatedProjects = projects.filter((_, i) => i !== index);

    setProjects((prevPortfolio) => ({
      ...prevPortfolio,
      projects: updatedProjects,
    }));

    setValidationErrors((prev) => prev.filter((_, i) => i !== index));
    setSizeError((prev) => prev.filter((_, i) => i !== index));
  };

  const updateProjectItem = (index: number, updatedProject: Project) => {
    const updatedProjects = projects.map((project, i) =>
      i === index ? updatedProject : project,
    );

    setProjects((prevPortfolio) => ({
      ...prevPortfolio,
      projects: updatedProjects,
    }));
  };

  const validateFilesSize = useCallback(
    (files: File[], index: number) => {
      const maxSizeInBytes = 10 * 1024 * 1024;

      const invalidFiles = files.some((file) => file.size > maxSizeInBytes);

      if (!invalidFiles) {
        setSizeError((prev) =>
          prev.map((item, itemIndex: number) =>
            index === itemIndex ? false : item,
          ),
        );
      }

      if (invalidFiles) {
        setSizeError((prev) =>
          prev.map((item, itemIndex: number) =>
            index === itemIndex ? true : item,
          ),
        );
      }
    },
    [setSizeError],
  );

  const validateProjectItemForm = useCallback(
    (index: number, project: Project) => {
      const updatedErrors = ['', '', ''];

      if (project.name.trim() === '') {
        updatedErrors[0] = `${PROVIDE_NAME}`;
      }

      if (project.description.trim() === '') {
        updatedErrors[1] = `${PROVIDE_DESCRIPTION}`;
      }

      if (project.files.length > 2) {
        return;
      }

      setValidationErrors((prev) =>
        prev.map((errors, i) => (i === index ? updatedErrors : errors)),
      );

      return updatedErrors.every((error) => error === '');
    },
    [setValidationErrors],
  );

  const validateProjectField = (
    index: number,
    field: string,
    value: string,
  ) => {
    const updatedErrors = [...validationErrors[index]];

    if (field === 'name' && value.trim() === '') {
      updatedErrors[0] = `${PROVIDE_NAME}`;
    } else if (field === 'name') {
      updatedErrors[0] = '';
    }

    if (field === 'description' && value.trim() === '') {
      updatedErrors[1] = `${PROVIDE_DESCRIPTION}`;
    } else if (field === 'description') {
      updatedErrors[1] = '';
    }

    setValidationErrors((prev) =>
      prev.map((errors, i) => (i === index ? updatedErrors : errors)),
    );
  };

  const handleNextClick = useCallback(() => {
    projects.forEach((project, index) => {
      validateProjectItemForm(index, project);
    });

    const isAnyCertificationInvalid = projects.some(
      (project, index) => !validateProjectItemForm(index, project),
    );

    if (!isAnyCertificationInvalid && sizeError.every((error) => !error)) {
      handleNext();
    }
  }, [projects, validateProjectItemForm, sizeError, handleNext]);

  return (
    <section>
      <div className="container my-4">
        <h2>Projects</h2>
        <h6>
          A showcase of your past work, highlighting key projects you’ve
          completed. This section demonstrates your hands-on experience and
          achievements in specific areas.
        </h6>
        {projects.map((project, index) => (
          <PortfolioItemsWrapper
            addItem={addProjectItem}
            removeItem={() => removeProjectItem(index)}
            index={index}
            key={index}
            itemName="Project"
          >
            <ProjectItem
              project={project}
              errors={validationErrors[index]}
              updateProject={(updatedProject) =>
                updateProjectItem(index, updatedProject)
              }
              validateProjectField={validateProjectField}
              index={index}
              validateFilesSize={validateFilesSize}
              sizeError={sizeError}
            />
          </PortfolioItemsWrapper>
        ))}
        <PrevAndNextButtons
          handleNext={handleNextClick}
          handlePrevious={handlePrevious}
        />
      </div>
    </section>
  );
};

export default PortfolioProjects;
