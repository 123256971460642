import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Col, Row, Card, Form, Button, Alert, Toast } from 'react-bootstrap';

import {
  Accomplishment,
  PopupsForAddingState,
  PopupsState,
  ReturnedPortfolio,
} from '../Create-Portfolio/types';
import '../Preview/Preview.css';

import { maxDate, minDate } from '../../config/calendarMinMaxDates';

import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { ReactComponent as MinusIcon } from '../../images/minus.svg';
import { formatDate, handleNewItemFields } from '../UserProfile/helpers';
import {
  useFileAmountValidation,
  useFileSizeValidation,
} from '../Create-Portfolio/customHooks';
import { validationMessages } from '../../config/validationMessages';
import { newlyAddedAccomplishment } from '../Create-Portfolio/initialValues';
import FileUploadComponent from '../FileUploadButton/SingleFileUploadButton';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';
import { API_ROUTES } from '../../config/api-routes';
import axiosInstance from '../../api/api';

interface Props {
  index: number;
  portfolio: ReturnedPortfolio;
  setOpenedPopups: React.Dispatch<React.SetStateAction<PopupsState>>;
  setPopupsToAdd: React.Dispatch<React.SetStateAction<PopupsForAddingState>>;
  setPortfolioData: React.Dispatch<React.SetStateAction<ReturnedPortfolio>>;
  setSavePortfolioButton: React.Dispatch<React.SetStateAction<boolean>>;
  savePortfolioButton: boolean;
  popupVariant: string;
  getData: () => void;
}

const AccomplishmentPopup: React.FC<Props> = ({
  index,
  portfolio,
  setOpenedPopups,
  setPopupsToAdd,
  setPortfolioData,
  setSavePortfolioButton,
  savePortfolioButton,
  popupVariant,
  getData,
}) => {
  const [accomplishments, setAccomplishments] = useState<any>(
    portfolio.accomplishments,
  );
  const [newAccomplishment, setNewAccomplishment] = useState<any>(
    newlyAddedAccomplishment,
  );
  const [disabled, setDisabled] = useState<boolean>(true);
  const [success, setAddingFileSuccess] = useState<boolean>(false);
  const [sizeError, setSizeError] = useState<boolean>(false);

  const popupRef = useRef<HTMLDivElement>(null);

  const { fileError } = useFileAmountValidation(
    accomplishments[index]?.filesUrl,
    index,
  );

  const errorWithAmountInNewAccomplishment = useFileAmountValidation(
    newAccomplishment.filesUrl,
    index,
  );
  const errorWithSizeInNewAccomplishment = useFileSizeValidation(
    newAccomplishment.filesUrl,
    index,
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setPopupsToAdd((prev: PopupsForAddingState) => ({
          ...prev,
          accomplishments: false,
        }));

        setOpenedPopups((prev) => {
          const newAccomplishmentsPopups = [...prev.accomplishments];
          newAccomplishmentsPopups[index] = false;
          return {
            ...prev,
            accomplishments: newAccomplishmentsPopups,
          };
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, setOpenedPopups, setPopupsToAdd, index]);

  const handleAccomplishmentSubmit = (index: number) => {
    if (accomplishments.length === undefined) {
      return;
    }

    setSavePortfolioButton(true);
    scrollToPortfolioButton();

    setPortfolioData((prev) => ({ ...prev, accomplishments: accomplishments }));

    setOpenedPopups((prev) => {
      const newAccomplishmentsPopups = [...prev.accomplishments];
      newAccomplishmentsPopups[index] = false;
      return {
        ...prev,
        accomplishments: newAccomplishmentsPopups,
      };
    });
  };

  const handleSubmitOfNewAccomplishment = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    const updatedAccomplishments = [...accomplishments, newAccomplishment];

    setPortfolioData((prev) => ({
      ...prev,
      accomplishments: updatedAccomplishments,
    }));
    setSavePortfolioButton(true);
    scrollToPortfolioButton();

    setPopupsToAdd((prev: PopupsForAddingState) => ({
      ...prev,
      accomplishments: false,
    }));
  };

  const scrollToPortfolioButton = () => {
    const saveButton = document.getElementById('port-btn');
    if (saveButton) {
      saveButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleDeleteFile = (accomplishmentIndex: number, fileIndex: number) => {
    const updatedFiles = accomplishments[accomplishmentIndex].filesUrl.filter(
      (_: any, index: number) => index !== fileIndex,
    );

    const updatedItem = {
      ...accomplishments[accomplishmentIndex],
      filesUrl: updatedFiles,
    };

    const updatedItems = [...accomplishments];
    updatedItems[accomplishmentIndex] = updatedItem;

    setAccomplishments(updatedItems);
    setDisabled(false);
    setSavePortfolioButton(true);
  };

  const handleInputChange = (
    index: number,
    field: string,
    value: string,
    array: any,
    setItems: React.Dispatch<React.SetStateAction<any>>,
  ) => {
    const itemIndex = array.findIndex(
      (_: any, prIndex: number) => prIndex === index,
    );

    if (itemIndex !== -1) {
      const updatedItems = [...array];
      updatedItems[itemIndex] = {
        ...updatedItems[itemIndex],
        [field]: value,
      };
      setItems(updatedItems);
      setDisabled(false);
      setSavePortfolioButton(true);
    }
  };

  const handleFileChange = async (index: number, file: File, array: any[]) => {
    const maxSizeInBytes = 10 * 1024 * 1024;

    if (file.size >= maxSizeInBytes) {
      setSizeError(true);
      return;
    }

    const data: FormData = new FormData();

    const info = {
      accomplishment: { id: array[index].id, fileId: uuidv4() },
    };

    data.append('info', JSON.stringify(info));

    data.append(info.accomplishment.fileId, file, file.name);

    try {
      const response = await axiosInstance.post(
        API_ROUTES.PORTFOLIO.UPLOAD_FILES,
        data,
      );

      if (!response || response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setAddingFileSuccess(true);

      const result = await response.data;
      getData();
      console.log('Portfolio files uploaded successfully', result);
    } catch (error) {
      console.error('Failed to upload portfolio files:', error);
    }
  };

  const handleAccomplishmentLinkChange = (
    linkIndex: number,
    value: string,
    accomplishmentIndex: number,
  ) => {
    const updatedAccomplishments = [...accomplishments];
    const updatedLinks = [...updatedAccomplishments[accomplishmentIndex].links];
    updatedLinks[linkIndex] = value;
    updatedAccomplishments[accomplishmentIndex].links = updatedLinks;
    setAccomplishments(updatedAccomplishments);
  };

  const handleNewAccomplishmentLinkChange = (
    linkIndex: number,
    value: string,
  ) => {
    setNewAccomplishment((prevAccomplishment: Accomplishment) => {
      const updatedLinks = [...prevAccomplishment.links];
      updatedLinks[linkIndex] = value;
      return {
        ...prevAccomplishment,
        links: updatedLinks,
      };
    });
  };

  const removeLink = (linkIndex: number) => {
    const updatedLinks = [...accomplishments[index].links];
    updatedLinks.splice(linkIndex, 1);
    const updatedAccomplishments = [...accomplishments];
    updatedAccomplishments[index].links = updatedLinks;
    setAccomplishments(updatedAccomplishments);
    setDisabled(false);
    setSavePortfolioButton(true);
  };

  const addLink = (index: number) => {
    const updatedLinks = [...accomplishments[index].links, ''];
    const updatedAccomplishments = [...accomplishments];
    updatedAccomplishments[index].links = updatedLinks;
    setAccomplishments(updatedAccomplishments);
  };

  const addLinkInNewAccomplishment = () => {
    setNewAccomplishment((prevAccomplishment: Accomplishment) => ({
      ...prevAccomplishment,
      links: [...prevAccomplishment.links, ''],
    }));
  };

  const removeLinkFromNewAccomplishment = (linkIndexToRemove: number) => {
    setNewAccomplishment((prevAccomplishment: Accomplishment) => ({
      ...prevAccomplishment,
      links: prevAccomplishment.links.filter(
        (_, index) => index !== linkIndexToRemove,
      ),
    }));
    setDisabled(false);
  };

  return (
    <Card
      className={`${popupVariant === 'adding' ? '' : 'user-popup'} p-3 popup-card`}
      ref={popupRef}
    >
      <Card.Body className="scrollable-card">
        {popupVariant !== 'adding' && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleAccomplishmentSubmit(index);
            }}
          >
            {/* Title */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Title
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="text"
                  required
                  defaultValue={portfolio.accomplishments[index].name}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'name',
                      e.target.value,
                      accomplishments,
                      setAccomplishments,
                    )
                  }
                />
              </Col>
            </Form.Group>

            {/* Date */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Date
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="date"
                  required
                  defaultValue={formatDate(
                    portfolio.accomplishments[index].date,
                  )}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'date',
                      e.target.value,
                      accomplishments,
                      setAccomplishments,
                    )
                  }
                  min={minDate}
                  max={maxDate}
                />
              </Col>
            </Form.Group>

            {/* Description */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Description
              </Form.Label>
              <Col sm={12} style={{ position: 'relative' }}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  className="popup-textarea"
                  required
                  maxLength={50}
                  defaultValue={portfolio.accomplishments[index].description}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'description',
                      e.target.value,
                      accomplishments,
                      setAccomplishments,
                    )
                  }
                />
                <span className="textarea-placeholder">Max: 50 characters</span>
              </Col>
            </Form.Group>

            {/* Links */}
            {accomplishments[index].links &&
              accomplishments[index].links.map(
                (link: string, linkIndex: number) => (
                  <Form.Group as={Row} className="mb-3" key={linkIndex}>
                    {linkIndex === 0 && (
                      <Form.Label column sm={12}>
                        Relevant links
                      </Form.Label>
                    )}
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        value={link}
                        required
                        onChange={(e) => {
                          setDisabled(false);
                          setSavePortfolioButton(true);
                          handleAccomplishmentLinkChange(
                            linkIndex,
                            e.target.value,
                            index,
                          );
                        }}
                      />
                      <div
                        className={
                          linkIndex > 0
                            ? 'links-toggle-button-wrapper'
                            : 'links-add-btn-wrapper'
                        }
                      >
                        {linkIndex > 0 && (
                          <button
                            type="button"
                            onClick={() => removeLink(linkIndex)}
                          >
                            <MinusIcon className="icons-size" />
                            <span className="underline-text">Remove link</span>
                          </button>
                        )}
                        <button type="button" onClick={() => addLink(index)}>
                          <PlusIcon className="icons-size" />
                          <span className="underline-text">Add link</span>
                        </button>
                      </div>
                    </Col>
                  </Form.Group>
                ),
              )}

            {/* File Upload */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Upload Supporting Files
              </Form.Label>
              <Col sm={12} className="file-upload-section">
                {portfolio.accomplishments[index].filesUrl &&
                  portfolio.accomplishments[index].filesUrl.length > 0 &&
                  accomplishments[index].filesUrl.map(
                    (fileItem: any, fileIndex: number) => {
                      const fileName = fileItem?.name
                        ? fileItem.name.replace(/_/g, ' ')
                        : fileItem
                            .toString()
                            .replace(/_/g, ' ')
                            .split('/')
                            .pop();
                      return (
                        <div className="selected-file" key={fileIndex}>
                          <p className="file-name">{fileName}</p>
                          <Button
                            variant="danger"
                            className="prompt-delete-button"
                            onClick={() => handleDeleteFile(index, fileIndex)}
                          >
                            <DeleteIcon
                              style={{ width: '20px', height: '20px' }}
                            />
                          </Button>
                        </div>
                      );
                    },
                  )}
                {savePortfolioButton ? (
                  <Button disabled variant="secondary-outlined">
                    Please save an accomplishment to add file.
                  </Button>
                ) : (
                  <>
                    {success ? (
                      <Button disabled variant="secondary-outlined">
                        File successfully uploaded.
                      </Button>
                    ) : (
                      <>
                        {accomplishments[index].filesUrl &&
                          accomplishments[index].filesUrl.length < 2 && (
                            <div className="select-project-file">
                              <FileUploadComponent
                                handleFileChange={(file) =>
                                  handleFileChange(index, file, accomplishments)
                                }
                              />
                            </div>
                          )}
                        {fileError && (
                          <Alert variant="danger">{fileError}</Alert>
                        )}
                      </>
                    )}
                  </>
                )}
                {sizeError && (
                  <Alert variant="danger">
                    {validationMessages.FILES_EXCEEDED_LIMIT_10MB}
                  </Alert>
                )}
              </Col>
            </Form.Group>

            {/* Submit Button */}
            <Button variant="success" type="submit" disabled={disabled}>
              Submit
            </Button>
          </Form>
        )}

        {/* Adding a New Accomplishment */}
        {popupVariant === 'adding' && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitOfNewAccomplishment(e);
            }}
          >
            <Card.Title className="mb-3">Add Accomplishment</Card.Title>

            {/* Title */}
            <Form.Group as={Row} className="mb-3">
              <Col sm={12}>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter a title"
                  onChange={(e) =>
                    handleNewItemFields(
                      'name',
                      e.target.value,
                      setNewAccomplishment,
                    )
                  }
                />
              </Col>
            </Form.Group>

            {/* Date */}
            <Form.Group as={Row} className="mb-3">
              <Col sm={12}>
                <Form.Control
                  type="date"
                  required
                  placeholder="Enter a date"
                  onChange={(e) =>
                    handleNewItemFields(
                      'date',
                      e.target.value,
                      setNewAccomplishment,
                    )
                  }
                  min={minDate}
                  max={maxDate}
                />
              </Col>
            </Form.Group>

            {/* Description */}
            <Form.Group as={Row} className="mb-3">
              <Col sm={12} style={{ position: 'relative' }}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  placeholder="Enter a description"
                  maxLength={50}
                  onChange={(e) =>
                    handleNewItemFields(
                      'description',
                      e.target.value,
                      setNewAccomplishment,
                    )
                  }
                />
                <span className="textarea-placeholder">Max: 50 characters</span>
              </Col>
            </Form.Group>

            {/* Links */}
            {newAccomplishment.links &&
              newAccomplishment.links.length > 0 &&
              newAccomplishment.links.map((link: string, linkIndex: number) => (
                <Form.Group as={Row} className="mb-3" key={linkIndex}>
                  <Col sm={12}>
                    <Form.Control
                      type="text"
                      value={link}
                      required
                      placeholder="Enter a link"
                      onChange={(e) =>
                        handleNewAccomplishmentLinkChange(
                          linkIndex,
                          e.target.value,
                        )
                      }
                    />
                    <div
                      className={
                        linkIndex > 0
                          ? 'links-toggle-button-wrapper'
                          : 'links-add-btn-wrapper'
                      }
                    >
                      {linkIndex > 0 && (
                        <button
                          type="button"
                          onClick={() =>
                            removeLinkFromNewAccomplishment(linkIndex)
                          }
                        >
                          <MinusIcon className="icons-size" />
                          <span className="underline-text">Remove link</span>
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={addLinkInNewAccomplishment}
                      >
                        <PlusIcon className="icons-size" />
                        <span className="underline-text">Add link</span>
                      </button>
                    </div>
                  </Col>
                </Form.Group>
              ))}

            {/* File Upload */}
            <Form.Group as={Row} className="mb-3">
              <Col sm={12} className="file-upload-section">
                <Button disabled variant="secondary-outlined">
                  To add file please save portfolio first.
                </Button>
                {errorWithAmountInNewAccomplishment.fileError && (
                  <Alert variant="danger">{fileError}</Alert>
                )}
                {errorWithSizeInNewAccomplishment.sizeError[index] && (
                  <Alert variant="danger">
                    {validationMessages.FILES_EXCEEDED_LIMIT_10MB}
                  </Alert>
                )}
              </Col>
            </Form.Group>

            {/* Submit Button */}
            <Button variant="success" type="submit">
              Submit
            </Button>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};

export default AccomplishmentPopup;
