import { requiredSections } from '../../config/portfolio-section-list';
import { v4 as uuidv4 } from 'uuid';
import {
  Accomplishment,
  Certification,
  Language,
  Organization,
  Project,
  Reference,
} from './types';

export const isOverviewFilled = (overview: string): boolean => {
  return overview.trim() !== '';
};

export const isProjectFilled = (project: Project): boolean => {
  const { name, description } = project;

  return name.trim() !== '' && description.trim() !== '';
};

export const isReferenceFilled = (reference: Reference): boolean => {
  const { name, email, role, organization } = reference;

  return (
    name.trim() !== '' &&
    email.trim() !== '' &&
    role.trim() !== '' &&
    organization.trim() !== ''
  );
};

export const isLanguagesFilled = (lang: Language): boolean => {
  const { language, level } = lang;
  return language.trim() !== '' && level.trim() !== '';
};

export const isCertificationFilled = (
  certification: Certification,
): boolean => {
  const { name, date, file } = certification;

  return name.trim() !== '' && date.trim() !== '' && file !== null;
};

export const isWorkCultureFilled = (workCulture: string[]): boolean => {
  return workCulture.length !== 0;
};

export const isAccomplishmentFilled = (
  accomplishment: Accomplishment,
): boolean => {
  const { name, date, description, links } = accomplishment;

  return (
    name.trim() !== '' &&
    (date as string).trim() !== '' &&
    description.trim() !== '' &&
    links[0] !== ''
  );
};

export const isOrganizationFilled = (organization: Organization): boolean => {
  const { name, startDate, description, link, endDate } = organization;

  const checkup = Object.keys(organization).includes('endDate');

  if (checkup) {
    if (
      name.trim() === '' ||
      (startDate as string).trim() === '' ||
      (endDate as string).trim() === '' ||
      description.trim() === '' ||
      link.trim() === ''
    ) {
      return false;
    }
  }

  if (!checkup) {
    if (
      name.trim() === '' ||
      (startDate as string).trim() === '' ||
      description.trim() === '' ||
      link.trim() === ''
    ) {
      return false;
    }
  }

  return true;
};

export const addOverviewToPortfolioData = (overview: string) => {
  if (isOverviewFilled(overview)) {
    return overview;
  }
};

export const addProjectsToPortfolioData = (projects: Project[]) => {
  if (projects.every(isProjectFilled)) {
    const projectsWithAddedFileId = projects.map((project) => {
      const { name, description, files } = project;

      if (files.length > 0) {
        const adjustedProject = {
          name,
          description,
          fileId: uuidv4(),
        };
        return adjustedProject;
      } else {
        const adjustedProject = {
          name,
          description,
        };
        return adjustedProject;
      }
    });

    return projectsWithAddedFileId;
  }
};

export const prepareAndAppendFiles = (
  items: Project[] | Accomplishment[],
  adjustedProjects: any,
  formData: FormData,
) => {
  items.forEach((item, index) => {
    if (item.files.length > 0) {
      const id = adjustedProjects[index].fileId;
      const { files } = item;

      files.forEach((file) => formData.append(`${id}`, file, file.name));
    }
  });
};

export const prepareAndAppendFile = (
  items: Certification[],
  adjustedProjects: any,
  formData: FormData,
) => {
  items.forEach((item, index) => {
    if (item.file !== null) {
      const id = adjustedProjects[index].fileId;
      const { file } = item;

      formData.append(`${id}`, file, file.name);
    }
  });
};

export const addReferencesToPortfolioData = (references: Reference[]) => {
  if (references.every(isReferenceFilled)) {
    return references;
  }
};

export const addLanguagesToPortfolioData = (languages: Language[]) => {
  if (languages.length > 0 && languages.every(isLanguagesFilled)) {
    return languages;
  }
};

export const addCertificationsToPortfolioData = (
  certifications: Certification[],
) => {
  if (certifications.every(isCertificationFilled)) {
    const certificationsWithUpdatedDatesAndFileId = certifications.map(
      (certification) => {
        const { name, date } = certification;

        return {
          name,
          date: date,
          fileId: uuidv4(),
        };
      },
    );

    return certificationsWithUpdatedDatesAndFileId;
  }
};

export const addWorkCultureToPortfolioData = (workCulture: string[]) => {
  if (isWorkCultureFilled(workCulture)) {
    return workCulture;
  }
};

export const addAccomplishmentsToPortfolioData = (
  accomplishments: Accomplishment[],
) => {
  if (accomplishments.every(isAccomplishmentFilled)) {
    const accomplishmentsWithUpdatedDates = accomplishments.map(
      (accomplishment) => {
        const { name, date, description, links, files } = accomplishment;

        if (files.length > 0) {
          return {
            name,
            description,
            links: [...links],
            date: date,
            fileId: uuidv4(),
          };
        }

        return {
          name,
          description,
          links: [...links],
          date: date,
        };
      },
    );
    return accomplishmentsWithUpdatedDates;
  }
};

export const addOrganizationsToPortfolioData = (
  organizations: Organization[],
) => {
  if (organizations.every(isOrganizationFilled)) {
    const organizationsWithUpdatedDates = organizations.map((item) => ({
      ...item,
      startDate: item.startDate,
      endDate: item.endDate,
    }));
    return organizationsWithUpdatedDates;
  }
};

export const calculatePercentage = (
  switches: string[],
  step: number,
  isSectionFilled: Function,
) => {
  if (step === 0 || step === 1) {
    return 0;
  } else {
    const completedSections = switches
      .slice(0, step)
      .filter(
        (section) =>
          requiredSections.includes(section) && isSectionFilled(section),
      ).length;
    const totalSections = requiredSections.filter((section) =>
      switches.includes(section),
    ).length;
    return (completedSections / totalSections) * 100;
  }
};

export const isLastStep = (switches: string[], step: number) => {
  return step === switches.length - 1;
};

export const canMoveToPreviousStep = (step: number) => {
  return step > 0;
};
