import React, { useState, useEffect } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../images/SKIP.svg';
import './Header.css';
import { fetchUserData } from '../Preview/api';
import { ReactComponent as DownIcon } from '../../images/chevron-down.svg';
import { ReactComponent as BellIcon } from '../../images/bell-fill.svg';

const Header: React.FC = () => {
  const [userData, setUserData] = useState<any>(null);
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getUserData = async () => {
      try {
        if (localStorage.getItem('accessToken')) {
          const data = await fetchUserData();
          setUserData(data);
        }
      } catch (error: any) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate('/', { replace: true });
      }
    };

    getUserData();
  }, [navigate]);

  const accessToken = localStorage.getItem('accessToken');

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleLogout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/', { replace: true });
  };

  const goToSettings = () => {
    navigate('/user-settings');
    setDropdownOpen(false);
  };

  return (
    <div className="header-container">
      <Navbar className="mobile-header" variant="light">
        <Navbar.Brand as={Link} to="/user-profile" className="logo">
          <img alt="Logo" src={Logo} className="site-logo" width={150} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </Navbar>

      {accessToken && (
        <nav style={{ position: 'relative' }}>
          <div className="header-user-data-wrapper">
            <span style={{ margin: 'auto' }}>
              <span className="down-arrow">
                <BellIcon style={{ width: '20px', height: '20px' }} />
              </span>
            </span>
            {userData?.profileImg ? (
              <button
                onClick={toggleDropdown}
                style={{ border: 'none', backgroundColor: 'transparent' }}
              >
                <img
                  src={userData.profileImg}
                  alt="Profile Avatar"
                  className="preview-avatar-wrapper"
                  style={{ width: '40px', height: '40px' }}
                />
                <span className="down-arrow">
                  <DownIcon style={{ width: '20px', height: '20px' }} />
                </span>
              </button>
            ) : (
              <button
                className="header-avatar"
                onClick={toggleDropdown}
              ></button>
            )}
          </div>
          {isDropdownOpen && (
            <div className="user-settings-dropdown">
              {userData.username && userData.profileImg && (
                <button
                  type="button"
                  className="dropdown-options"
                  onClick={goToSettings}
                >
                  Settings
                </button>
              )}
              <button
                type="button"
                className="dropdown-options"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          )}
        </nav>
      )}
    </div>
  );
};

export default Header;
