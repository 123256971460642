import React, { useState, useCallback } from 'react';
import { maxDate, minDate } from '../../config/calendarMinMaxDates';
import { validationMessages } from '../../config/validationMessages';
import { newCertification } from '../Create-Portfolio/initialValues';
import { Certification, Portfolio } from '../Create-Portfolio/types';
import PortfolioItemsWrapper from '../Portfolio-Items-Wrapper';
import PrevAndNextButtons from '../Prev-Next-Buttons';
import CertificationItem from './CertificationItem';
import './Certifications.css';

interface CertificationProps {
  handleNext: () => void;
  handlePrevious: () => void;
  certifications: Certification[];
  setCertifications: React.Dispatch<React.SetStateAction<Portfolio>>;
}

const { PROVIDE_NAME, PROVIDE_DATE, PROVIDE_FILE, DATE_OUT_OF_RANGE } =
  validationMessages;

const Certifications: React.FC<CertificationProps> = ({
  handleNext,
  handlePrevious,
  certifications,
  setCertifications,
}) => {
  const [validationErrors, setValidationErrors] = useState<string[][]>(
    certifications.map(() => ['', '', '']),
  );
  const [sizeError, setSizeError] = useState<boolean[]>([false]);

  const addCertificationItem = () => {
    const allCertifications = [...certifications, newCertification];

    setCertifications((prevPortfolio) => ({
      ...prevPortfolio,
      certifications: allCertifications,
    }));

    setValidationErrors((prev) => [...prev, ['', '', '']]);
    setSizeError((prev) => [...prev, false]);
  };

  const removeCertificationItem = (index: number) => {
    const updatedCertifications = certifications.filter((_, i) => i !== index);
    setCertifications((prevPortfolio) => ({
      ...prevPortfolio,
      certifications: updatedCertifications,
    }));

    setValidationErrors((prev) => prev.filter((_, i) => i !== index));
    setSizeError((prev) => prev.filter((_, i) => i !== index));
  };

  const updateCertificationItem = (
    index: number,
    updatedCertification: Certification,
  ) => {
    const updatedCertifications = certifications.map((certification, i) =>
      i === index ? updatedCertification : certification,
    );
    setCertifications((prevPortfolio) => ({
      ...prevPortfolio,
      certifications: updatedCertifications,
    }));
  };

  const validateCertificationItemForm = useCallback(
    (index: number, certification: Certification) => {
      const updatedErrors = ['', '', ''];

      if (certification.name.trim() === '') {
        updatedErrors[0] = `${PROVIDE_NAME}`;
      }

      if (certification.date.trim() === '') {
        updatedErrors[1] = `${PROVIDE_DATE}`;
      }

      if (
        certification.date &&
        (certification.date < minDate || certification.date > maxDate)
      ) {
        updatedErrors[1] = `${DATE_OUT_OF_RANGE}`;
      }

      if (certification.file === null) {
        updatedErrors[2] = `${PROVIDE_FILE}`;
      } else if (certification.file !== null) {
        updatedErrors[2] = '';
      }

      setValidationErrors((prev) =>
        prev.map((errors, i) => (i === index ? updatedErrors : errors)),
      );

      return updatedErrors.every((error) => error === '');
    },
    [setValidationErrors],
  );

  const validateCertificationField = (
    index: number,
    field: string,
    value: string,
  ) => {
    const updatedErrors = [...validationErrors[index]];

    if (field === 'name' && value.trim() === '') {
      updatedErrors[0] = `${PROVIDE_NAME}`;
    } else if (field === 'name') {
      updatedErrors[0] = '';
    }

    if (field === 'date') {
      if (value.trim() === '') {
        updatedErrors[1] = `${PROVIDE_DATE}`;
      } else if (value < minDate || value > maxDate) {
        updatedErrors[1] = `${DATE_OUT_OF_RANGE}`;
      } else {
        updatedErrors[1] = '';
      }
    }

    setValidationErrors((prev) =>
      prev.map((errors, i) => (i === index ? updatedErrors : errors)),
    );
  };

  const handleNextClick = useCallback(() => {
    certifications.forEach((certification, index) => {
      validateCertificationItemForm(index, certification);
    });

    const isAnyCertificationInvalid = certifications.some(
      (certification, index) =>
        !validateCertificationItemForm(index, certification),
    );

    if (!isAnyCertificationInvalid && sizeError.every((error) => !error)) {
      handleNext();
    }
  }, [certifications, validateCertificationItemForm, sizeError, handleNext]);

  return (
    <section>
      <div className="container my-4">
        <h2>Certifications</h2>
        <h6>
          A record of any official certifications you have earned in your
          professional field. This shows your dedication to ongoing education
          and expertise in specific areas.
        </h6>
        {certifications.map((certification, index) => (
          <PortfolioItemsWrapper
            addItem={addCertificationItem}
            removeItem={removeCertificationItem}
            index={index}
            key={index}
            itemName="Certification"
          >
            <CertificationItem
              certification={certification}
              errors={validationErrors[index]}
              updateCertification={(updatedCertification) =>
                updateCertificationItem(index, updatedCertification)
              }
              validateCertificationField={validateCertificationField}
              index={index}
              setSizeError={setSizeError}
              sizeError={sizeError}
            />
          </PortfolioItemsWrapper>
        ))}
        <PrevAndNextButtons
          handleNext={handleNextClick}
          handlePrevious={handlePrevious}
        />
      </div>
    </section>
  );
};

export default Certifications;
