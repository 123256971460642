import React, { useState } from 'react';
import {
  Col,
  Row,
  Card,
  CardLink,
  Button,
  Modal,
  Accordion,
} from 'react-bootstrap';

import {
  PopupsForAddingState,
  PopupsState,
  RemoveModalDataState,
  ReturnedPortfolio,
} from '../Create-Portfolio/types';
import { ReactComponent as EditIcon } from '../../images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';
import '../Preview/Preview.css';
import { extractYearAndAverageMonth } from '../Preview/helpers';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';

import OverviewPopup from '../Popups/OverviewPopup';
import ProjectsPopup from '../Popups/ProjectsPopup';
import AccomplishmentPopup from '../Popups/AccomplishmentPopup';
import OrganizationsPopup from '../Popups/OrganizationsPopup';
import CertificationsPopup from '../Popups/CertificationsPopup';
import { CURRENT_PLACEHOLDER_DATE } from '../../config/calendarMinMaxDates';
import RemoveModal from '../RemoveModal';

interface PortfolioTabProps {
  portfolio: ReturnedPortfolio;
  setPortfolioData: React.Dispatch<React.SetStateAction<ReturnedPortfolio>>;
  setSavePortfolioButton: React.Dispatch<React.SetStateAction<boolean>>;
  savePortfolioButton: boolean;
  deletePortfolioContext: boolean;
  getData: () => void;
}

const UserProfileTabContent: React.FC<PortfolioTabProps> = ({
  portfolio,
  setPortfolioData,
  setSavePortfolioButton,
  deletePortfolioContext,
  savePortfolioButton,
  getData,
}) => {
  const [removeModalData, setRemoveModalData] =
    useState<RemoveModalDataState | null>(null);
  const [openedPopups, setOpenedPopups] = useState<PopupsState>({
    overview: false,
    projects: portfolio.projects.map(() => false),
    accomplishments: portfolio.accomplishments.map(() => false),
    organizations: portfolio.organizations.map(() => false),
    certifications: portfolio.certifications.map(() => false),
  });

  const [openPopupsToAdd, setPopupsToAdd] = useState<PopupsForAddingState>({
    projects: false,
    accomplishments: false,
    organizations: false,
    certifications: false,
  });

  const togglePopup = (popupName: keyof PopupsState) => {
    setOpenedPopups((prev) => ({
      ...prev,
      [popupName]: !prev[popupName],
    }));
  };

  const togglePopups = (popupName: keyof PopupsState, index: number) => {
    setOpenedPopups((prev) => {
      const popupArray = prev[popupName];
      if (Array.isArray(popupArray)) {
        const newPopupArray = [...popupArray];
        newPopupArray[index] = !newPopupArray[index];
        return {
          ...prev,
          [popupName]: newPopupArray,
        };
      }
      return prev;
    });
  };

  const handleTogglePopupsForAdding = (field: keyof PopupsForAddingState) => {
    setPopupsToAdd((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleCloseModal = () => {
    setRemoveModalData(null);
  };

  const handleDeleteSubsection = () => {
    if (removeModalData) {
      setPortfolioData((prevState) => {
        const updatedState = { ...prevState };
        const updatedElements = [...updatedState[removeModalData.field]];
        updatedElements?.splice(removeModalData.index, 1);
        updatedState[removeModalData.field] = updatedElements as any;
        return updatedState;
      });
      setSavePortfolioButton(true);
      setRemoveModalData(null);
    }
  };

  return (
    <Card className="portfolio-card">
      {!deletePortfolioContext && (
        <Card.Body>
          {portfolio.isOverviewEnabled && (
            <div className="portfolio-section">
              <div className="section-header">
                <Card.Title as="h3" className="section-title overview-pd">
                  Overview
                </Card.Title>
                <button
                  type="button"
                  className="add-button"
                  onClick={() => togglePopup('overview')}
                >
                  <EditIcon style={{ width: '20px', height: '20px' }} />
                </button>

                {/* Modal for the Overview Popup */}
                {openedPopups.overview && (
                  <Modal
                    show={openedPopups.overview}
                    onHide={() => togglePopup('overview')}
                    centered
                    backdrop="static"
                    size="lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Overview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ width: '100%' }}>
                      <OverviewPopup
                        portfolio={portfolio}
                        setOpenedPopups={setOpenedPopups}
                        setPortfolioData={setPortfolioData}
                        setSavePortfolioButton={setSavePortfolioButton}
                      />
                    </Modal.Body>
                  </Modal>
                )}
              </div>

              <Card.Text className="section-text overview-pd">
                {portfolio.overview}
              </Card.Text>
            </div>
          )}

          {portfolio.isProjectsEnabled && (
            <div className="portfolio-section">
              <div className="section-header">
                <Card.Title as="h3" className="section-title">
                  Projects
                </Card.Title>
                <button
                  type="button"
                  className="add-button"
                  onClick={() => handleTogglePopupsForAdding('projects')}
                >
                  <PlusIcon style={{ width: '20px', height: '20px' }} />
                </button>
                <Modal
                  show={openPopupsToAdd.projects} // This controls the visibility of the modal
                  onHide={() => togglePopup('projects')} // This closes the modal
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Projects</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {openPopupsToAdd.projects && (
                      <ProjectsPopup
                        portfolio={portfolio}
                        setOpenedPopups={setOpenedPopups}
                        setPopupsToAdd={setPopupsToAdd}
                        index={0}
                        setPortfolioData={setPortfolioData}
                        setSavePortfolioButton={setSavePortfolioButton}
                        popupVariant="adding"
                        savePortfolioButton={savePortfolioButton}
                        getData={getData}
                      />
                    )}
                  </Modal.Body>
                </Modal>
              </div>

              <Accordion>
                {portfolio.projects.map(
                  ({ name, description, filesUrl }, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>
                        <div className="project-info">
                          <Card.Title as="h5" className="item-title">
                            {name}
                          </Card.Title>
                          <div className="action-buttons">
                            <button
                              type="button"
                              className="edit-button"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent the accordion from toggling
                                togglePopups('projects', index); // Open the popup
                              }}
                            >
                              <EditIcon
                                style={{ width: '15px', height: '15px' }}
                              />
                            </button>
                          </div>
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>
                        <div className="project-description">
                          <div className="vertical-divider-portfolio"></div>
                          <div className="description-text">
                            <Card.Text>{description}</Card.Text>
                            {filesUrl &&
                              filesUrl.map((file, fileIndex) => {
                                const fileName = file
                                  .toString()
                                  .replace(/_/g, ' ')
                                  .split('/')
                                  .pop();
                                return (
                                  <a
                                    key={fileIndex}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={file.toString()}
                                    className="view-project-btn-po"
                                  >
                                    {fileName}
                                  </a>
                                );
                              })}
                          </div>
                        </div>
                      </Accordion.Body>

                      {/* Add the Modal outside of the Accordion */}
                      <Modal
                        show={openedPopups.projects[index]} // Control modal visibility
                        onHide={() => togglePopups('projects', index)} // Close the modal
                        centered // Center the modal on the screen
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Project</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ width: '100%' }}>
                          <ProjectsPopup
                            portfolio={portfolio}
                            setOpenedPopups={setOpenedPopups}
                            setPopupsToAdd={setPopupsToAdd}
                            index={index}
                            setPortfolioData={setPortfolioData}
                            setSavePortfolioButton={setSavePortfolioButton}
                            savePortfolioButton={savePortfolioButton}
                            popupVariant="editing"
                            getData={getData}
                          />
                        </Modal.Body>
                      </Modal>
                    </Accordion.Item>
                  ),
                )}
              </Accordion>
            </div>
          )}

          {portfolio.isAccomplishmentsEnabled && (
            <div className="portfolio-section">
              <div className="section-header">
                <Card.Title as="h3" className="section-title">
                  Accomplishments
                </Card.Title>
                <button
                  type="button"
                  className="add-button"
                  onClick={() => handleTogglePopupsForAdding('accomplishments')}
                >
                  <PlusIcon style={{ width: '20px', height: '20px' }} />
                </button>
                <Modal
                  show={openPopupsToAdd.accomplishments} // This controls the visibility of the modal
                  onHide={() => togglePopup('accomplishments')} // This closes the modal
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Accomplishments</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {openPopupsToAdd.accomplishments && (
                      <AccomplishmentPopup
                        portfolio={portfolio}
                        setOpenedPopups={setOpenedPopups}
                        setPopupsToAdd={setPopupsToAdd}
                        setPortfolioData={setPortfolioData}
                        setSavePortfolioButton={setSavePortfolioButton}
                        savePortfolioButton={savePortfolioButton}
                        index={0}
                        popupVariant="adding"
                        getData={getData}
                      />
                    )}
                  </Modal.Body>
                </Modal>
              </div>

              <Accordion>
                {portfolio.accomplishments.map((accomplishment, index) => {
                  const { name, date, description, links } = accomplishment;
                  return (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>
                        <div className="project-info">
                          <Card.Title as="h5" className="item-title">
                            {name}
                          </Card.Title>
                          <div className="action-buttons">
                            <button
                              type="button"
                              className="edit-button"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent the accordion from toggling
                                togglePopups('accomplishments', index); // Open the popup
                              }}
                            >
                              <EditIcon
                                style={{ width: '15px', height: '15px' }}
                              />
                            </button>
                          </div>
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>
                        <div className="project-description">
                          <div className="vertical-divider-portfolio"></div>
                          <div className="description-text">
                            <Card.Text>{description}</Card.Text>
                            {links &&
                              links.map((link, linkIndex) => (
                                <CardLink
                                  key={linkIndex}
                                  href={link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="view-project-btn-po"
                                >
                                  View Link
                                </CardLink>
                              ))}
                          </div>
                        </div>
                      </Accordion.Body>
                      <Modal
                        show={openedPopups.accomplishments[index]} // Control modal visibility
                        onHide={() => togglePopups('accomplishments', index)} // Close the modal
                        centered // Center the modal on the screen
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Accomplishments</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ width: '100%' }}>
                          <AccomplishmentPopup
                            portfolio={portfolio}
                            setOpenedPopups={setOpenedPopups}
                            setPopupsToAdd={setPopupsToAdd}
                            index={index}
                            setPortfolioData={setPortfolioData}
                            setSavePortfolioButton={setSavePortfolioButton}
                            savePortfolioButton={savePortfolioButton}
                            popupVariant="editing"
                            getData={getData}
                          />
                        </Modal.Body>
                      </Modal>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          )}

          {portfolio.isCertificationsEnabled && (
            <div className="portfolio-section">
              <div className="section-header">
                <Card.Title as="h3" className="section-title">
                  Certifications
                </Card.Title>
                <button
                  type="button"
                  className="add-button"
                  onClick={() => handleTogglePopupsForAdding('certifications')}
                >
                  <PlusIcon style={{ width: '20px', height: '20px' }} />
                </button>
                <Modal
                  show={openPopupsToAdd.certifications} // This controls the visibility of the modal
                  onHide={() => togglePopup('certifications')} // This closes the modal
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Certifications</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {openPopupsToAdd.certifications && (
                      <CertificationsPopup
                        portfolio={portfolio}
                        setOpenedPopups={setOpenedPopups}
                        setPopupsToAdd={setPopupsToAdd}
                        index={0}
                        popupVariant="adding"
                        setPortfolioData={setPortfolioData}
                        setSavePortfolioButton={setSavePortfolioButton}
                        savePortfolioButton={savePortfolioButton}
                        getData={getData}
                      />
                    )}
                  </Modal.Body>
                </Modal>
              </div>

              <Accordion>
                {portfolio.certifications.map(
                  ({ name, date, filesUrl }, index) => {
                    const { formattedDate } = extractYearAndAverageMonth(date);
                    return (
                      <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>
                          <div className="project-info">
                            <Card.Title as="h5" className="item-title">
                              {name}
                            </Card.Title>
                            <div className="action-buttons">
                              <button
                                type="button"
                                className="edit-button"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent the accordion from toggling
                                  togglePopups('certifications', index); // Open the popup
                                }}
                              >
                                <EditIcon
                                  style={{ width: '15px', height: '15px' }}
                                />
                              </button>
                            </div>
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <div className="project-description">
                            <div className="vertical-divider-portfolio"></div>
                            <div className="description-text">
                              <span className="preview-font-weight">
                                {formattedDate}
                              </span>
                              {filesUrl && typeof filesUrl[0] === 'string' && (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={filesUrl[0]}
                                  className="view-project-btn-po"
                                >
                                  {filesUrl[0]
                                    .replace(/_/g, ' ')
                                    .split('/')
                                    .pop()}
                                </a>
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                        <Modal
                          show={openedPopups.certifications[index]} // Control modal visibility
                          onHide={() => togglePopups('certifications', index)} // Close the modal
                          centered // Center the modal on the screen
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Edit Certifications</Modal.Title>
                          </Modal.Header>
                          <Modal.Body style={{ width: '100%' }}>
                            <CertificationsPopup
                              portfolio={portfolio}
                              setOpenedPopups={setOpenedPopups}
                              setPopupsToAdd={setPopupsToAdd}
                              index={index}
                              setPortfolioData={setPortfolioData}
                              setSavePortfolioButton={setSavePortfolioButton}
                              savePortfolioButton={savePortfolioButton}
                              popupVariant="editing"
                              getData={getData}
                            />
                          </Modal.Body>
                        </Modal>
                      </Accordion.Item>
                    );
                  },
                )}
              </Accordion>
            </div>
          )}

          {portfolio.isOrganizationsEnabled && (
            <div className="portfolio-section">
              <div className="section-header">
                <Card.Title as="h3" className="section-title">
                  Organizations
                </Card.Title>
                <button
                  type="button"
                  className="add-button"
                  onClick={() => handleTogglePopupsForAdding('organizations')}
                >
                  <PlusIcon style={{ width: '20px', height: '20px' }} />
                </button>
                <Modal
                  show={openPopupsToAdd.organizations} // This controls the visibility of the modal
                  onHide={() => togglePopup('organizations')} // This closes the modal
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Organization</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {openPopupsToAdd.organizations && (
                      <OrganizationsPopup
                        portfolio={portfolio}
                        setOpenedPopups={setOpenedPopups}
                        setPopupsToAdd={setPopupsToAdd}
                        index={0}
                        popupVariant="adding"
                        setPortfolioData={setPortfolioData}
                        setSavePortfolioButton={setSavePortfolioButton}
                      />
                    )}
                  </Modal.Body>
                </Modal>
              </div>

              <Accordion>
                {portfolio.organizations.map(
                  ({ name, startDate, endDate, description, link }, index) => {
                    return (
                      <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>
                          <div className="project-info">
                            <Card.Title as="h5" className="item-title">
                              {name}
                            </Card.Title>
                            <div>
                              <Card.Body>
                                <span>
                                  {
                                    extractYearAndAverageMonth(startDate)
                                      .formattedDate
                                  }
                                </span>
                                <span> - </span>
                                <span>
                                  {
                                    extractYearAndAverageMonth(endDate)
                                      .formattedDate as string
                                  }
                                </span>
                              </Card.Body>
                            </div>
                            <div className="action-buttons">
                              <button
                                type="button"
                                className="edit-button"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent the accordion from toggling
                                  togglePopups('organizations', index); // Open the popup
                                }}
                              >
                                <EditIcon
                                  style={{ width: '15px', height: '15px' }}
                                />
                              </button>
                            </div>
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <div className="project-description">
                            <div className="vertical-divider-portfolio"></div>
                            <div className="description-text">
                              <div
                                style={{ marginBottom: '1rem' }}
                                className="preview-font-weight"
                              >
                                <span>{description}</span>
                              </div>
                              {link && (
                                <CardLink
                                  key={link}
                                  href={link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="view-project-btn-po"
                                >
                                  View Link
                                </CardLink>
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                        <Modal
                          show={openedPopups.organizations[index]} // Control modal visibility
                          onHide={() => togglePopups('organizations', index)} // Close the modal
                          centered // Center the modal on the screen
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Edit Organizations</Modal.Title>
                          </Modal.Header>
                          <Modal.Body style={{ width: '100%' }}>
                            <OrganizationsPopup
                              portfolio={portfolio}
                              setOpenedPopups={setOpenedPopups}
                              setPopupsToAdd={setPopupsToAdd}
                              index={index}
                              setPortfolioData={setPortfolioData}
                              setSavePortfolioButton={setSavePortfolioButton}
                              popupVariant="editing"
                            />
                          </Modal.Body>
                        </Modal>
                      </Accordion.Item>
                    );
                  },
                )}
              </Accordion>
            </div>
          )}

          <RemoveModal
            showModal={!!removeModalData}
            onCloseModal={handleCloseModal}
            onSubmit={handleDeleteSubsection}
          />
        </Card.Body>
      )}
    </Card>
  );
};

export default UserProfileTabContent;
