import React, { ReactElement, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { fetchUserData } from '../Preview/api';
import { fetchPromptsData } from '../UserProfile/api';
import Spinner from 'react-bootstrap/Spinner';

type Props = {
  children: ReactElement;
};

const PrivateRoute: React.FC<Props> = ({ children }) => {
  const [loader, setLoader] = useState<boolean>(true);
  const navigate = useNavigate();

  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    const checkUserData = async () => {
      try {
        const userData = await fetchUserData();
        const promptsData = await fetchPromptsData();

        if (!userData.username) {
          navigate('/complete-profile');
          return;
        }

        if (!userData.profileImg) {
          navigate('/complete-profile-avatar');
          return;
        }

        if (
          userData.username &&
          userData.profileImg &&
          promptsData.length === 0
        ) {
          navigate('/create-prompt');
          return;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        navigate('/');
      } finally {
        setLoader(false);
      }
    };

    checkUserData();
  }, [navigate]);

  if (loader) {
    return (
      <div className="prompt-spinner">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return accessToken ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;
