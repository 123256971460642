import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FocusableInput, FocusableInputRef } from './FocusableInput';
import './TagListInput.css';
import { languages } from '../../config/languages';
import { Alert } from 'react-bootstrap';

interface TagListProps {
  selectOption: (item: string) => void;
  validationError?: string;
}

const TagListInput: React.FC<TagListProps> = ({
  selectOption,
  validationError,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [filteredDropdown, setFilteredDropdown] = useState<string[]>([]);

  const inputRef = useRef<FocusableInputRef>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    setSearch(inputValue);

    const filteredItems = languages.filter(
      (item: string) =>
        inputValue.length &&
        item?.toLowerCase().includes(inputValue.toLowerCase()),
    );

    setFilteredDropdown(filteredItems);
  };

  const handleClick = () => {
    if (!isOpen) {
      inputRef.current?.focus();
    }
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const target = e.target as HTMLButtonElement;
    const selectedItem = target.textContent;
    if (selectedItem) {
      selectOption(selectedItem);
      setSearch('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (e.key === 'Enter') {
      e.preventDefault();
      selectOption(search);
      setSearch('');
      target.value = '';
    }
  };

  return (
    <Form style={{ position: 'relative' }}>
      <FocusableInput
        className="focusable-input"
        ref={inputRef}
        onChange={handleChange}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        placeholder="Enter a language and press enter"
        validationError={validationError as string}
      />
      {validationError && <Alert variant="danger">{validationError}</Alert>}
      <div
        ref={dropdownRef}
        className={isOpen ? 'open optionWrapper ' : 'optionWrapper '}
      >
        <div>
          {search
            ? filteredDropdown.map((item, index) => (
                <button type="button" key={index} onClick={handleOptionClick}>
                  {item}
                </button>
              ))
            : languages.map((item: string, index: number) => (
                <button type="button" key={index} onClick={handleOptionClick}>
                  {item}
                </button>
              ))}
        </div>
      </div>
    </Form>
  );
};

export default TagListInput;
