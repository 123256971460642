import React, { useEffect, useRef } from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { validationMessages } from '../../config/validationMessages';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';
import {
  useFileAmountValidation,
  useProjectFileChange,
  useProjectFileDelete,
} from '../Create-Portfolio/customHooks';
import { Project } from '../Create-Portfolio/types';
import MultipleFilesUploadButton from '../FileUploadButton/MultipleFilesUploadButton';

interface ProjectItemProps {
  project: Project;
  updateProject: (updatedProject: Project) => void;
  errors: string[];
  validateProjectField: (index: number, field: string, value: string) => void;
  index: number;
  validateFilesSize: (files: File[], index: number) => void;
  sizeError: boolean[];
}

const { FILES_EXCEEDED_LIMIT_10MB } = validationMessages;

const ProjectItem: React.FC<ProjectItemProps> = ({
  project,
  updateProject,
  errors,
  validateProjectField,
  index,
  validateFilesSize,
  sizeError,
}) => {
  const { handleFileChange } = useProjectFileChange(project, updateProject);
  const { handleDeleteFile } = useProjectFileDelete(project, updateProject);
  const { fileError } = useFileAmountValidation(project.files, index);

  useEffect(() => {
    validateFilesSize(project.files, index);
  }, [project.files, index, validateFilesSize]);

  const handleInputChange = (field: string, value: string) => {
    const updatedProject = { ...project, [field]: value };

    updateProject(updatedProject);
    validateProjectField(index, field, value);
  };

  return (
    <Form className="project-item" id="add-form">
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Project Name
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="text"
            value={project.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            isInvalid={!!errors[0]}
          />
          <Form.Control.Feedback type="invalid">
            <Alert variant="danger">{errors[0]}</Alert>
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Description
        </Form.Label>
        <Col sm={9} style={{ position: 'relative' }}>
          <Form.Control
            as="textarea"
            rows={3}
            maxLength={50}
            value={project.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            isInvalid={!!errors[1]}
          />
          <Form.Control.Feedback type="invalid">
            <Alert variant="danger">{errors[1]}</Alert>
          </Form.Control.Feedback>
          <span
            className={
              errors[1]
                ? 'textarea-placeholder-with-error'
                : 'textarea-placeholder-without-error'
            }
          >
            Max: 50 characters
          </span>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Upload Supporting Files
        </Form.Label>
        <Col
          sm={9}
          style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}
        >
          <div className="select-project-file">
            {project.files.length < 2 && (
              <span>
                <MultipleFilesUploadButton
                  handleFileChange={handleFileChange}
                />
              </span>
            )}
          </div>
          {project.files &&
            project.files.map((file, fileIndex) => {
              const updatedName = file.name
                ? file?.name.replace(/_/g, ' ')
                : file?.name;
              return (
                <div className="selected-file" key={fileIndex}>
                  <span>{updatedName}</span>
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteFile(fileIndex)}
                  >
                    <DeleteIcon
                      width={20}
                      height={20}
                      className="prompt-delete-button"
                    />
                  </Button>
                </div>
              );
            })}
          {sizeError[index] && (
            <Alert variant="danger">{FILES_EXCEEDED_LIMIT_10MB}</Alert>
          )}
          {fileError && (
            <Alert
              variant="danger"
              style={{ fontSize: '1rem', fontWeight: '400' }}
            >
              {fileError}
            </Alert>
          )}
        </Col>
      </Form.Group>
    </Form>
  );
};

export default ProjectItem;
