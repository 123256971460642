import React, { useState, useEffect } from 'react';
import { workCultureList } from '../../config/work-culture-components';
import { Form, Col, Alert } from 'react-bootstrap';
import PrevAndNextButtons from '../Prev-Next-Buttons';
import './IdealWorkCulture.css';
import { Portfolio } from '../Create-Portfolio/types';
import { validationMessages } from '../../config/validationMessages';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';
import { useCustomOptionsHandlers } from '../Create-Portfolio/customHooks';

interface WorkCultureProps {
  handleNext: () => void;
  handlePrevious: () => void;
  showCustomInput: boolean;
  customOptions: string[];
  workCulture: string[];
  setWorkCulture: React.Dispatch<React.SetStateAction<Portfolio>>;
  setShowCustomInput: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomOptions: React.Dispatch<React.SetStateAction<string[]>>;
}

const { CHOOSE_MIN_ONE_OPTION, SHARE_OPTION } = validationMessages;

const WorkCultureComponent: React.FC<WorkCultureProps> = ({
  handleNext,
  handlePrevious,
  setWorkCulture,
  showCustomInput,
  customOptions,
  workCulture,
  setShowCustomInput,
  setCustomOptions,
}) => {
  const [validationError, setValidationError] = useState<string>('');
  const { handleAddExtraField, handleRemoveExtraField, handleCustomOption } =
    useCustomOptionsHandlers(customOptions, setCustomOptions);

  useEffect(() => {
    if (workCulture.length > 0 || customOptions.some((item) => item !== '')) {
      setValidationError('');
    }
  }, [workCulture, customOptions]);

  const handleWorkCulture = (options: string[], checked: boolean) => {
    setWorkCulture((prevPortfolio) => ({
      ...prevPortfolio,
      workCulture: checked
        ? [...prevPortfolio.workCulture, ...options]
        : prevPortfolio.workCulture.filter((item) => !options.includes(item)),
    }));
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = event.target.value;
    const checked = event.target.checked;

    if (option === 'Other (Custom)') {
      setShowCustomInput(checked);
      handleWorkCulture([option], checked);
    } else {
      handleWorkCulture([option], checked);
    }

    if (option === 'Other (Custom)' && !checked) {
      handleWorkCulture(customOptions, checked);
      setCustomOptions(['']);
    }
  };

  const handleSubmit = () => {
    if (customOptions && showCustomInput) {
      setWorkCulture((prevPortfolio) => ({
        ...prevPortfolio,
        workCulture: [...prevPortfolio.workCulture, ...customOptions].filter(
          Boolean,
        ),
      }));
    }
  };

  const validateForm = () => {
    if (workCulture.length === 0 && customOptions.some((item) => item === '')) {
      setValidationError(`${CHOOSE_MIN_ONE_OPTION}`);
      return false;
    }

    if (
      workCulture.length === 1 &&
      customOptions.some((item) => item === '') &&
      workCulture[0] === 'Other (Custom)'
    ) {
      setValidationError(`${SHARE_OPTION}`);
      return false;
    }

    return true;
  };

  const onNextClick = () => {
    if (validateForm()) {
      handleNext();
    }
  };

  const isAddExtraButtonDisabled =
    workCulture.length - 1 + customOptions.length >= 3;

  const isSelectionLimit =
    workCulture.filter((item) => item !== 'Other (Custom)').length +
      customOptions.filter(Boolean).length >=
    3;

  return (
    <section>
      <div className="container my-4">
        <h2 style={{ marginBottom: '10px' }}>Ideal Work Culture</h2>
        <p style={{ marginBottom: '25px' }}>
          Select up to three options that describe your ideal work culture.
        </p>
        <Form className="switch-form row g-3">
          {workCultureList.map(({ name, description }) => (
            <div className="col-md-6 mb-3" key={name}>
              <Form.Check
                disabled={!workCulture.includes(name) && isSelectionLimit}
                type="switch"
                id={name}
                label={name}
                onChange={handleSwitchChange}
                value={name}
                checked={workCulture.includes(name)}
              />
              {description && (
                <p className="description-work-culture">{description}</p>
              )}
            </div>
          ))}
          {showCustomInput && (
            <div className="d-flex position-relative">
              <button
                style={{
                  left: -30,
                }}
                type="button"
                className={`user-info-edit-button mt-4 me-1 position-absolute ${isAddExtraButtonDisabled ? 'opacity-25' : ''}`}
                onClick={handleAddExtraField}
                disabled={isAddExtraButtonDisabled}
              >
                <PlusIcon style={{ width: '25px', height: '25px' }} />
              </button>
              <div className="w-100">
                {customOptions.map((customOption, index) => (
                  <Col
                    key={index}
                    sm={9}
                    className="mb-3"
                    style={{ position: 'relative' }}
                  >
                    <Form.Control
                      disabled={!customOption && isSelectionLimit}
                      as="textarea"
                      rows={3}
                      maxLength={200}
                      value={customOption}
                      onChange={(e) =>
                        handleCustomOption(e.target.value, index)
                      }
                    />
                    <span className="textarea-placeholder">
                      Max: 200 characters
                    </span>
                    {customOptions.length >= 2 && (
                      <button
                        style={{
                          top: 8,
                          right: -30,
                        }}
                        type="button"
                        onClick={() => handleRemoveExtraField(index)}
                        className="user-info-edit-button position-absolute"
                      >
                        <DeleteIcon width={15} height={15} />
                      </button>
                    )}
                  </Col>
                ))}
              </div>
            </div>
          )}
          {validationError && <Alert variant="danger">{validationError}</Alert>}
        </Form>
        <PrevAndNextButtons
          handleNext={onNextClick}
          handlePrevious={handlePrevious}
          handleSubmit={handleSubmit}
        />
      </div>
    </section>
  );
};

export default WorkCultureComponent;
