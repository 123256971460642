import React, { useState } from 'react';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import './ProfessionalOrganization.css';
import { Organization } from '../Create-Portfolio/types';
import {
  CURRENT_PLACEHOLDER_DATE,
  maxDate,
  minDate,
} from '../../config/calendarMinMaxDates';

interface OrganizationItemProps {
  organization: Organization;
  errors: string[];
  updateOrganization: (updatedOrganization: Organization) => void;
  validateOrganizationField: (
    index: number,
    field: string,
    value: string,
  ) => void;
  index: number;
  showEndDate: boolean[];
  setShowEndDate: React.Dispatch<React.SetStateAction<boolean[]>>;
}

const OrganizationItem: React.FC<OrganizationItemProps> = ({
  organization,
  errors,
  updateOrganization,
  validateOrganizationField,
  index,
  showEndDate,
  setShowEndDate,
}) => {
  const [startDate, setStartDate] = useState(organization.startDate || '');

  const handleInputChange = (field: string, value: string) => {
    const updatedOrganization = { ...organization, [field]: value };
    updateOrganization(updatedOrganization);
    validateOrganizationField(index, field, value);
  };

  const handleStartDateChange = (value: string) => {
    handleInputChange('startDate', value);
    setStartDate(value);
  };

  const handleSwitch = () => {
    setShowEndDate((prev) =>
      prev.map((item, itemIndex) => (itemIndex === index ? !item : item)),
    );

    if (organization.endDate === '') {
      const updatedOrganization = {
        ...organization,
        endDate: CURRENT_PLACEHOLDER_DATE,
      };
      updateOrganization(updatedOrganization);
    } else if (
      organization.endDate !== '' &&
      organization.endDate !== CURRENT_PLACEHOLDER_DATE
    ) {
      const updatedOrganization = {
        ...organization,
        endDate: CURRENT_PLACEHOLDER_DATE,
      };
      updateOrganization(updatedOrganization);
    } else {
      const updatedOrganization = {
        ...organization,
        endDate: '',
      };
      updateOrganization(updatedOrganization);
    }
  };

  return (
    <Form className="certification-item">
      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Label column sm={3}>
            Name of organization
          </Form.Label>
          <Form.Control
            type="text"
            value={organization ? organization.name : ''}
            onChange={(e) => handleInputChange('name', e.target.value)}
          />
          {errors[0] && <Alert variant="danger">{errors[0]}</Alert>}
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Row>
          <Col>
            <Form.Label column>Start Date</Form.Label>
            <input
              type="date"
              className="form-control"
              value={organization ? (organization.startDate as string) : ''}
              onChange={(e) => handleStartDateChange(e.target.value)}
              min={minDate}
              max={maxDate}
            />
            {errors[1] && <Alert variant="danger">{errors[1]}</Alert>}
          </Col>

          {showEndDate[index] && (
            <Col>
              <Form.Label column>End Date</Form.Label>
              <input
                type="date"
                className="form-control"
                value={organization ? (organization.endDate as string) : ''}
                onChange={(e) => handleInputChange('endDate', e.target.value)}
                min={startDate || minDate}
                max={maxDate}
              />
              {errors[2] && <Alert variant="danger">{errors[2]}</Alert>}
            </Col>
          )}
        </Row>
        <Col>
          <Form.Check
            className="col-md-6"
            type="switch"
            label="Current organization"
            onChange={handleSwitch}
            value="Current"
            checked={!showEndDate[index]}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Label column sm={3}>
            Brief description
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={organization ? organization.description : ''}
            onChange={(e) => handleInputChange('description', e.target.value)}
          />
          {errors[3] && <Alert variant="danger">{errors[3]}</Alert>}
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Label column sm={3}>
            Organization link
          </Form.Label>
          <Form.Control
            type="text"
            value={organization ? organization.link : ''}
            onChange={(e) => handleInputChange('link', e.target.value)}
          />
          {errors[4] && <Alert variant="danger">{errors[4]}</Alert>}
        </Col>
      </Form.Group>
    </Form>
  );
};

export default OrganizationItem;
