import React from 'react';
import { ListGroup, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Preview.css';
import { ReactComponent as LNIcon } from '../../images/linkedin.svg';
import { convertDaysToReadableTime } from './helpers';
import { ReactComponent as EditIcon } from '../../images/edit.svg';

interface UserPropTypes {
  userData: any;
}

const UserInformationList: React.FC<UserPropTypes> = ({ userData }) => {
  const navigate = useNavigate();
  const handleEdit = () => navigate('/user-settings');

  return (
    <Card className="info-card sleek-card">
      <Card.Body>
        <button
          type="button"
          className="user-info-edit-button preview-edit-btn"
          onClick={handleEdit}
        >
          <EditIcon style={{ width: '15px', height: '15px' }} />
        </button>
        <ListGroup className="list-group-flush list-font">
          <ListGroup.Item className="info-section">
            <div className="info-heading">Gender</div>
            <div className="info-response">
              {userData?.gender ? (
                <>{userData?.gender && <span>{userData.gender}</span>}</>
              ) : (
                <span>Gender</span>
              )}
            </div>
          </ListGroup.Item>

          <ListGroup.Item className="info-section">
            <div className="info-heading">Location</div>
            <div className="info-response">
              {userData?.city || userData?.country || userData?.state ? (
                <>
                  {userData?.city && <span>{userData.city}</span>}
                  {(userData?.city || userData?.state) && ', '}
                  {userData?.state && <span>{userData.state}</span>}
                  {(userData?.state || userData?.country) && ', '}
                  {userData?.country && <span>{userData.country}</span>}
                </>
              ) : (
                <span>Your location</span>
              )}
            </div>
          </ListGroup.Item>

          <ListGroup.Item className="info-section">
            <div className="info-heading">Phone Number</div>
            <div className="info-response">
              {userData?.phoneNumber ? (
                <a href={`tel:+${userData.phoneNumber}`} className="info-link">
                  +{userData.phoneNumber}
                </a>
              ) : (
                <span>Your phone number</span>
              )}
            </div>
          </ListGroup.Item>

          <ListGroup.Item className="info-section">
            <div className="info-heading">Email</div>
            <div className="info-response">
              {userData?.email ? (
                <span>{userData.email}</span>
              ) : (
                <span>Your email</span>
              )}
            </div>
          </ListGroup.Item>

          <ListGroup.Item className="info-section">
            <div className="info-heading">Date of Birth</div>
            <div className="info-response">
              {userData?.dateOfBirth ? (
                <span>{userData.dateOfBirth}</span>
              ) : (
                <span>Your date of birth</span>
              )}
            </div>
          </ListGroup.Item>

          <ListGroup.Item className="info-section">
            <div className="info-heading">Current Company</div>
            <div className="info-response">
              {userData?.company ? (
                <span>{userData.company}</span>
              ) : (
                <span>Current Company</span>
              )}
            </div>
          </ListGroup.Item>

          <ListGroup.Item className="info-section">
            <div className="info-heading">Willing to Relocate</div>
            <div className="info-response">
              {userData?.willingToRelocate ? (
                <span>{userData.willingToRelocate}</span>
              ) : (
                <span>Not sure</span>
              )}
            </div>
          </ListGroup.Item>

          <ListGroup.Item className="info-section">
            <div className="info-heading">Preferred Work Arrangements</div>
            <div className="info-response">
              {userData?.workArrangements ? (
                <span>{userData.workArrangements}</span>
              ) : (
                <span>Preferred work arrangements</span>
              )}
            </div>
          </ListGroup.Item>

          {userData?.salary && userData.salary !== 'Not indicated' && (
            <ListGroup.Item className="info-section">
              <div className="info-heading">Salary</div>
              <div className="info-response">$ {userData.salary}</div>
            </ListGroup.Item>
          )}

          <ListGroup.Item className="info-section">
            <div className="info-heading">Time Needed to Start</div>
            <div className="info-response">
              {userData?.timeToStart ? (
                <span>{convertDaysToReadableTime(userData.timeToStart)}</span>
              ) : (
                <span>Preferred time to start arrangements</span>
              )}
            </div>
          </ListGroup.Item>

          {userData?.flexibleType && (
            <ListGroup.Item className="info-section">
              <div className="info-heading">Flexibility</div>
              <div className="info-response">{userData.flexibleType}</div>
            </ListGroup.Item>
          )}

          {userData?.linkedin &&
            userData.linkedin.includes('www.linkedin.com') && (
              <ListGroup.Item className="info-section">
                <div className="info-heading">Socials</div>
                <div className="">
                  <a
                    href={userData.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LNIcon style={{ width: '45px', height: '45px' }} />
                  </a>
                </div>
              </ListGroup.Item>
            )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default UserInformationList;
