import React, { useState, useEffect } from 'react';
import {
  LeftSidePopupsForAdding,
  Portfolio,
  ReturnedPortfolio,
  ReturnedReference,
} from '../Create-Portfolio/types';
import {
  Button,
  Card,
  ButtonGroup,
  CloseButton,
  Alert,
  Modal,
} from 'react-bootstrap';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { ReactComponent as PrevIcon } from '../../images/arrow-prev.svg';
import { ReactComponent as NextIcon } from '../../images/arrow-next.svg';
import { ReactComponent as EditIcon } from '../../images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';
import { isReferenceFilled } from '../Create-Portfolio/helpers';
import { workCultureArray } from '../../config/work-culture-components';
import { levels } from '../../config/languages';
import ReferencePopup from '../Popups/ReferencePopup';
import WorkCulturePopup from '../Popups/WorkCulturePopup';
import LanguagePopup from '../Popups/LanguagePopup';
import './Preview.css';
import RemoveModal from '../RemoveModal';
import axiosInstance from '../../api/api';
import { API_ROUTES } from '../../config/api-routes';

interface PortfolioCardsProps {
  portfolio: Portfolio | ReturnedPortfolio;
  userProfile?: string;
  setPortfolioData?: React.Dispatch<React.SetStateAction<ReturnedPortfolio>>;
  setSavePortfolioButton?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PortfolioCards: React.FC<PortfolioCardsProps> = ({
  portfolio,
  userProfile,
  setPortfolioData,
  setSavePortfolioButton,
}) => {
  const {
    references,
    languages,
    workCulture,
    isReferencesEnabled,
    isLanguagesEnabled,
    isWorkCultureEnabled,
  } = portfolio;

  const [currentReferenceIndex, setCurrentReferenceIndex] = useState<number>(0);
  const [customOptions, setCustomOptions] = useState<string[]>([]);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

  const [openedPopupsToAdd, setOpenedPopupsToAdd] =
    useState<LeftSidePopupsForAdding>({
      references: false,
      languages: false,
      workCulture: false,
    });
  const [openedPopupsToEdit, setOpenedPopupsToEdit] = useState<{
    references: boolean[];
  }>({
    references: portfolio?.references.map(() => false),
  });
  const [message, setMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const togglePopups = (popupName: 'references', index: number) => {
    setOpenedPopupsToEdit((prev) => {
      const popupArray = prev[popupName];
      if (Array.isArray(popupArray)) {
        const newPopupArray = [...popupArray];
        newPopupArray[index] = !newPopupArray[index];
        return {
          ...prev,
          [popupName]: newPopupArray,
        };
      }
      return prev;
    });
  };

  const handleDeleteLanguage = (index: number) => {
    const updatedLanguages = languages.filter((_, i) => i !== index);

    if (userProfile && setPortfolioData && setSavePortfolioButton) {
      setPortfolioData((prev) => ({ ...prev, languages: updatedLanguages }));
      setSavePortfolioButton(true);
      scrollToPortfolioButton();
    }
  };

  const scrollToPortfolioButton = () => {
    const saveButton = document.getElementById('port-btn');
    if (saveButton) {
      saveButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleTogglePopupsForAdding = (
    field: keyof LeftSidePopupsForAdding,
  ) => {
    setOpenedPopupsToAdd((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handlePrev = () => {
    setCurrentReferenceIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : references.length - 1,
    );
  };

  const handleNext = () => {
    setCurrentReferenceIndex((prevIndex) =>
      prevIndex < references.length - 1 ? prevIndex + 1 : 0,
    );
  };

  const filteredWorkCulture = workCulture?.filter(
    (item) => workCultureArray.includes(item) && item !== 'Other (Custom)',
  );

  useEffect(() => {
    const customWorkCulture = workCulture?.filter(
      (item) => !workCultureArray.includes(item),
    );
    if (customWorkCulture) {
      setCustomOptions(customWorkCulture);
    }
  }, [workCulture]);

  const handleOpenRemoveModal = () => setShowRemoveModal(true);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const handleRemoveReference = async () => {
    try {
      const referenceId = references[currentReferenceIndex].id;
      const response = await axiosInstance.delete(
        `${API_ROUTES.REFERENCE.UPDATE_REFERENCE}/${referenceId}`,
      );
      if (response.status === 200) {
        const updatedReferences = references.filter(
          (_, index) => index !== currentReferenceIndex,
        );
        setPortfolioData?.((prev) => ({
          ...prev,
          references: updatedReferences,
        }));
        setCurrentReferenceIndex((prev) => (prev > 0 ? prev - 1 : 0));
        setSavePortfolioButton?.(true);
      } else {
        console.error('Failed to delete reference');
      }
    } catch (error) {
      console.error('Error deleting reference:', error);
    }
    setShowRemoveModal(false);
  };

  const handleResendConfirmationEmail = async () => {
    try {
      const referenceId = references[currentReferenceIndex].id;
      const response = await axiosInstance.post(
        `${API_ROUTES.REFERENCE.RESEND_CONFIRMATION_EMAIL}?referenceId=${referenceId}`,
      );

      if (response.status === 200) {
        setMessage('Confirmation email resent successfully!');
      } else {
        console.error('Failed to resend confirmation email');
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again later.');
    }
  };

  return (
    <>
      {isReferencesEnabled && (
        <Card>
          <Card.Header className="preview-cards-ref-header">
            <span>References</span>
            <div>
              {userProfile === 'profile' ? (
                <button
                  type="button"
                  className="user-info-edit-button"
                  style={{ position: 'relative' }}
                >
                  <PlusIcon
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                    onClick={() => handleTogglePopupsForAdding('references')}
                  />
                </button>
              ) : (
                <PlusIcon
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              )}

              {openedPopupsToAdd.references && (
                <Modal
                  show={openedPopupsToAdd.references} // Control modal visibility
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add References</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ width: '100%' }}>
                    <ReferencePopup
                      setPopupOpen={setOpenedPopupsToAdd}
                      setPortfolioData={setPortfolioData}
                      setSavePortfolioButton={setSavePortfolioButton}
                    />
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </Card.Header>
          <Card.Body>
            <div>
              <span></span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                {references?.length ? (
                  <div>
                    <span>{currentReferenceIndex + 1}</span>
                    <span>{` of ${references.length}`}</span>
                  </div>
                ) : (
                  '0 of 0'
                )}
                <div className="d-flex align-items-center">
                  <button type="button" className="user-info-edit-button">
                    <EditIcon
                      style={{ width: '15px', height: '15px' }}
                      onClick={() =>
                        togglePopups('references', currentReferenceIndex)
                      }
                    />
                    {openedPopupsToEdit.references[currentReferenceIndex] && (
                      <Modal
                        show={
                          openedPopupsToEdit.references[currentReferenceIndex]
                        } // Control modal visibility
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Edit References</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ width: '100%' }}>
                          <ReferencePopup
                            initialReference={references[currentReferenceIndex]}
                            setOpenedPopups={setOpenedPopupsToEdit}
                            setPortfolioData={setPortfolioData}
                            setSavePortfolioButton={setSavePortfolioButton}
                            index={currentReferenceIndex}
                          />
                        </Modal.Body>
                      </Modal>
                    )}
                  </button>

                  <ButtonGroup size="sm">
                    <Button
                      variant="outline-secondary"
                      onClick={handlePrev}
                      disabled={!references?.length}
                    >
                      <PrevIcon style={{ width: '15px', height: '15px' }} />
                    </Button>
                    <Button
                      variant="outline-secondary"
                      onClick={handleNext}
                      disabled={!references?.length}
                    >
                      <NextIcon style={{ width: '15px', height: '15px' }} />
                    </Button>
                  </ButtonGroup>
                </div>
                <RemoveModal
                  showModal={showRemoveModal}
                  onCloseModal={handleCloseRemoveModal}
                  onSubmit={handleRemoveReference}
                />
              </div>
            </div>
            {references?.length > 0 && references.every(isReferenceFilled) && (
              <div className="preview-ref-content">
                {userProfile === 'profile' &&
                references[currentReferenceIndex] &&
                !(references[currentReferenceIndex] as ReturnedReference)
                  .isReferenceConfirmed ? (
                  <>
                    <Alert variant="danger" style={{ margin: '0 auto' }}>
                      Confirmation Required: Verify View in Email
                    </Alert>
                    <Button
                      variant="success"
                      style={{
                        margin: '10px auto',
                      }}
                      onClick={handleResendConfirmationEmail}
                    >
                      Resend Confirmation Email
                    </Button>
                    {message && (
                      <Alert variant="success" style={{ margin: '0 auto' }}>
                        {message}
                      </Alert>
                    )}
                    {errorMessage && (
                      <Alert variant="danger" style={{ margin: '0 auto' }}>
                        {errorMessage}
                      </Alert>
                    )}
                  </>
                ) : (
                  <>
                    <h5>{references[currentReferenceIndex]?.name}</h5>
                    <p>{references[currentReferenceIndex]?.role}</p>
                    <p>{references[currentReferenceIndex]?.organization}</p>
                    <p>{references[currentReferenceIndex]?.email}</p>
                  </>
                )}
                <button
                  onClick={handleOpenRemoveModal}
                  className="reference-delete-button ms-1 me-3"
                >
                  <DeleteIcon width={15} height={15} />
                </button>
              </div>
            )}
          </Card.Body>
        </Card>
      )}
      {isWorkCultureEnabled && (
        <Card>
          <Card.Header className="preview-cards-ref-header">
            <span>My Ideal Work Culture</span>
            <div>
              {userProfile === 'profile' ? (
                <button type="button" className="user-info-edit-button">
                  <EditIcon
                    style={{ width: '15px', height: '15px' }}
                    onClick={() => handleTogglePopupsForAdding('workCulture')}
                  />
                </button>
              ) : (
                <EditIcon style={{ width: '15px', height: '15px' }} />
              )}

              {openedPopupsToAdd.workCulture && (
                <Modal
                  show={openedPopupsToAdd.workCulture} // Control modal visibility
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Work Culture</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ width: '100%' }}>
                    <WorkCulturePopup
                      setPopupOpen={setOpenedPopupsToAdd}
                      setPortfolioData={setPortfolioData}
                      setSavePortfolioButton={setSavePortfolioButton}
                      workCulture={workCulture}
                    />
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </Card.Header>
          <Card.Body>
            <div className="preview-work-wrapper mb-3">
              {filteredWorkCulture?.map((item, index) => (
                <span key={index} className="preview-spans preview-span-green">
                  {item}
                </span>
              ))}
            </div>
            {customOptions.map((customOption) => (
              <div className="preview-work-wrapper mb-3">
                <span className="preview-spans preview-span-green">
                  {customOption}
                </span>
              </div>
            ))}
          </Card.Body>
        </Card>
      )}
      {isLanguagesEnabled && (
        <Card>
          <Card.Header className="preview-cards-ref-header">
            <span>Languages</span>
            {userProfile === 'profile' ? (
              <button type="button" className="user-info-edit-button">
                <PlusIcon
                  style={{ width: '20px', height: '20px' }}
                  onClick={() => handleTogglePopupsForAdding('languages')}
                />
              </button>
            ) : (
              <PlusIcon style={{ width: '20px', height: '20px' }} />
            )}
            {openedPopupsToAdd.languages && (
              <Modal
                show={openedPopupsToAdd.languages} // Control modal visibility
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Languages</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ width: '100%' }}>
                  <LanguagePopup
                    setPopupOpen={setOpenedPopupsToAdd}
                    languages={languages}
                    setPortfolioData={setPortfolioData}
                    setSavePortfolioButton={setSavePortfolioButton}
                  />
                </Modal.Body>
              </Modal>
            )}
          </Card.Header>
          <Card.Body>
            <div className="preview-colors-wrapper">
              {levels.map(({ level, color }) => (
                <span
                  key={level}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span style={{ color: color, marginRight: '8px' }}>⬤</span>
                  <span>{level}</span>
                </span>
              ))}
            </div>
            <div className="preview-work-wrapper">
              {userProfile === 'profile'
                ? languages.map((item, index) => (
                    <div
                      key={index}
                      className={
                        item.level === 'Beginner'
                          ? 'preview-spans preview-span-yellow'
                          : item.level === 'Intermediate'
                            ? 'preview-spans preview-span-orange'
                            : item.level === 'Fluent'
                              ? 'preview-spans preview-span-green'
                              : 'preview-spans preview-span-red'
                      }
                      onMouseEnter={(e) =>
                        e.currentTarget.classList.add('hovered')
                      }
                      onMouseLeave={(e) =>
                        e.currentTarget.classList.remove('hovered')
                      }
                    >
                      <span>{item.language}</span>
                      <CloseButton
                        style={{ width: '16px', height: '16px' }}
                        className="close-button"
                        onClick={() => handleDeleteLanguage(index)}
                      />
                    </div>
                  ))
                : languages.map((item, index) => (
                    <span
                      key={index}
                      className={
                        item.level === 'Beginner'
                          ? 'preview-spans preview-span-yellow'
                          : item.level === 'Intermediate'
                            ? 'preview-spans preview-span-orange'
                            : item.level === 'Fluent'
                              ? 'preview-spans preview-span-green'
                              : 'preview-spans preview-span-red'
                      }
                    >
                      {item.language}
                    </span>
                  ))}
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default PortfolioCards;
