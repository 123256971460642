import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { PopupsState, ReturnedPortfolio } from '../Create-Portfolio/types';
import '../Preview/Preview.css';

interface Props {
  portfolio: ReturnedPortfolio;
  setOpenedPopups: React.Dispatch<React.SetStateAction<PopupsState>>;
  setPortfolioData: React.Dispatch<React.SetStateAction<ReturnedPortfolio>>;
  setSavePortfolioButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const OverviewPopup: React.FC<Props> = ({
  portfolio,
  setOpenedPopups,
  setPortfolioData,
  setSavePortfolioButton,
}) => {
  const [overview, setOverview] = useState<string>(portfolio.overview);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setOpenedPopups((prev: any) => ({
          ...prev,
          overview: false,
        }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, setOpenedPopups]);

  const handleOverviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOverview(event.target.value);
  };

  const handleOverviewSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPortfolioData((prev) => ({ ...prev, overview: overview }));
    setSavePortfolioButton(true);
    scrollToPortfolioButton();
    setOpenedPopups((prev: any) => ({
      ...prev,
      overview: false,
    }));
  };

  const scrollToPortfolioButton = () => {
    const saveButton = document.getElementById('port-btn');
    if (saveButton) {
      saveButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Card className="user-popup p-3 popup-card" ref={popupRef}>
      <Card.Body>
        <Form onSubmit={handleOverviewSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              className="popup-textarea"
              rows={5}
              placeholder="Write a brief introduction about yourself here"
              defaultValue={portfolio.overview}
              onChange={handleOverviewChange}
              required
            />
          </Form.Group>
          <Button variant="success" type="submit">
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default OverviewPopup;
