import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Col,
  Row,
  Button,
  Card,
  Modal,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';

import { ReturnedPortfolio } from '../Create-Portfolio/types';
import './Preview.css';
import { API_ROUTES, BASE_URL } from '../../config/api-routes';
import { ReactComponent as EditIcon } from '../../images/edit.svg';
import { ReactComponent as ShareIcon } from '../../images/share-fill.svg';
import { ReactComponent as RefreshIcon } from '../../images/arrow-clockwise.svg';
import { ReactComponent as CopyIcon } from '../../images/link-45deg.svg';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/api';
import SkillsInput from '../Skills-Input';
import { Prompt } from '../UserProfile/PromptDataTabContent';

interface PanelProps extends promptsTabProps {
  userData: any;
  portfolioId?: number;
  portfolio?: ReturnedPortfolio;
  savePortfolioButton?: boolean;
  setSavePortfolioButton?: React.Dispatch<React.SetStateAction<boolean>>;
  getData?: () => void;
  setUserData?: React.Dispatch<any>;
  jobTitle: string;
}

interface promptsTabProps {
  prompts?: Prompt[];
  setPromptsData?: Dispatch<SetStateAction<any>>;
}

const UserPanel: React.FC<PanelProps> = ({
  userData,
  setUserData,
  savePortfolioButton,
  portfolio,
  portfolioId,
  setSavePortfolioButton,
  getData,
  jobTitle,
  setPromptsData,
}) => {
  const [skills, setSkills] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [show, setShareModal] = useState<boolean>(false);
  const [showEditJobRoleModal, setShowEditJobRoleModal] = useState(false);
  const [showDataWarningModal, setShowDataWarningModal] = useState(false);
  const [profileLink, setProfileLink] = useState(
    `${BASE_URL}/${userData?.username}`,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.skills) {
      setSkills(userData.skills);
    }
  }, [userData]);

  const handleUpdateSkills = (updatedSkills: string[]) =>
    setSkills(updatedSkills);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.put(API_ROUTES.USER.UPDATE_USER, {
        ...userData,
        skills,
      });

      if (!response || response.status !== 200) {
        throw new Error(`Failed to update skills: ${response.statusText}`);
      }

      setUserData?.((prevState: any) => ({ ...prevState, skills }));
      setShowModal(false);
    } catch (error) {
      console.error('Failed to update skills:', error);
    }
  };

  const handleEditAvatar = () => navigate('/complete-profile-avatar?mode=edit');

  const onModalOpen = () => setShowModal(true);
  const onShareModalOpen = () => setShareModal(true);

  const onModalClose = () => {
    setShowModal(false);
    setSkills(userData.skills);
  };

  const handleClose = () => {
    setShareModal(false);
  };

  // Function to copy the link
  const copyToClipboard = () => {
    navigator.clipboard.writeText(profileLink);
    alert('Link copied to clipboard!');
  };

  // Function to generate a new random link
  const generateRandomLink = () => {
    const randomString = Math.random().toString(36).substring(2, 7); // Generates a random string
    setProfileLink(`${BASE_URL}/${randomString}`);
  };

  const handleEditClick = () => {
    setShowEditJobRoleModal(true);
  };

  const handleEditJobRoleConfirm = () => {
    setShowEditJobRoleModal(false);
    setShowDataWarningModal(true); // Open the second modal after confirming
  };

  const handleDataWarningConfirm = () => {
    // Logic for deleting screening prompts data
    handleDeleteAllPrompts();
    console.log('Screening prompts and data deleted');
    setShowDataWarningModal(false); // Close the second modal
  };

  const handleCloseModals = () => {
    setShowEditJobRoleModal(false);
    setShowDataWarningModal(false);
  };

  const handleDeleteAllPrompts = async () => {
    try {
      // Call the API to delete all prompts
      const response = await axiosInstance.delete(
        `${BASE_URL}${API_ROUTES.PROMPTS.DELETE_ALL_PROMPTS}`,
      );

      // Here, after deleting the data, we navigate to '/create-prompt'
      navigate('/create-prompt');

      if (!response) {
        throw new Error(`Failed to delete all prompts: ${response}`);
      }

      // Update the prompts state by setting it to an empty array
      if (setPromptsData) {
        setPromptsData([]);
      }
      handleCloseModals();
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  return (
    <Card className="profile-card mb-3">
      <div className="profile-header">
        <div className="profile-image-wrapper">
          {userData?.profileImg ? (
            <div style={{ position: 'relative' }}>
              <img
                src={userData.profileImg}
                alt="Profile Avatar"
                className="profile-avatar"
              />
              <button
                type="button"
                className="user-info-edit-button"
                onClick={handleEditAvatar}
              >
                <EditIcon style={{ width: '15px', height: '15px' }} />
              </button>
            </div>
          ) : (
            <div className="default-avatar"></div>
          )}
        </div>
      </div>

      <Card.Body className="profile-body">
        <h3 className="profile-name">
          {userData?.firstName && userData?.lastName
            ? `${userData.firstName} ${userData.lastName}`
            : 'John Doe'}
        </h3>
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            width: 'fit-content',
          }}
        >
          <h2 className="profile-title">{jobTitle || ''}</h2>
          <button
            type="button"
            className="user-info-edit-button"
            onClick={handleEditClick}
            style={{
              position: 'absolute',
              right: '-17px',
              transform: 'translateY(-44%)',
            }}
          >
            <EditIcon style={{ width: '15px', height: '15px' }} />
          </button>
        </div>

        {/* Edit Job Role Modal */}
        <Modal show={showEditJobRoleModal} onHide={handleCloseModals} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Job Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to modify your job role?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModals}>
              No
            </Button>
            <Button variant="success" onClick={handleEditJobRoleConfirm}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Data Deletion Warning Modal */}
        <Modal show={showDataWarningModal} onHide={handleCloseModals} centered>
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Changing your job role will delete your current screening prompts
            and all associated data. Do you want to proceed?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModals}>
              No
            </Button>
            <Button variant="danger" onClick={handleDataWarningConfirm}>
              Yes, Delete Data
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal Trigger for Skills */}
        <div className="skills-container">
          {userData?.skills && (
            <div
              style={{
                display: 'flex',
                gap: '10px',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {userData.skills.map((skill: string, index: string) => (
                <div key={index} className="skill-badge">
                  {skill}
                </div>
              ))}

              <button
                type="button"
                className="user-info-edit-button"
                onClick={onModalOpen}
              >
                <PlusIcon style={{ width: '25px', height: '25px' }} />
              </button>
            </div>
          )}
        </div>

        {/* Modal for Editing Skills */}
        <Modal show={showModal} onHide={onModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '20px' }}>Skills</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SkillsInput skills={skills} onUpdateSkills={handleUpdateSkills} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={handleSubmit}
              style={{
                fontWeight: '700',
                fontSize: '16px',
                borderRadius: '4px',
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <div style={{ margin: '19px auto' }}>
          <Button
            variant="success"
            className="share-profile-button share-button-container"
            onClick={onShareModalOpen}
          >
            <ShareIcon style={{ width: '15px', height: '15px' }} /> Share
            Profile
          </Button>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          className="copy-link-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '20px' }}>
              Share Your Profile
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup>
              <FormControl
                type="text"
                className="copy-link-textbox"
                value={profileLink}
                readOnly
              />
              <Button
                variant="outline-secondary"
                onClick={copyToClipboard}
                className="copy-button"
              >
                <CopyIcon style={{ width: '15px', height: '15px' }} /> Copy
              </Button>
            </InputGroup>
            <Button
              onClick={generateRandomLink}
              className="mt-3 change-link-button"
            >
              <RefreshIcon style={{ width: '15px', height: '15px' }} /> Change
              Link
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Card.Body>
    </Card>
  );
};

export default UserPanel;
