import React, { useEffect } from 'react';
import FileUploadComponent from '../FileUploadButton/SingleFileUploadButton';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { validationMessages } from '../../config/validationMessages';
import './Certifications.css';
import { Certification } from '../Create-Portfolio/types';
import { maxDate, minDate } from '../../config/calendarMinMaxDates';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';

interface CertificationItemProps {
  certification: Certification;
  errors: string[];
  updateCertification: (updatedCertification: Certification) => void;
  validateCertificationField: (
    index: number,
    field: string,
    value: string,
  ) => void;
  index: number;
  setSizeError: React.Dispatch<React.SetStateAction<boolean[]>>;
  sizeError: boolean[];
}

const { FILE_EXCEEDED_LIMIT_4MB } = validationMessages;

const CertificationItem: React.FC<CertificationItemProps> = ({
  certification,
  errors,
  updateCertification,
  validateCertificationField,
  index,
  setSizeError,
  sizeError,
}) => {
  useEffect(() => {
    const maxSizeInBytes = 10 * 1024 * 1024;
    const { file } = certification;

    if (file !== null) {
      if (file.size <= maxSizeInBytes) {
        setSizeError((prev) =>
          prev.map((item, itemIndex: number) =>
            index === itemIndex ? false : item,
          ),
        );
      }

      if (file.size > maxSizeInBytes) {
        setSizeError((prev) =>
          prev.map((item, itemIndex: number) =>
            index === itemIndex ? true : item,
          ),
        );
      }
    }

    if (file === null) {
      setSizeError((prev) =>
        prev.map((item, itemIndex: number) =>
          index === itemIndex ? false : item,
        ),
      );
    }
  }, [certification, index, setSizeError]);

  const handleInputChange = (field: string, value: string) => {
    const updatedCertification = { ...certification, [field]: value };
    updateCertification(updatedCertification);
    validateCertificationField(index, field, value);
  };

  const handleFileChange = (file: File) => {
    const updatedCertification = { ...certification, file: file };
    updateCertification(updatedCertification);
  };

  const handleDeleteFile = () => {
    const updatedCertification = { ...certification, file: null };
    updateCertification(updatedCertification);
  };

  return (
    <Form className="certification-item">
      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Label column sm={3}>
            Certification Name
          </Form.Label>
          <Form.Control
            type="text"
            value={certification ? certification.name : ''}
            onChange={(e) => handleInputChange('name', e.target.value)}
          />
          {errors[0] && <Alert variant="danger">{errors[0]}</Alert>}
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Label column sm={3}>
            Date Received
          </Form.Label>
          <input
            type="date"
            className="form-control"
            value={certification ? certification.date : ''}
            onChange={(e) => handleInputChange('date', e.target.value)}
            min={minDate}
            max={maxDate}
          />
          {errors[1] && <Alert variant="danger">{errors[1]}</Alert>}
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Label column sm={3}>
            Upload Certificate
          </Form.Label>
          {certification.file ? (
            <div className="selected-file">
              <span>{certification.file.name}</span>
              <Button variant="danger" onClick={handleDeleteFile}>
                <DeleteIcon
                  width={20}
                  height={20}
                  className="prompt-delete-button"
                />
              </Button>
            </div>
          ) : (
            <div className="select-project-file">
              <FileUploadComponent handleFileChange={handleFileChange} />
              {errors[2] && <Alert variant="danger">{errors[2]}</Alert>}
            </div>
          )}
          {sizeError[index] && (
            <Alert variant="danger">{FILE_EXCEEDED_LIMIT_4MB}</Alert>
          )}
        </Col>
      </Form.Group>
    </Form>
  );
};

export default CertificationItem;
