import React, { useState, useCallback } from 'react';
import { maxDate, minDate } from '../../config/calendarMinMaxDates';
import { validationMessages } from '../../config/validationMessages';
import { newAccomplishment } from '../Create-Portfolio/initialValues';
import { Accomplishment, Portfolio } from '../Create-Portfolio/types';
import PortfolioItemsWrapper from '../Portfolio-Items-Wrapper';
import PrevAndNextButtons from '../Prev-Next-Buttons';
import AccomplishmentItem from './AccomplishmentItem';

export interface AccomplishmentErrors {
  name: string;
  date: string;
  description: string;
  links: string;
}

interface AccomplishmentProps {
  handleNext: () => void;
  handlePrevious: () => void;
  accomplishments: Accomplishment[];
  setAccomplishments: React.Dispatch<React.SetStateAction<Portfolio>>;
}

const {
  PROVIDE_NAME,
  PROVIDE_DATE,
  PROVIDE_DESCRIPTION,
  ENSURE_ALL_LINKS_PROVIDED,
  PROVIDE_LINK,
  DATE_OUT_OF_RANGE,
} = validationMessages;

const Accomplishments: React.FC<AccomplishmentProps> = ({
  handleNext,
  handlePrevious,
  setAccomplishments,
  accomplishments,
}) => {
  const [validationErrors, setValidationErrors] = useState<string[][]>(
    accomplishments.map(() => ['', '', '', '']),
  );
  const [sizeError, setSizeError] = useState<boolean[]>([false]);

  const addAccomplishmentItem = () => {
    const allAccomplishments = [...accomplishments, newAccomplishment];

    setAccomplishments((prevPortfolio) => ({
      ...prevPortfolio,
      accomplishments: allAccomplishments,
    }));

    setValidationErrors((prev) => [...prev, ['', '', '', '']]);
    setSizeError((prev) => [...prev, false]);
  };

  const removeAccomplishmentItem = (index: number) => {
    const updatedAccomplishments = accomplishments.filter(
      (_, i) => i !== index,
    );

    setAccomplishments((prevPortfolio) => ({
      ...prevPortfolio,
      accomplishments: updatedAccomplishments,
    }));

    setValidationErrors((prev) => prev.filter((_, i) => i !== index));
    setSizeError((prev) => prev.filter((_, i) => i !== index));
  };

  const updateAccomplishmentItem = (
    index: number,
    updatedAccomplishment: Accomplishment,
  ) => {
    const updatedAccomplishments = accomplishments.map((accomplishment, i) =>
      i === index ? updatedAccomplishment : accomplishment,
    );

    setAccomplishments((prevPortfolio) => ({
      ...prevPortfolio,
      accomplishments: updatedAccomplishments,
    }));
  };

  const validateFilesSize = useCallback(
    (files: File[], index: number) => {
      const maxSizeInBytes = 10 * 1024 * 1024;

      const invalidFiles = files.some((file) => file.size > maxSizeInBytes);

      if (!invalidFiles) {
        setSizeError((prev) =>
          prev.map((item, itemIndex: number) =>
            index === itemIndex ? false : item,
          ),
        );
      }

      if (invalidFiles) {
        setSizeError((prev) =>
          prev.map((item, itemIndex: number) =>
            index === itemIndex ? true : item,
          ),
        );
      }
    },
    [setSizeError],
  );

  const validateAccomplishmentItemForm = useCallback(
    (index: number, accomplishment: Accomplishment) => {
      const updatedErrors = ['', '', '', ''];

      if (accomplishment.name.trim() === '') {
        updatedErrors[0] = `${PROVIDE_NAME}`;
      }

      if ((accomplishment.date as string).trim() === '') {
        updatedErrors[1] = `${PROVIDE_DATE}`;
      }

      if (
        accomplishment.date &&
        (accomplishment.date < minDate || accomplishment.date > maxDate)
      ) {
        updatedErrors[1] = `${DATE_OUT_OF_RANGE}`;
      }

      if (accomplishment.description.trim() === '') {
        updatedErrors[2] = `${PROVIDE_DESCRIPTION}`;
      }

      if (accomplishment.links.some((link) => link.trim() === '')) {
        updatedErrors[3] = `${ENSURE_ALL_LINKS_PROVIDED}`;
      }

      if (accomplishment.files.length > 2) {
        return;
      }

      setValidationErrors((prev) =>
        prev.map((errors, i) => (i === index ? updatedErrors : errors)),
      );

      return updatedErrors.every((error) => error === '');
    },
    [setValidationErrors],
  );

  const validateAccomplishmentField = (
    index: number,
    field: string,
    value: string,
  ) => {
    const updatedErrors = [...validationErrors[index]];

    if (field === 'name' && value.trim() === '') {
      updatedErrors[0] = `${PROVIDE_NAME}`;
    } else if (field === 'name') {
      updatedErrors[0] = '';
    }
    if (field === 'date') {
      if (value.trim() === '') {
        updatedErrors[1] = `${PROVIDE_DATE}`;
      } else if (value < minDate || value > maxDate) {
        updatedErrors[1] = `${DATE_OUT_OF_RANGE}`;
      } else {
        updatedErrors[1] = '';
      }
    }

    if (field === 'description' && value.trim() === '') {
      updatedErrors[2] = `${PROVIDE_DESCRIPTION}`;
    } else if (field === 'description') {
      updatedErrors[2] = '';
    }

    if (field.includes('link') && value.trim() === '') {
      updatedErrors[3] = `${PROVIDE_LINK}`;
    } else if (field.includes('link')) {
      updatedErrors[3] = '';
    }

    setValidationErrors((prev) =>
      prev.map((errors, i) => (i === index ? updatedErrors : errors)),
    );
  };

  const handleNextClick = useCallback(() => {
    accomplishments.forEach((accomplishment, index) => {
      validateAccomplishmentItemForm(index, accomplishment);
    });

    const isAnyCertificationInvalid = accomplishments.some(
      (accomplishment, index) =>
        !validateAccomplishmentItemForm(index, accomplishment),
    );

    if (!isAnyCertificationInvalid && sizeError.every((error) => !error)) {
      handleNext();
    }
  }, [accomplishments, validateAccomplishmentItemForm, sizeError, handleNext]);

  return (
    <section>
      <div className="container my-4">
        <h2>Accomplishments</h2>
        {accomplishments.map((accomplishment, index) => (
          <PortfolioItemsWrapper
            addItem={addAccomplishmentItem}
            removeItem={() => removeAccomplishmentItem(index)}
            index={index}
            key={index}
            itemName="Accomplishment"
          >
            <AccomplishmentItem
              accomplishment={accomplishment}
              updateAccomplishment={(updatedAccomplishment) =>
                updateAccomplishmentItem(index, updatedAccomplishment)
              }
              validateAccomplishmentField={validateAccomplishmentField}
              index={index}
              errors={validationErrors[index]}
              validateFilesSize={validateFilesSize}
              sizeError={sizeError}
            />
          </PortfolioItemsWrapper>
        ))}

        <PrevAndNextButtons
          handleNext={handleNextClick}
          handlePrevious={handlePrevious}
        />
      </div>
    </section>
  );
};

export default Accomplishments;
