import React, { Dispatch, SetStateAction, useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';

interface UploadSupportingDocumentProps {
  documentFiles: { [key: number]: File };
  setDocumentFiles: Dispatch<SetStateAction<{ [key: number]: File }>>;
  openPromptIndex: number | null;
  variant?: string;
  link?: string;
  showLink?: boolean;
}

const UploadSupportingDocument: React.FC<UploadSupportingDocumentProps> = ({
  documentFiles,
  setDocumentFiles,
  openPromptIndex,
  variant,
  link,
  showLink,
}) => {
  const documentInputRef = useRef<HTMLInputElement | null>(null);

  const triggerDocumentUpload = () => {
    documentInputRef.current?.click();
  };

  const handleDocumentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file && openPromptIndex !== null) {
      setDocumentFiles((prevFiles) => ({
        ...prevFiles,
        [openPromptIndex]: file,
      }));
    }

    event.target.value = '';
  };

  const handleDeleteDocument = (index: number) => {
    setDocumentFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[index];
      return updatedFiles;
    });
  };

  const Wrapper = useMemo(
    () =>
      variant === 'buttons'
        ? ({ children, ...rest }: any) => (
            <Button className="w-100" variant="success" {...rest}>
              {children}
            </Button>
          )
        : ({ children, ...rest }: any) => (
            <button className="prompt-collapse-btn" {...rest}>
              {children}
            </button>
          ),
    [variant],
  );

  return (
    <>
      <input
        type="file"
        ref={documentInputRef}
        style={{ display: 'none' }}
        onChange={handleDocumentUpload}
        accept=".pdf,.doc,.docx,.txt"
      />
      {showLink && (
        <a
          style={{ color: 'white', textDecoration: 'none' }}
          href={link}
          className={`w-100 ${link ? '' : 'pe-none'}`}
          target="_blank"
          rel="noreferrer"
        >
          <Wrapper disabled={!link}>View Existing Document</Wrapper>
        </a>
      )}
      <Wrapper onClick={triggerDocumentUpload}>
        Upload Supporting Document
      </Wrapper>
      {openPromptIndex !== null && documentFiles[openPromptIndex] && (
        <Wrapper
          onClick={() => handleDeleteDocument(openPromptIndex)}
          className="delete-document-button"
        >
          Delete Supporting Document
        </Wrapper>
      )}
    </>
  );
};

export default UploadSupportingDocument;
