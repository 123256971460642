import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as DownloadIcon } from '../../images/download.svg';

interface FileUploadProps {
  handleFileChange: (file: File) => void;
}

const FileUploadComponent: React.FC<FileUploadProps> = ({
  handleFileChange,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      handleFileChange(file);
    }
  };

  return (
    <>
      <Button
        variant="outline-secondary"
        size="lg"
        style={{
          width: '50px',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '15px',
        }}
        onClick={handleButtonClick}
      >
        <DownloadIcon />
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        className="d-none"
        onChange={handleFileInputChange}
      />
    </>
  );
};

export default FileUploadComponent;
