import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainContainer from './components/Main-Container';
import CompleteProfile from './components/Complete-Profile';
import CompleteProfileAvatar from './components/Complete-Profile-Avatar';
import CreatePrompt from './components/Create-Prompt';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreatePortfolio from './components/Create-Portfolio';
import PageNotFound from './components/PageNotFound';
import UserProfile from './components/UserProfile';
import PrivateRoute from './components/HOCs/PrivateRoute';
import Login from './components/Login';
import Settings from './components/Settings';
import { PublicRoute } from './components/HOCs/PublicRoute';
import PrivateRouteForSettings from './components/HOCs/PrivateRouteForSettings';
import ResetPassword from './components/ResetPassword';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute restricted>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/" element={<MainContainer />}>
          <Route
            path="/complete-profile"
            element={
              <PrivateRoute>
                <CompleteProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-portfolio"
            element={
              <PrivateRoute>
                <CreatePortfolio />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-profile"
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/complete-profile-avatar"
            element={
              <PrivateRoute>
                <CompleteProfileAvatar />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-prompt"
            element={
              <PrivateRoute>
                <CreatePrompt />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
          <Route
            path="/user-settings"
            element={
              <PrivateRouteForSettings>
                <Settings />
              </PrivateRouteForSettings>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
