import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Col,
  Row,
  Button,
  Modal,
  Spinner,
  Card,
  Accordion,
} from 'react-bootstrap';
import { ReactComponent as DescriptionIcon } from '../../images/book.svg';
import { ReactComponent as PlayIcon } from '../../images/eye.svg';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';
import { ReactComponent as EditIcon } from '../../images/edit.svg';
import './UserProfile.css';

import { API_ROUTES, BASE_URL } from '../../config/api-routes';
import PromptDescriptionModal from '../PromptDescriptionModal';
import axiosInstance from '../../api/api';
import UploadSupportingDocument from '../UploadSupportingDocument';
import uploadSupportingDocument from '../UploadSupportingDocument';
import RecordOrUploadModal from '../RecordOrUploadModal';

export interface Prompt {
  id: number;
  created_at: Date;
  updated_at: Date;
  description?: string;
  content: string;
  fileUrl: string;
  supportedDocumentUrl?: string;
}

interface promptsTabProps {
  prompts: Prompt[];
  setPromptsData: Dispatch<SetStateAction<any>>;
}

const PromptsTabContent: React.FC<promptsTabProps> = ({
  prompts,
  setPromptsData,
}) => {
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState<boolean>(false);
  const [currentDescription, setCurrentDescription] = useState('');
  const [recordOrUploadModal, setRecordOrUploadModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [prompt, setPrompt] = useState<Prompt | null>(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showSupportingDocumentModal, setShowSupportingDocumentModal] =
    useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string | null>(null);
  const [videoFiles, setVideoFiles] = useState<{ [key: number]: File }>({});
  const [documentFiles, setDocumentFiles] = useState<{ [key: number]: File }>(
    {},
  );
  const [showSaveModal, setShowSaveModal] = useState(false);

  const openDescriptionModal = (description: string) => {
    setDescriptionModal(true);
    setCurrentDescription(description);
  };
  const onDescriptionModalClose = () => {
    setDescriptionModal(false);
    setPrompt(null);
  };

  const handleRecordOrUploadModalClose = () => {
    setRecordOrUploadModal(false);
    setPrompt(null);
  };

  const onSupportingDocumentModalClose = () => {
    setShowSupportingDocumentModal(false);
    setPrompt(null);
  };

  const viewVideo = (videoFile: string) => {
    setSelectedVideoUrl(videoFile);
    setShowVideoPlayer(true);
  };

  const onVideoModalClose = () => {
    setShowVideoPlayer(false);
    setSelectedVideoUrl(null);
    URL.revokeObjectURL(selectedVideoUrl!); // Cleanup the object URL
  };

  const onDeleteModalClose = () => {
    setShowRemoveModal(false);
    setPrompt(null);
  };

  const handleDeletePrompt = async () => {
    if (prompts.length <= 4) {
      return;
    }

    try {
      const response = await axiosInstance.delete(
        `${BASE_URL}${API_ROUTES.PROMPTS.DELETE_PROMPT}/${prompt?.id}`,
      );

      if (!response) {
        throw new Error(`Failed to delete prompt: ${response}`);
      }

      setPromptsData((prev: any) => {
        const updatedData = [...prev];
        const promptIndex = updatedData.findIndex(
          (item) => item.id === prompt?.id,
        );
        if (promptIndex > -1) {
          updatedData.splice(promptIndex, 1);
        }
        return updatedData;
      });
      onDeleteModalClose();
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  const handleUpdateDescription = async () => {
    try {
      const response = await axiosInstance.put(
        `${BASE_URL}${API_ROUTES.PROMPTS.EDIT_PROMPT}/${prompt?.id}`,
        {
          description: currentDescription,
        },
      );

      if (!response || response.status !== 201) {
        throw new Error(
          `Failed to update prompt description: ${response.statusText}`,
        );
      }

      setPromptsData((prev: any) => {
        const updatedData = [...prev];
        const promptIndex = updatedData.findIndex(
          (item) => item.id === prompt?.id,
        );
        updatedData[promptIndex].description = currentDescription;

        return updatedData;
      });
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  const isDisabledSubmit = !!Object.keys(uploadSupportingDocument).length;

  const handleSupportingDocumentSubmit = async () => {
    if (!prompt) return;

    const formData = new FormData();

    formData.append('supportDocumentFileId', `doc-${prompt.id}`);
    formData.append('fileId', `prompt-${prompt.id}`);
    const fileUrlParts = prompt.fileUrl.split('/');
    formData.append(
      `prompt-${prompt.id}`,
      new File([], fileUrlParts[fileUrlParts.length - 1]),
    );

    Object.keys(documentFiles).forEach((keyString) => {
      const key = parseInt(keyString, 10);
      const file = documentFiles[key];
      if (file) {
        formData.append(`doc-${key}`, file, file.name);
      }
    });

    setLoader(true);

    try {
      const response = await axiosInstance.put(
        `${BASE_URL}${API_ROUTES.PROMPTS.EDIT_PROMPT}/${prompt.id}`,
        formData,
      );

      if (!response) {
        throw new Error(
          `Failed to update prompt uploading document: ${response}`,
        );
      }

      setPromptsData((prev: any) => {
        const updatedData = [...prev];
        const promptIndex = updatedData.findIndex(
          (item) => item.id === prompt?.id,
        );

        updatedData[promptIndex].supportedDocumentUrl =
          response.data.uploadedPrompts[0].supportedDocumentUrl;

        return updatedData;
      });
      setDocumentFiles({});
      setShowSupportingDocumentModal(false);
    } catch (error: any) {
      console.error('Error:', error);
    } finally {
      setLoader(false);
    }
  };

  const handleRecordOrUploadSubmit = async () => {
    if (!prompt) return;

    const formData = new FormData();

    formData.append('fileId', `file-${prompt.id}`);
    formData.append(`prompt-${prompt.id}`, new File([], `prompt-${prompt.id}`));

    Object.keys(videoFiles).forEach((key) => {
      const numericKey = parseInt(key, 10);
      if (videoFiles[numericKey]) {
        const file = videoFiles[numericKey];
        formData.append(`file-${key}`, file, file.name);
      }
    });

    setLoader(true);

    try {
      const response = await axiosInstance.put(
        `${BASE_URL}${API_ROUTES.PROMPTS.EDIT_PROMPT}/${prompt.id}`,
        formData,
      );

      if (!response) {
        throw new Error(
          `Failed to update prompt uploading document: ${response}`,
        );
      }

      setPromptsData((prev: any) => {
        const updatedData = [...prev];
        const promptIndex = updatedData.findIndex(
          (item) => item.id === prompt?.id,
        );

        updatedData[promptIndex].fileUrl = response.data.prompt.fileUrl;

        return updatedData;
      });
      setVideoFiles({});
      setRecordOrUploadModal(false);
      setShowSaveModal(true);
    } catch (error: any) {
      console.error('Error:', error);
    } finally {
      setLoader(false);
    }
  };

  const isDisabledRecordOrUploadSubmit = !Object.keys(videoFiles).length;

  return (
    <>
      <Card className="portfolio-card prompts">
        {prompts.map((prompt) => (
          <Accordion className="prompt-tab-accordion" key={prompt.id}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <p className="prompt-tab-text">{prompt.content}</p>

                {/* Default Buttons (Edit & Delete) */}
                <div className="prompt-tab-default-buttons">
                  <Button
                    className="prompt-tab-button"
                    onClick={() => {
                      setPrompt(prompt);
                      setRecordOrUploadModal(true);
                    }}
                  >
                    <EditIcon style={{ width: '15px', height: '15px' }} />
                  </Button>
                  <Button
                    className="prompt-tab-button delete-btn"
                    variant="danger"
                    onClick={() => {
                      setPrompt(prompt);
                      setShowRemoveModal(true);
                    }}
                  >
                    <DeleteIcon
                      width={22}
                      height={22}
                      className="prompt-delete-button"
                    />
                  </Button>
                </div>
              </Accordion.Header>

              <Accordion.Body>
                <div className="prompt-tab-expanded-section">
                  <div className="divider"></div> {/* Divider */}
                  <div className="prompt-tab-expanded-buttons">
                    <Button
                      className="prompt-tab-button"
                      onClick={() => viewVideo(prompt.fileUrl)}
                    >
                      Watch Prompt
                    </Button>
                    <Button
                      className="prompt-tab-button"
                      onClick={() => {
                        setPrompt(prompt);
                        setShowSupportingDocumentModal(true);
                      }}
                    >
                      View Document
                    </Button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
      </Card>

      <Modal
        show={recordOrUploadModal}
        onHide={handleRecordOrUploadModalClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <RecordOrUploadModal
          videoFiles={videoFiles}
          setVideoFiles={setVideoFiles}
          openPromptIndex={prompt?.id || 0}
          editingIndex={prompt?.id || 0}
          recordOrUploadModal={recordOrUploadModal}
          errors={errors}
          setErrors={setErrors}
          hideSubmit
        />
        <Modal.Footer>
          <div className="d-flex justify-content-end w-100 g-10">
            <Button
              variant="success"
              onClick={handleRecordOrUploadSubmit}
              disabled={isDisabledRecordOrUploadSubmit}
            >
              Save
            </Button>
            <Button variant="success" onClick={handleRecordOrUploadModalClose}>
              Discard
            </Button>
          </div>
        </Modal.Footer>
        {loader && (
          <div className="prompt-spinner">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </Modal>

      <Modal
        show={showRemoveModal}
        onHide={onDeleteModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body
          style={{ overflowY: 'auto' }}
          className="prompt-modal-container p-5"
        >
          <div>
            <Row sm={12}>
              <h5>Are you sure you want to delete the prompt?</h5>

              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <Button
                  variant="secondary"
                  className="modal-sub-title"
                  onClick={onDeleteModalClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  className="modal-sub-title"
                  onClick={handleDeletePrompt}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={descriptionModal}
        onHide={onDescriptionModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <PromptDescriptionModal
          editingIndex={0}
          currentDescription={currentDescription}
          setCurrentDescription={setCurrentDescription}
          onModalClose={onDescriptionModalClose}
          onSubmit={handleUpdateDescription}
        />
      </Modal>

      <Modal
        show={showSupportingDocumentModal}
        onHide={onSupportingDocumentModalClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body
          style={{
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '12px',
          }}
        >
          <UploadSupportingDocument
            documentFiles={documentFiles}
            setDocumentFiles={setDocumentFiles}
            openPromptIndex={prompt?.id || 0}
            variant="buttons"
            link={prompt?.supportedDocumentUrl}
            showLink
          />
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-end w-100 g-10">
            <Button
              variant="success"
              onClick={handleSupportingDocumentSubmit}
              disabled={isDisabledSubmit}
            >
              Save
            </Button>
            <Button variant="success" onClick={onSupportingDocumentModalClose}>
              Close
            </Button>
          </div>
        </Modal.Footer>

        {loader && (
          <div className="prompt-spinner">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </Modal>

      {selectedVideoUrl && (
        <Modal
          show={showVideoPlayer}
          onHide={onVideoModalClose}
          size="lg"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <video controls style={{ width: '100%' }}>
              <source src={selectedVideoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end w-100 g-10">
              <Button variant="success" onClick={onVideoModalClose}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)}>
        <Modal.Header>
          <Modal.Title>Save Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your changes have been saved successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => setShowSaveModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PromptsTabContent;
