import React, { useState, useRef, useEffect } from 'react';
import { Col, Row, Form, Button, Card } from 'react-bootstrap';
import { newReference } from '../Create-Portfolio/initialValues';
import {
  LeftSidePopupsForAdding,
  Reference,
  ReturnedPortfolio,
} from '../Create-Portfolio/types';
import { handleNewItemFields } from '../UserProfile/helpers';
import { API_ROUTES } from '../../config/api-routes';
import axiosInstance from '../../api/api';

interface Props {
  initialReference?: Reference;
  setPopupOpen?: React.Dispatch<React.SetStateAction<LeftSidePopupsForAdding>>;
  setOpenedPopups?: React.Dispatch<
    React.SetStateAction<{ references: boolean[] }>
  >;
  setPortfolioData?: React.Dispatch<React.SetStateAction<ReturnedPortfolio>>;
  setSavePortfolioButton?: React.Dispatch<React.SetStateAction<boolean>>;
  index?: number;
}

const ReferencePopup: React.FC<Props> = ({
  initialReference,
  setPopupOpen,
  setOpenedPopups,
  setPortfolioData,
  setSavePortfolioButton,
  index,
}) => {
  const [reference, setNewReference] = useState<Reference>(
    initialReference || newReference,
  );
  const popupRef = useRef<HTMLDivElement>(null);

  const isEdit = !!initialReference;

  const closePopup = () => {
    setPopupOpen?.((prev) => ({ ...prev, references: false }));
    setOpenedPopups?.((prev) => {
      const newReferencesPopups = [...prev.references];
      newReferencesPopups[index!] = false;
      return {
        ...prev,
        references: newReferencesPopups,
      };
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        closePopup();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, setPopupOpen]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const requestUrl = isEdit
        ? `${API_ROUTES.REFERENCE.UPDATE_REFERENCE}/${initialReference!.id}`
        : API_ROUTES.REFERENCE.CREATE_REFERENCE;
      const method = isEdit ? 'put' : 'post';

      const response = await axiosInstance({
        method,
        url: requestUrl,
        data: { reference },
      });

      if (!response || response.status !== 200) {
        throw new Error('Failed to save reference');
      }

      const savedReference = response.data;

      if (setPortfolioData && setSavePortfolioButton) {
        setPortfolioData((prev) => {
          const updatedReferences = [...(prev.references || [])];
          if (isEdit) {
            updatedReferences[index!] = savedReference;
          } else {
            updatedReferences.push(savedReference);
          }
          return {
            ...prev,
            references: updatedReferences,
          };
        });
        setSavePortfolioButton(true);
        scrollToPortfolioButton();
      }
    } catch (error) {
      console.error('Error:', error);
    }

    closePopup();
  };

  const scrollToPortfolioButton = () => {
    const saveButton = document.getElementById('port-btn');
    if (saveButton) {
      saveButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Card className="user-left-popup p-3" ref={popupRef}>
      <Card.Body className="scrollable-card">
        <Card.Title className="mb-3">
          {isEdit ? 'Edit reference' : 'Add reference'}
        </Card.Title>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3">
            <Col sm={12}>
              <Form.Control
                type="text"
                required
                placeholder="Enter a name"
                onChange={(e) =>
                  handleNewItemFields('name', e.target.value, setNewReference)
                }
                value={reference.name}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={12}>
              <Form.Control
                type="email"
                required
                readOnly={isEdit}
                placeholder="Enter a email"
                onChange={(e) =>
                  handleNewItemFields('email', e.target.value, setNewReference)
                }
                value={reference.email}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={12}>
              <Form.Control
                type="text"
                required
                placeholder="Enter a role"
                onChange={(e) =>
                  handleNewItemFields('role', e.target.value, setNewReference)
                }
                value={reference.role}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={12}>
              <Form.Control
                type="text"
                required
                placeholder="Enter an organization"
                onChange={(e) =>
                  handleNewItemFields(
                    'organization',
                    e.target.value,
                    setNewReference,
                  )
                }
                value={reference.organization}
              />
            </Col>
          </Form.Group>
          <Button variant="success" type="submit">
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ReferencePopup;
