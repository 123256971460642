import React from 'react';
import { Alert, Col, Form, InputGroup, Row } from 'react-bootstrap';
import './References.css';

import { ReactComponent as UserIcon } from '../../images/user.svg';
import { ReactComponent as AtIcon } from '../../images/at.svg';
import { ReactComponent as CaseIcon } from '../../images/case.svg';
import { ReactComponent as BuildingIcon } from '../../images/building.svg';
import { Reference } from '../Create-Portfolio/types';

interface ReferenceItemProps {
  reference: Reference;
  errors: string[];
  updateReference: (updatedReference: Reference) => void;
  validateReferenceField: (index: number, field: string, value: string) => void;
  index: number;
}

const ReferenceItem: React.FC<ReferenceItemProps> = ({
  reference,
  errors,
  updateReference,
  validateReferenceField,
  index,
}) => {
  const handleInputChange = (field: string, value: string) => {
    const updatedReference = { ...reference, [field]: value };
    updateReference(updatedReference);
    validateReferenceField(index, field, value);
  };

  return (
    <Form className="reference-item">
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Full Name
        </Form.Label>

        <Col sm={9}>
          <InputGroup>
            <Form.Control
              aria-label="Full Name"
              type="text"
              className="control-margin-reset"
              value={reference ? reference.name : ''}
              onChange={(e) => handleInputChange('name', e.target.value)}
              isInvalid={!!errors[0]}
            />
            <InputGroup.Text>
              <span className="back-ref-icons">
                <UserIcon style={{ width: '25px', height: '25px' }} />
              </span>
            </InputGroup.Text>
          </InputGroup>

          {errors[0] && (
            <Alert variant="danger" style={{ marginTop: '10px' }}>
              {errors[0]}
            </Alert>
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Email
        </Form.Label>

        <Col sm={9}>
          <InputGroup>
            <Form.Control
              aria-label="Email"
              type="email"
              className="control-margin-reset"
              value={reference ? reference.email : ''}
              onChange={(e) => handleInputChange('email', e.target.value)}
              isInvalid={!!errors[1]}
            />
            <InputGroup.Text>
              <span className="back-ref-icons">
                <AtIcon style={{ width: '25px', height: '25px' }} />
              </span>
            </InputGroup.Text>
          </InputGroup>

          {errors[1] && (
            <Alert variant="danger" style={{ marginTop: '10px' }}>
              {errors[1]}
            </Alert>
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Role
        </Form.Label>

        <Col sm={9}>
          <InputGroup>
            <Form.Control
              aria-label="Role"
              type="text"
              className="control-margin-reset"
              value={reference ? reference.role : ''}
              onChange={(e) => handleInputChange('role', e.target.value)}
              isInvalid={!!errors[2]}
            />
            <InputGroup.Text>
              <span className="back-ref-icons">
                <CaseIcon style={{ width: '25px', height: '25px' }} />
              </span>
            </InputGroup.Text>
          </InputGroup>
          {errors[2] && (
            <Alert variant="danger" style={{ marginTop: '10px' }}>
              {errors[2]}
            </Alert>
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Company / School
        </Form.Label>

        <Col sm={9}>
          <InputGroup>
            <Form.Control
              aria-label="Company/School"
              type="text"
              className="control-margin-reset"
              value={reference ? reference.organization : ''}
              onChange={(e) =>
                handleInputChange('organization', e.target.value)
              }
              isInvalid={!!errors[3]}
            />
            <InputGroup.Text>
              <span className="back-ref-icons">
                <BuildingIcon style={{ width: '25px', height: '25px' }} />
              </span>
            </InputGroup.Text>
          </InputGroup>
          {errors[3] && (
            <Alert variant="danger" style={{ marginTop: '10px' }}>
              {errors[3]}
            </Alert>
          )}
        </Col>
      </Form.Group>
    </Form>
  );
};

export default ReferenceItem;
