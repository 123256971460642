import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
  children: ReactElement;
  restricted?: boolean;
};

export const PublicRoute: React.FC<Props> = ({
  children,
  restricted = false,
}) => {
  const accessToken = localStorage.getItem('accessToken');
  const shouldRedirect = accessToken && restricted;

  return shouldRedirect ? <Navigate to="/user-profile" replace /> : children;
};
