import React, { useEffect } from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { ReactComponent as MinusIcon } from '../../images/minus.svg';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';
import './Accomplishments.css';
import { Accomplishment } from '../Create-Portfolio/types';
import MultipleFilesUploadButton from '../FileUploadButton/MultipleFilesUploadButton';
import { maxDate, minDate } from '../../config/calendarMinMaxDates';
import { validationMessages } from '../../config/validationMessages';
import {
  useAccomplishmentFileChange,
  useAccomplishmentFileDelete,
  useFileAmountValidation,
} from '../Create-Portfolio/customHooks';

interface AccomplishmentItemProps {
  accomplishment: Accomplishment;
  updateAccomplishment: (updatedProject: Accomplishment) => void;
  validateAccomplishmentField: (
    index: number,
    field: string,
    value: string,
  ) => void;
  index: number;
  errors: string[];
  validateFilesSize: (files: File[], index: number) => void;
  sizeError: boolean[];
}

const { FILES_EXCEEDED_LIMIT_10MB } = validationMessages;

const AccomplishmentItem: React.FC<AccomplishmentItemProps> = ({
  accomplishment,
  updateAccomplishment,
  validateAccomplishmentField,
  index,
  errors,
  validateFilesSize,
  sizeError,
}) => {
  const { handleFileChange } = useAccomplishmentFileChange(
    accomplishment,
    updateAccomplishment,
  );
  const { handleDeleteFile } = useAccomplishmentFileDelete(
    accomplishment,
    updateAccomplishment,
  );

  const { fileError } = useFileAmountValidation(accomplishment.files, index);

  useEffect(() => {
    validateFilesSize(accomplishment.files, index);
  }, [accomplishment.files, index, validateFilesSize]);

  const handleInputChange = (field: string, value: string) => {
    const updatedAccomplishment = { ...accomplishment, [field]: value };
    updateAccomplishment(updatedAccomplishment);
    validateAccomplishmentField(index, field, value);
  };

  const handleLinkChange = (linkIndex: number, value: string) => {
    const updatedLinks = [...accomplishment.links];
    updatedLinks[linkIndex] = value;
    const updatedAccomplishment = { ...accomplishment, links: updatedLinks };
    updateAccomplishment(updatedAccomplishment);
    validateAccomplishmentField(index, `link${linkIndex}`, value);
  };

  const addLink = () => {
    const updatedLinks = [...accomplishment.links, ''];
    const updatedAccomplishment = { ...accomplishment, links: updatedLinks };
    updateAccomplishment(updatedAccomplishment);
  };

  const removeLink = (linkIndex: number) => {
    const updatedLinks = accomplishment.links.filter((_, i) => i !== linkIndex);
    const updatedAccomplishment = { ...accomplishment, links: updatedLinks };
    updateAccomplishment(updatedAccomplishment);
  };

  return (
    <Form className="certification-item">
      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Label column sm={3}>
            Title
          </Form.Label>
          <Form.Control
            type="text"
            value={accomplishment.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            isInvalid={!!errors[0]}
          />
          {errors[0] && <Alert variant="danger">{errors[0]}</Alert>}
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Label column sm={3}>
            Date
          </Form.Label>
          <Form.Control
            type="date"
            className="form-control"
            required
            value={accomplishment.date as string}
            onChange={(e) => handleInputChange('date', e.target.value)}
            isInvalid={!!errors[1]}
            min={minDate}
            max={maxDate}
          />
          {errors[1] && <Alert variant="danger">{errors[1]}</Alert>}
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Label column sm={3}>
            Brief description
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            max={50}
            value={accomplishment.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            isInvalid={!!errors[2]}
          />
          {errors[2] && <Alert variant="danger">{errors[2]}</Alert>}
        </Col>
      </Form.Group>

      {accomplishment.links.map((link, linkIndex) => (
        <Form.Group as={Row} className="mb-3" key={linkIndex}>
          <Col>
            {linkIndex === 0 && (
              <Form.Label column sm={3}>
                Relevant links
              </Form.Label>
            )}
            <Form.Control
              type="text"
              value={link}
              onChange={(e) =>
                handleLinkChange(linkIndex, e.target.value.trim())
              }
              isInvalid={!!errors[3]}
            />
            <div
              className={
                linkIndex > 0
                  ? 'links-toggle-button-wrapper'
                  : 'links-add-btn-wrapper'
              }
            >
              {linkIndex > 0 && (
                <button type="button" onClick={() => removeLink(linkIndex)}>
                  <MinusIcon className="icons-size" />
                  <span className="underline-text">Remove link</span>
                </button>
              )}
              <button type="button" onClick={addLink}>
                <PlusIcon className="icons-size" />
                <span className="link-text">Add link</span>
              </button>
            </div>
          </Col>
        </Form.Group>
      ))}
      {errors[3] && (
        <Alert variant="danger" style={{ marginTop: '10px' }}>
          {errors[3]}
        </Alert>
      )}

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={12}>
          Upload Supporting Documents
        </Form.Label>
        <Col
          sm={12}
          style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}
        >
          <div className="select-project-file">
            {accomplishment.files.length < 2 && (
              <span>
                <MultipleFilesUploadButton
                  handleFileChange={handleFileChange}
                />
              </span>
            )}
          </div>
          {accomplishment.files &&
            accomplishment.files.map((file, fileIndex) => (
              <div className="selected-file" key={fileIndex}>
                <span>{file.name.replace(/_/g, ' ')}</span>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteFile(fileIndex)}
                >
                  <DeleteIcon
                    width={20}
                    height={20}
                    className="prompt-delete-button"
                  />
                </Button>
              </div>
            ))}
          {sizeError[index] && (
            <Alert variant="danger">{FILES_EXCEEDED_LIMIT_10MB}</Alert>
          )}
          {fileError && <Alert variant="danger">{fileError}</Alert>}
        </Col>
      </Form.Group>
    </Form>
  );
};

export default AccomplishmentItem;
