import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-bootstrap/Modal';

interface ModalWindowProps {
  children: ReactNode;
  showModal: boolean;
  handleModalClose: () => void;
}

const ModalWindow: React.FC<ModalWindowProps> = ({
  children,
  showModal,
  handleModalClose,
}) => {
  const modalRoot = document.getElementById('modal');

  if (!modalRoot) {
    return null;
  }

  return ReactDOM.createPortal(
    <Modal
      show={showModal}
      onHide={handleModalClose}
      backdrop="static"
      keyboard={false}
    >
      {children}
    </Modal>,
    modalRoot,
  );
};

export default ModalWindow;
