export const newProject = { name: '', description: '', files: [] };

export const newlyAddedProject = {
  name: '',
  description: '',
  filesUrl: [],
  files: [],
};

export const newReference = {
  name: '',
  email: '',
  role: '',
  organization: '',
};

export const newCertification = {
  name: '',
  date: '',
  file: null,
};

export const newlyAddedCertification = {
  name: '',
  date: '',
  filesUrl: [],
};

export const newAccomplishment = {
  name: '',
  date: '',
  description: '',
  links: [''],
  files: [],
};

export const newlyAddedAccomplishment = {
  name: '',
  date: '',
  description: '',
  links: [''],
  filesUrl: [],
};

export const newOrganization = {
  name: '',
  startDate: '',
  endDate: '',
  description: '',
  link: '',
};
