import React, { useState, useRef, useEffect } from 'react';
import { Row, Form, Button, Card, ListGroup, Alert } from 'react-bootstrap';
import {
  LeftSidePopupsForAdding,
  ReturnedPortfolio,
} from '../Create-Portfolio/types';
import {
  workCultureArray,
  workCultureList,
} from '../../config/work-culture-components';
import { validationMessages } from '../../config/validationMessages';
import { useCustomOptionsHandlers } from '../Create-Portfolio/customHooks';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';

interface Props {
  setPopupOpen: React.Dispatch<React.SetStateAction<LeftSidePopupsForAdding>>;
  setPortfolioData?: React.Dispatch<React.SetStateAction<ReturnedPortfolio>>;
  setSavePortfolioButton?: React.Dispatch<React.SetStateAction<boolean>>;
  workCulture: string[];
}

const WorkCulturePopup: React.FC<Props> = ({
  setPopupOpen,
  workCulture,
  setPortfolioData,
  setSavePortfolioButton,
}) => {
  const [updatedWorkCulture, setWorkCulture] = useState<string[]>(
    workCulture.filter(
      (item) =>
        item && workCultureArray.includes(item) && item !== 'Other (Custom)',
    ),
  );
  const [error, setError] = useState<boolean>(false);

  const [customOptions, setCustomOptions] = useState<string[]>(() => {
    const initialOptions = workCulture.filter(
      (item) => !workCultureArray.includes(item) && item !== 'Other (Custom)',
    );
    return initialOptions.length ? initialOptions : [''];
  });

  const popupRef = useRef<HTMLDivElement>(null);

  const { handleAddExtraField, handleRemoveExtraField, handleCustomOption } =
    useCustomOptionsHandlers(customOptions, setCustomOptions);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setPopupOpen((prev) => ({ ...prev, workCulture: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, setPopupOpen]);

  const handleWorkCulture = (option: string, checked: boolean) => {
    setWorkCulture((prevWorkCulture) =>
      checked
        ? [...prevWorkCulture, option]
        : prevWorkCulture.filter((item) => item !== option),
    );
    setError(false);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = event.target.value;
    const checked = event.target.checked;

    handleWorkCulture(option, checked);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (updatedWorkCulture.length === 0 && !customOptions) {
      setError(true);
      return;
    }

    if (setPortfolioData && setSavePortfolioButton) {
      setPortfolioData((prev) => ({
        ...prev,
        workCulture: [
          ...updatedWorkCulture.filter((item) =>
            workCultureArray.includes(item),
          ),
          ...customOptions,
        ].filter(Boolean),
      }));
      setSavePortfolioButton(true);
      scrollToPortfolioButton();
    }
    setPopupOpen((prev) => ({
      ...prev,
      workCulture: !prev['workCulture'],
    }));
  };

  const scrollToPortfolioButton = () => {
    const saveButton = document.getElementById('port-btn');
    if (saveButton) {
      saveButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const isAddExtraButtonDisabled =
    updatedWorkCulture.length + customOptions.length >= 3;

  return (
    <Card className="user-left-popup p-3" ref={popupRef}>
      <Card.Body className="scrollable-card">
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Card.Title className="mb-3">Add work culture options</Card.Title>
          <ListGroup>
            {workCultureList &&
              workCultureList
                .slice(0, workCultureList.length - 1)
                .map(({ name, description }) => (
                  <div className="mb-2" key={name}>
                    <ListGroup.Item style={{ border: 'none' }}>
                      <Row sm={12}>
                        <Form.Check
                          disabled={
                            !updatedWorkCulture.includes(name) &&
                            updatedWorkCulture.length +
                              customOptions.filter(Boolean).length >=
                              3
                          }
                          type="switch"
                          id={name}
                          label={name}
                          onChange={handleSwitchChange}
                          value={name}
                          checked={updatedWorkCulture.includes(name)}
                        />
                      </Row>
                      <Row>
                        {description && (
                          <p className="description-work-culture">
                            {description}
                          </p>
                        )}
                      </Row>
                    </ListGroup.Item>
                  </div>
                ))}
            {customOptions.map((customOption, index) => (
              <ListGroup.Item key={index} style={{ border: 'none' }}>
                {index === 0 && (
                  <button
                    style={{
                      top: 12,
                      left: -20,
                    }}
                    type="button"
                    className={`user-info-edit-button me-1 position-absolute ${isAddExtraButtonDisabled ? 'opacity-25' : ''}`}
                    onClick={handleAddExtraField}
                    disabled={isAddExtraButtonDisabled}
                  >
                    <PlusIcon style={{ width: '25px', height: '25px' }} />
                  </button>
                )}
                <Form.Control
                  disabled={updatedWorkCulture.length >= 3}
                  as="textarea"
                  rows={7}
                  placeholder="Other option (optional)"
                  value={customOption}
                  onChange={(e) => handleCustomOption(e.target.value, index)}
                />
                {customOptions.length >= 2 && (
                  <button
                    style={{
                      top: 16,
                      right: -10,
                    }}
                    type="button"
                    onClick={() => handleRemoveExtraField(index)}
                    className="user-info-edit-button position-absolute"
                  >
                    <DeleteIcon width={15} height={15} />
                  </button>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
          {error && (
            <Alert variant="danger">
              {validationMessages.CHOOSE_MIN_ONE_OPTION}
            </Alert>
          )}
          <Button variant="success" type="submit" className="mt-3">
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default WorkCulturePopup;
