import React, { useState, useEffect } from 'react';
import { Portfolio } from '../Create-Portfolio/types';
import PrevAndNextButtons from '../Prev-Next-Buttons';

import { Col, Tab, Tabs, Row, Alert, Spinner, Button } from 'react-bootstrap';
import './Preview.css';
import UserInformationList from './UserInformationList';
import PortfolioTabContent from './PortfolioTabContent';
import PortfolioCards from './PortfolioCards';
import { fetchUserData } from './api';
import UserPanel from './UserPanel';
import { validationMessages } from '../../config/validationMessages';
import { fetchPromptsData } from '../UserProfile/api';

import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface PreviewProps {
  handleNext: () => void;
  handlePrevious: () => void;
  portfolio: Portfolio;
  lastButton: boolean;
  setPortfolio: React.Dispatch<React.SetStateAction<Portfolio>>;
  loader: boolean;
  error: boolean;
}

const Preview: React.FC<PreviewProps> = ({
  handleNext,
  handlePrevious,
  portfolio,
  setPortfolio,
  lastButton,
  loader,
  error,
}) => {
  const [key, setKey] = useState<string>('portfolio');
  const [userData, setUserData] = useState<any>(null);
  const [getError, setGetError] = useState<boolean>(false);
  const [jobTitle, setJobTitle] = useState<string>('');

  useEffect(() => {
    const getUserData = async () => {
      try {
        const data = await fetchUserData();
        const promptsData = await fetchPromptsData();
        if (promptsData) {
          setJobTitle(promptsData[0]?.jobTitle);
        }
        setUserData(data);
        setGetError(false);
      } catch (error) {
        console.error('Error:', error);
        setGetError(true);
      }
    };

    getUserData();
  }, []);

  useEffect(() => {
    toast.info(
      'You are now on the Preview page. If you want to make changes, click Previous. If everything is correct, click Save.',
      {
        className: 'toast-message',
        position: 'top-center',
        autoClose: 8500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Zoom,
      },
    );
  }, []);

  const scrollToSaveButton = () => {
    const saveButton = document.getElementById('save-portfolio-btn');
    if (saveButton) {
      saveButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        //padding: '50px 100px',
      }}
      className="container"
    >
      <ToastContainer
        style={{
          width: '500px',
        }}
        className="container"
      />
      {loader ? (
        <div className="prompt-spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Row>
          <Col className="mb-5">
            <Row>
              <Col md={4} sm={12}>
                <UserPanel userData={userData} jobTitle={jobTitle} />
                <UserInformationList userData={userData} />
                <PortfolioCards portfolio={portfolio} />
              </Col>
              <Col md={8} sm={12} className="preview-cards-margin-bottom">
                <Tabs
                  id="controlled-tab"
                  activeKey={key}
                  onSelect={(k) => setKey(k as string)}
                  className="mb-3"
                >
                  <Tab eventKey="prompts" title="Screening prompts" disabled>
                    Tab content for Screening prompts
                  </Tab>
                  <Tab eventKey="portfolio" title="Portfolio">
                    <PortfolioTabContent portfolio={portfolio} />
                  </Tab>
                </Tabs>
                <Button
                  className="responsive-btn"
                  onClick={scrollToSaveButton}
                  variant="success"
                >
                  Save Portfolio
                </Button>
              </Col>
            </Row>
          </Col>
          <Row>
            {(error || getError) && (
              <Alert variant="danger" style={{ marginBottom: '50px' }}>
                {validationMessages.DATA_FETCH_FAILED}
              </Alert>
            )}
            <PrevAndNextButtons
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              lastButton={lastButton}
            />
          </Row>
        </Row>
      )}
    </div>
  );
};

export default Preview;
