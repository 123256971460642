import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useReactMediaRecorder } from 'react-media-recorder';
import { ReactComponent as UploadIcon } from '../../images/box-arrow-in-up.svg';
import { ReactComponent as PlayIcon } from '../../images/play-fill.svg';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';
import { validationMessages } from '../../config/validationMessages';

interface PromptSelectionModalProps {
  editingIndex: number | null;
  recordOrUploadModal: boolean;
  onModalClose?: () => void;
  onSubmit?: () => void;
  errors: any;
  setErrors: Dispatch<SetStateAction<any>>;
  setVideoFiles: Dispatch<SetStateAction<any>>;
  setEditingIndex?: Dispatch<SetStateAction<number | null>>;
  videoFiles: any;
  openPromptIndex: number | null;
  hideSubmit?: boolean;
}

const RecordOrUploadModal: React.FC<PromptSelectionModalProps> = ({
  editingIndex,
  recordOrUploadModal,
  onModalClose,
  onSubmit,
  errors,
  setErrors,
  setVideoFiles,
  setEditingIndex,
  videoFiles,
  openPromptIndex,
  hideSubmit,
}) => {
  const [recordModalStep, setRecordModalStep] = useState<number>(1);
  const [temporaryVideoFile, setTemporaryVideoFile] = useState<File | null>(
    null,
  );
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showTips, setShowTips] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    clearBlobUrl,
    previewStream,
  } = useReactMediaRecorder({
    video: true,
    audio: true,
  });

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleStartRecording = () => {
    clearBlobUrl();
    setErrors((prevErrors: any) => ({ ...prevErrors, videoFile: '' }));
    setShowTips(true);
    startRecording();
  };

  const handleStartRecordingInitial = () => {
    clearBlobUrl();
    setErrors((prevErrors: any) => ({ ...prevErrors, videoFile: '' }));
    setShowTips(true);
  };

  const handleStopRecording = () => {
    stopRecording();
  };

  const validateVideoDuration = (
    file: File,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => {
    const video = document.createElement('video');
    console.log('video:', file.type);
    video.preload = 'metadata';

    let retryCount = 0;
    const maxRetries = 4;

    const handleMetadataLoad = () => {
      if (isNaN(video.duration) || video.duration === Infinity) {
        if (retryCount < maxRetries) {
          retryCount++;
          setTimeout(() => {
            video.src = '';
            video.src = URL.createObjectURL(file);
          }, 500);
        } else {
          window.URL.revokeObjectURL(video.src);
          onError('Unable to load the video. Please try a different file.');
        }
        return;
      }

      window.URL.revokeObjectURL(video.src);

      if (video.duration <= 120) {
        onSuccess();
      } else {
        onError('Video is longer than 2 minutes');
      }
    };

    video.onloadedmetadata = handleMetadataLoad;

    video.onerror = () => {
      window.URL.revokeObjectURL(video.src);
      console.log(video.error);
      onError('Error loading video file');
    };

    video.src = URL.createObjectURL(file);
  };

  const uploadVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          videoFile: 'File size exceeds 20MB',
        }));
        return;
      }

      if (
        file.type !== 'video/webm' &&
        file.type !== 'video/mp4' &&
        file.type !== 'video/quicktime'
      ) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          videoFile: 'File type must be webm, mp4, or MOV',
        }));
        return;
      }

      validateVideoDuration(
        file,
        () => {
          const newFile = new File([file], `prompt-${editingIndex}.webm`, {
            type: 'video/webm',
          });
          setTemporaryVideoFile(newFile);
          setErrors((prevErrors: any) => ({
            ...prevErrors,
            videoFile: '',
          }));
        },
        (error: string) => {
          setErrors((prevErrors: any) => ({
            ...prevErrors,
            videoFile: error,
          }));
        },
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (status === 'recording') {
        // Ensure recording is still ongoing
        handleStopRecording();
      }
    }, 120 * 1000);
  }, [status]);

  useEffect(() => {
    if (status === 'media_aborted') {
      alert(`${validationMessages.RECORDING_ABORTED}`);
    } else if (status === 'permission_denied') {
      alert(`${validationMessages.PERMISSION_DENIED}`);
    } else if (status === 'no_specified_media_found') {
      alert(`${validationMessages.NO_MEDIA_FOUND}`);
    } else if (status === 'media_in_use') {
      alert(`${validationMessages.MEDIA_IN_USE}`);
    } else if (status === 'invalid_media_constraints') {
      alert(`${validationMessages.INVALID_MEDIA_CONSTRAINS}`);
    }
  }, [status]);

  useEffect(() => {
    if (mediaBlobUrl && editingIndex !== null) {
      console.log('Blob URL:', mediaBlobUrl);
      fetch(mediaBlobUrl)
        .then((res) => res.blob())
        .then((blob) => {
          console.log('Blob MIME type for playback:', blob.type);
          const newFile = new File([blob], `prompt-${editingIndex}.webm`, {
            type: 'video/webm',
          });
          setTemporaryVideoFile(newFile);
        })
        .catch((err) => console.error('Error converting blob to file:', err));
    }
  }, [mediaBlobUrl, editingIndex]);

  useEffect(() => {
    const videoElement = document.querySelector('video');
    if (videoElement && mediaBlobUrl) {
      videoElement.src = mediaBlobUrl; // Set Blob URL
      videoElement.load(); // Force video reload to ensure it's loaded
      videoElement.onloadedmetadata = () => {
        console.log('Metadata loaded, video should be ready to play');
      };
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (hideSubmit && temporaryVideoFile && fileInputRef.current?.value) {
      handleSubmit();
    }
  }, [hideSubmit, temporaryVideoFile]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined; // Explicitly type timer

    if (countdown !== null && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000); // Decrease countdown every second
    } else if (countdown === 0) {
      startRecording(); // Start recording after countdown finishes
      setCountdown(null); // Reset the countdown
    }

    return () => clearTimeout(timer); // Clear timer on component unmount or countdown change
  }, [countdown]);

  const handleCloseTips = () => {
    setShowTips(false); // Hide tips
    setCountdown(5); // Start countdown from 5 seconds
  };

  const handleSubmit = () => {
    if (editingIndex !== null && temporaryVideoFile) {
      setVideoFiles((prevFiles: any) => ({
        ...prevFiles,
        [editingIndex]: temporaryVideoFile,
      }));
      setTemporaryVideoFile(null);
      setEditingIndex?.(null);
      clearBlobUrl();
      if (previewStream) {
        const videoElement = document.querySelector('video');
        if (videoElement) {
          videoElement.srcObject = null;
        }
      }
      // Move to success step
      setRecordModalStep(3);
      onModalClose?.();
      onSubmit?.();
    }
  };

  useEffect(() => {
    if (!recordOrUploadModal) {
      setEditingIndex?.(null);
      setRecordModalStep(1);
      clearBlobUrl();
      setTemporaryVideoFile(null);
      setErrors((prevErrors: any) => ({ ...prevErrors, videoFile: '' }));
      if (previewStream) {
        const videoElement = document.querySelector('video');
        if (videoElement) {
          videoElement.srcObject = null;
        }
      }
    }
  }, [recordOrUploadModal]);

  const handleDeleteVideo = (index: number) => {
    setVideoFiles((prevFiles: any) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[index];
      return updatedFiles;
    });
    onModalClose?.();
  };

  return (
    <>
      {showTips && (
        <div className="tips-container">
          <h3>Recording Tips</h3>
          <ul>
            <li>Make sure you're in a quiet environment.</li>
            <li>Check your lighting for a clear picture.</li>
            <li>Position the camera at eye level.</li>
            <li>Speak clearly and confidently.</li>
          </ul>
          <Button variant="success" onClick={handleCloseTips}>
            Close Tips
          </Button>
        </div>
      )}

      {/* Countdown Screen */}
      {!showTips && countdown !== null && countdown > 0 && (
        <div className="countdown-container">
          <h3 className="countdown-text">
            Recording will start in {countdown} seconds...
          </h3>
        </div>
      )}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={uploadVideo}
        accept="video/*"
      />
      {recordModalStep === 1 && (
        <>
          <Modal.Body
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: 'fit-content',
            }}
          >
            <div className="prompt-video-modal-container">
              {openPromptIndex !== null && videoFiles[openPromptIndex] ? (
                hideSubmit ? (
                  <div className="d-flex align-items-center justify-content-around">
                    <span className="pe-2">
                      {videoFiles[openPromptIndex]?.name}
                    </span>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteVideo(openPromptIndex)}
                    >
                      Delete
                    </Button>
                  </div>
                ) : (
                  <>
                    <p>You already have a video response for this prompt.</p>
                    <div
                      className="d-flex justify-content-around"
                      style={{ width: 'inherit' }}
                    >
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteVideo(openPromptIndex)}
                      >
                        Delete
                      </Button>
                      <Button variant="secondary" onClick={onModalClose}>
                        Cancel
                      </Button>
                    </div>
                  </>
                )
              ) : (
                <>
                  <div className="prompt-video-button-container modern">
                    <Button
                      onClick={() => {
                        if (fileInputRef.current) {
                          fileInputRef.current.value = '';
                        }
                        setRecordModalStep(2);
                        setErrors((prevErrors: any) => ({
                          ...prevErrors,
                          videoFile: '',
                        }));
                      }}
                      className="prompt-video-button modern-button"
                    >
                      <PlayIcon width={24} height={24} color="white" />
                    </Button>
                    <p className="modern-text">Record Response</p>
                  </div>

                  <div className="prompt-video-button-container modern">
                    <Button
                      className="prompt-video-button-upload modern-button"
                      onClick={handleUploadClick}
                    >
                      <UploadIcon width={24} height={24} color="white" />
                    </Button>
                    <p className="modern-text">Upload Video</p>
                  </div>

                  <ul className="video-requirements modern-list">
                    <li>Maximum file size: 20MB</li>
                    <li>Allowed video formats: WEBM, MP4, or MOV</li>
                    <li>Maximum video duration: 2 minutes</li>
                  </ul>

                  {temporaryVideoFile && (
                    <div className="d-flex align-items-center justify-content-between modern-file-info">
                      <span>{temporaryVideoFile.name}</span>
                      <Button
                        size="sm"
                        variant="outline-danger"
                        className="modern-delete-button"
                        onClick={() => setTemporaryVideoFile(null)}
                      >
                        <DeleteIcon width={16} height={16} />
                      </Button>
                    </div>
                  )}

                  {errors.videoFile && (
                    <Alert variant="danger" className="modern-alert">
                      {errors.videoFile}
                    </Alert>
                  )}

                  {!hideSubmit && (
                    <Button
                      variant="success"
                      className="modern-submit-button"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
        </>
      )}
      {recordModalStep === 2 && (
        <>
          <Modal.Body
            style={{
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px',
              backgroundColor:
                showTips || (countdown !== null && countdown)
                  ? '#fff'
                  : '#f5f5f5',
              borderRadius: '10px',
            }}
          >
            {!showTips && countdown === null && (
              <div
                className="prompt-modal-container-video"
                style={{ width: '100%', maxWidth: '1000px' }}
              >
                {mediaBlobUrl && (
                  <video
                    src={mediaBlobUrl ? mediaBlobUrl : '/video.mp4'} // Fallback to static video if no Blob URL
                    controls
                    ref={(video) => {
                      if (video) {
                        video.currentTime = 0; // Reset the video playback to start from the beginning
                        video.load(); // Force the video to reload
                      }
                    }}
                    onLoadedMetadata={(e) => {
                      const videoElement = e.target as HTMLVideoElement;
                      videoElement.currentTime = 0; // Set the current time to 0 once metadata is loaded
                    }}
                    style={{
                      width: '100%',
                      height: '60vh',
                      borderRadius: '10px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                    onPlay={() => {
                      if (videoRef.current) {
                        videoRef.current.currentTime = 0; // Ensures video starts from the beginning when played
                      }
                    }}
                    onError={(e) => console.error('Video playback error:', e)}
                  />
                )}
                {previewStream && !mediaBlobUrl && (
                  <video
                    autoPlay
                    muted
                    ref={(video) => {
                      if (video && previewStream) {
                        video.srcObject = previewStream;
                      } else if (video) {
                        video.srcObject = null;
                      }
                    }}
                    style={{
                      width: '100%',
                      height: '60vh',
                      borderRadius: '10px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                )}
                <div
                  className="btns-wrap-rec"
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  {!showTips && !mediaBlobUrl && (
                    <Button
                      variant="danger"
                      onClick={handleStartRecordingInitial}
                      disabled={status === 'recording'}
                      className="modern-button-rec"
                    >
                      Start Recording
                    </Button>
                  )}
                  <Button
                    variant="danger"
                    onClick={stopRecording}
                    disabled={status !== 'recording'}
                    className="modern-button-rec"
                  >
                    Stop Recording
                  </Button>
                  {mediaBlobUrl && (
                    <>
                      <Button
                        variant="secondary"
                        onClick={clearBlobUrl} // Clear video if user wants to record again
                        className="modern-button-rec"
                      >
                        Clear Video
                      </Button>
                      <Button
                        variant="success"
                        onClick={() => {
                          setRecordModalStep(1);
                          if (hideSubmit) {
                            handleSubmit();
                          }
                        }}
                        className="modern-button-rec"
                      >
                        Submit Video
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )}
          </Modal.Body>
        </>
      )}

      {recordModalStep === 3 && (
        <Modal.Body
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: 'fit-content',
          }}
        ></Modal.Body>
      )}
    </>
  );
};

export default RecordOrUploadModal;
