import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Modal,
  Alert,
  Spinner,
  FormControlProps,
} from 'react-bootstrap';
import { API_ROUTES } from '../../config/api-routes';
import { validationMessages } from '../../config/validationMessages';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../images/arrow-back.svg';
import './Complete-Profile-Avatar.css';
import SkillsInput from '../Skills-Input';
import axiosInstance from '../../api/api';
import InputMask from 'react-input-mask';

interface ProfileFormState {
  profileImg: File | null;
  willingToRelocate: string;
  workArrangements: string;
  salary: string;
  flexibleType: string;
  timeToStart: number;
  skills: string[];
}

const { FILE_EXCEEDED_LIMIT_4MB, UPLOAD_IMAGE, SALARY_ERROR, TIME_ERROR } =
  validationMessages;

type FormControlElement =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

const CompleteProfileForm: React.FC = () => {
  const [profileFormData, setProfileFormData] = useState<ProfileFormState>({
    profileImg: null,
    willingToRelocate: '',
    workArrangements: '',
    salary: '',
    flexibleType: '',
    timeToStart: 1,
    skills: [],
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [fetchError, setFetchingError] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [step, setStep] = useState<number>(1);
  const [salaryInput, setSalaryInput] = useState<boolean>(false);
  const [salaryKeeper, setSalaryKeeper] = useState<string>('');
  const [salaryError, setSalaryError] = useState<boolean>(false);
  const [noAvatarError, setNoAvatarError] = useState<string>('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('mode') === 'edit';

  const getUserInfo = async () => {
    try {
      const response = await axiosInstance.get('user/current-user');

      if (!response || response.status !== 200) {
        setLoader(false);
        return;
      }

      const data = response.data;

      if (data?.user?.profileImg && !isEdit) {
        navigate('/user-profile');
      }
    } catch (error: any) {
      console.error('Error fetching user info:', error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (profileFormData.profileImg) {
      setNoAvatarError('');
    }
  }, [profileFormData.profileImg]);

  useEffect(() => {
    if (Number(salaryKeeper) < 0) {
      setSalaryError(true);
    }
    setSalaryError(false);
  }, [salaryKeeper]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFetchingError(false);
    if (event.target.files && event.target.files[0]) {
      const maxSizeInBytes = 4 * 1024 * 1024;

      if (
        event.target.files[0] &&
        event.target.files[0].size > maxSizeInBytes
      ) {
        setNoAvatarError(`${FILE_EXCEEDED_LIMIT_4MB}`);
        event.target.value = '';
        return;
      }

      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!validImageTypes.includes(event.target.files[0].type)) {
        setNoAvatarError('Please select a valid image file (JPEG, PNG, GIF)');
        event.target.value = '';
        return;
      }

      setProfileFormData({
        ...profileFormData,
        profileImg: event.target.files[0],
      });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<FormControlElement>) => {
    const { name, value } = event.target;

    if (name === 'timeToStart') {
      setError('');
    }

    setSalaryInput(value === 'Choose compensation');

    setProfileFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNext = () => {
    if (step === 1 && !profileFormData.profileImg) {
      setNoAvatarError(`${UPLOAD_IMAGE}`);
      return;
    }
    if (step === 2 && !profileFormData.willingToRelocate) {
      return;
    }
    if (step === 3 && !profileFormData.workArrangements) {
      return;
    }
    if (
      step === 4 &&
      (!profileFormData.salary ||
        (profileFormData.salary === 'Choose compensation' &&
          salaryKeeper === '') ||
        (profileFormData.salary === 'Choose compensation' &&
          Number(salaryKeeper) <= 0))
    ) {
      if (Number(salaryKeeper) <= 0) {
        setSalaryError(true);
      }
      return;
    }

    if (step === 4 && salaryInput && salaryKeeper !== '') {
      if (!salaryInput || salaryKeeper.startsWith('0')) {
        setSalaryError(true);
        return;
      }
      setProfileFormData((prevState) => ({
        ...prevState,
        salary: salaryKeeper.toString(),
      }));
    }

    if (step === 5 && !profileFormData.flexibleType) {
      return;
    }

    if (step === 6) {
      const timeToStart = profileFormData.timeToStart;
      if (!timeToStart) {
        return;
      }
      if (timeToStart < 1 || timeToStart.toString().startsWith('0')) {
        setError(`${TIME_ERROR}`);
        setProfileFormData({
          ...profileFormData,
          timeToStart: 1,
        });
        return;
      }
    }

    if (step === 7 && profileFormData.skills.length === 0) {
      return;
    }

    setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const onModalClose = () => {
    setProfileFormData({
      ...profileFormData,
      willingToRelocate: '',
      workArrangements: '',
      salary: '',
      flexibleType: '',
      timeToStart: 0,
      skills: [],
    });
    setStep(1);
    setShowModal(false);
  };

  const handleSubmitAvatar = async (event: any) => {
    if (!profileFormData.profileImg) {
      event.preventDefault();
      setNoAvatarError(`${UPLOAD_IMAGE}`);
      return;
    }
    event.preventDefault();

    if (isEdit) {
      try {
        const formData = new FormData();
        formData.append('profileImg', profileFormData.profileImg);
        setLoader(true);
        const response = await axiosInstance.put(
          API_ROUTES.USER.UPDATE_USER,
          formData,
        );

        if (response && response.status === 200) {
          setShowModal(false);
          navigate('/user-profile', { replace: true });
        } else {
          setFetchingError(true);
        }
      } catch (error: any) {
        console.error('Error updating user avatar:', error.message);
        setLoader(false);
        setFetchingError(true);
      }
    } else {
      setStep(step + 1);
      setShowModal(true);
      setLoader(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (profileFormData.skills.length === 0) {
      return;
    }

    if (profileFormData.profileImg) {
      try {
        const formData = new FormData();
        formData.append('profileImg', profileFormData.profileImg);
        formData.append('willingToRelocate', profileFormData.willingToRelocate);
        formData.append('workArrangements', profileFormData.workArrangements);
        formData.append('salary', profileFormData.salary);
        formData.append('flexibleType', profileFormData.flexibleType);
        formData.append('timeToStart', profileFormData.timeToStart.toString());
        formData.append('skills', JSON.stringify(profileFormData.skills));
        formData.append('step', '2');

        const response = await axiosInstance.post(
          API_ROUTES.AUTH.COMPLETE_PROFILE,
          formData,
        );

        if (response && response.status === 200) {
          setShowModal(false);
          navigate('/create-prompt', { replace: true });
        } else {
          setFetchingError(true);
        }
      } catch (error: any) {
        console.error('Error completing profile:', error.message);
        setFetchingError(true);
      } finally {
      }
    }

    setProfileFormData({
      profileImg: null,
      willingToRelocate: '',
      workArrangements: '',
      salary: '',
      flexibleType: '',
      timeToStart: 0,
      skills: [],
    });
    setStep(1);
    setShowModal(false);
  };

  const handleUpdateSkills = (updatedSkills: string[]) => {
    setProfileFormData((prevState) => ({
      ...prevState,
      skills: updatedSkills,
    }));
  };

  return (
    <>
      {loader ? (
        <div className="prompt-spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="container-fluid h-100">
          <div className="row h-100 align-items-center justify-content-center">
            <div className="container">
              {isEdit && (
                <button
                  onClick={() => navigate('/user-profile')}
                  className="go-back-btn"
                  style={{ marginLeft: '15px' }}
                >
                  <BackIcon width={50} height={50} />
                </button>
              )}
              <h2
                className="title-dash flex2"
                style={{ marginLeft: '30px', padding: '10px 0 20px 0' }}
              >
                Upload Your Photo
              </h2>
              <Form onSubmit={handleSubmitAvatar} className="file-upload-form">
                <Form.Label htmlFor="fileUpload" className="file-upload">
                  <div className="upload-area text-center">
                    <Form.Control
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="fileUpload"
                    />
                    {profileFormData.profileImg ? (
                      <div className="position-relative">
                        <img
                          src={URL.createObjectURL(profileFormData.profileImg)}
                          alt="Preview"
                          className="uploaded-image"
                        />
                        <div className="change-image-indicator">
                          <p className="text-muted">Click to change image</p>
                        </div>
                      </div>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          className="bi bi-cloud-upload icon-upload"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 0a5.53 5.53 0 0 0-5.5 5.5c0 1.36.68 2.605 1.84 3.351A2.75 2.75 0 0 0 5 15h6a2.75 2.75 0 0 0 1.66-5.149 3.502 3.502 0 0 0 1.34-2.851A5.53 5.53 0 0 0 8 0zM6 8.5v3h1v-3h1.5v3H10v-3h1.5v3h.5a1.25 1.25 0 0 0 .774-2.287 1.25 1.25 0 0 0-.612-.252l-.028.003A4.505 4.505 0 0 0 8 5 4.505 4.505 0 0 0 6 8.5z" />
                        </svg>
                        <p>Select a file</p>
                      </>
                    )}
                  </div>
                </Form.Label>
                <p
                  className="text-center text-muted"
                  style={{ fontSize: '0.875rem' }}
                >
                  Max file size: 4MB
                </p>
                {noAvatarError && (
                  <Alert variant="danger">{noAvatarError}</Alert>
                )}
                {fetchError && (
                  <Alert variant="danger" style={{ marginBottom: '50px' }}>
                    {validationMessages.DATA_FETCH_FAILED}
                  </Alert>
                )}
                <Button
                  variant="success"
                  type="submit"
                  className="btn-complete"
                >
                  Submit
                </Button>
              </Form>
            </div>

            <Modal show={showModal} onHide={onModalClose} centered>
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '20px' }}>
                  Please fill out the following screening questions
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {step === 2 && (
                  <div>
                    <p className="modal-sub-title">
                      Are you willing to relocate?
                    </p>
                    <Form.Select
                      aria-label="Select relocation preference"
                      name="willingToRelocate"
                      onChange={handleInputChange}
                      value={profileFormData.willingToRelocate}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                  </div>
                )}
                {step === 3 && (
                  <div>
                    <p className="modal-sub-title">
                      What are your preferred work arrangements?
                    </p>
                    <Form.Select
                      aria-label="Select work arrangement"
                      name="workArrangements"
                      onChange={handleInputChange}
                      value={profileFormData.workArrangements}
                    >
                      <option value="">Select</option>
                      <option value="Hybrid">Hybrid</option>
                      <option value="On-site">On-site</option>
                      <option value="Remote">Remote</option>
                    </Form.Select>
                  </div>
                )}
                {step === 4 && (
                  <div>
                    <p className="modal-sub-title">
                      What is the minimum annual base salary you are looking
                      for?
                    </p>
                    <Form.Select
                      aria-label="Select salary"
                      name="salary"
                      onChange={handleInputChange}
                      value={profileFormData.salary}
                      className="mb-3"
                    >
                      <option value="">Select</option>
                      <option value="Not indicated">
                        I don’t want this added
                      </option>
                      <option value="Can negotiate">
                        I prefer to negotiate during the interview
                      </option>
                      <option value="Choose compensation">
                        Choose compensation
                      </option>
                    </Form.Select>
                    {salaryInput && (
                      <div style={{ position: 'relative' }}>
                        <span className="doll-sign">$</span>
                        <InputMask
                          mask="999,999,999,999"
                          value={salaryKeeper}
                          onChange={(e) => {
                            setSalaryKeeper(e.target.value.replace(/,/g, '')); // Remove commas for storage
                          }}
                        >
                          {/*
        TypeScript Workaround: 
        If this still causes issues, you can use @ts-ignore to bypass TypeScript's type checking.
      */}
                          {/* @ts-ignore */}
                          {(inputProps: any) => (
                            <Form.Control
                              {...inputProps}
                              style={{ paddingLeft: '30px' }}
                              type="text"
                              name="salary"
                              placeholder="Enter your desired compensation"
                              min={1}
                            />
                          )}
                        </InputMask>
                        {salaryError && (
                          <Alert variant="danger">{`${SALARY_ERROR}`}</Alert>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {step === 5 && (
                  <div>
                    <p className="modal-sub-title">
                      Are your responses flexible?
                    </p>
                    <Form.Select
                      aria-label="Select flexibility"
                      name="flexibleType"
                      onChange={handleInputChange}
                      value={profileFormData.flexibleType}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                  </div>
                )}
                {step === 6 && (
                  <div>
                    <p className="modal-sub-title">
                      On average, how many days do I need before starting a new
                      role?
                    </p>
                    <Form.Control
                      type="number"
                      name="timeToStart"
                      min={1}
                      onChange={(e) => handleInputChange(e as any)}
                      value={profileFormData.timeToStart}
                    />
                    {error && <Alert variant="danger">{error}</Alert>}
                  </div>
                )}
                {step === 7 && (
                  <div>
                    <p className="modal-sub-title">Add relevant skills</p>
                    <SkillsInput
                      skills={profileFormData.skills}
                      onUpdateSkills={handleUpdateSkills}
                    />
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                {step > 2 && (
                  <Button
                    variant="secondary"
                    onClick={handleBack}
                    style={{
                      fontWeight: '700',
                      fontSize: '16px',
                      borderRadius: '4px',
                    }}
                  >
                    Back
                  </Button>
                )}
                {step < 7 && (
                  <Button
                    variant="primary"
                    onClick={handleNext}
                    style={{
                      fontWeight: '700',
                      fontSize: '16px',
                      borderRadius: '4px',
                      backgroundColor: '#14a077',
                    }}
                  >
                    Next
                  </Button>
                )}
                {step === 7 && (
                  <Button
                    variant="success"
                    onClick={handleSubmit}
                    style={{
                      fontWeight: '700',
                      fontSize: '16px',
                      borderRadius: '4px',
                      backgroundColor: '#14a077',
                    }}
                  >
                    Finish
                  </Button>
                )}
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default CompleteProfileForm;
