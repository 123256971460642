import React from 'react';
import { Button } from 'react-bootstrap';
import './PrevAndNextButtons.css';

interface PrevAndNextButtonTypes {
  handleNext: () => void;
  handlePrevious: () => void;
  handleSubmit?: (data: any) => void;
  data?: any;
  lastButton?: boolean;
}

const PrevAndNextButtons: React.FC<PrevAndNextButtonTypes> = ({
  handleNext,
  handlePrevious,
  handleSubmit,
  data,
  lastButton,
}) => {
  const handleNextClick = () => {
    handleNext();
    if (handleSubmit) {
      handleSubmit(data);
    }
  };

  return (
    <div className="buttons-layout">
      <Button
        variant="success"
        size="lg"
        className="responsive-btn"
        onClick={handlePrevious}
      >
        <span className="full-text">Previous</span>
        <span className="small-text">&lt;</span> {/* This is the '<' symbol */}
      </Button>
      {lastButton ? (
        <Button
          variant="success"
          size="lg"
          onClick={handleNextClick}
          id="save-portfolio-btn"
        >
          Save
        </Button>
      ) : (
        <Button
          variant="success"
          size="lg"
          className="responsive-btn"
          onClick={handleNext}
        >
          <span className="full-text">Next</span>
          <span className="small-text">&gt;</span>{' '}
          {/* This is the '>' symbol */}
        </Button>
      )}
    </div>
  );
};

export default PrevAndNextButtons;
