import React, { useState, useCallback } from 'react';
import {
  CURRENT_PLACEHOLDER_DATE,
  maxDate,
  minDate,
} from '../../config/calendarMinMaxDates';
import { validationMessages } from '../../config/validationMessages';
import { newOrganization } from '../Create-Portfolio/initialValues';
import { Organization } from '../Create-Portfolio/types';
import PortfolioItemsWrapper from '../Portfolio-Items-Wrapper';
import PrevAndNextButtons from '../Prev-Next-Buttons';
import OrganizationItem from './OrganizationItem';

interface OrganizationProps {
  handleNext: () => void;
  handlePrevious: () => void;
  organizations: Organization[];
  setOrganizations: React.Dispatch<React.SetStateAction<Organization[]>>;
  showEndDate: boolean[];
  setShowEndDate: React.Dispatch<React.SetStateAction<boolean[]>>;
}

const {
  PROVIDE_NAME,
  PROVIDE_DESCRIPTION,
  PROVIDE_LINK,
  PROVIDE_START_DATE,
  PROVIDE_END_DATE,
  DATE_OUT_OF_RANGE,
  END_DATE_BEFORE_START_DATE,
} = validationMessages;

const ProfessionalOrganizations: React.FC<OrganizationProps> = ({
  handleNext,
  handlePrevious,
  organizations,
  setOrganizations,
  showEndDate,
  setShowEndDate,
}) => {
  const [validationErrors, setValidationErrors] = useState<string[][]>(
    organizations.map(() => ['', '', '', '', '']),
  );

  const addOrganizationItem = () => {
    setOrganizations((prevPortfolio) => [...prevPortfolio, newOrganization]);

    setValidationErrors((prev) => [...prev, ['', '', '', '', '']]);
    setShowEndDate((prev) => [...prev, true]);
  };

  const removeOrganizationItem = (index: number) => {
    const updatedOrganizations = organizations.filter((_, i) => i !== index);

    setOrganizations(updatedOrganizations);

    setValidationErrors((prev) => prev.filter((_, i) => i !== index));
    setShowEndDate((prev) => prev.filter((_, i) => i !== index));
  };

  const updateOrganizationItem = useCallback(
    (index: number, updatedOrganization: Organization) => {
      const updatedOrganizations = organizations.map((organization, i) =>
        i === index ? updatedOrganization : organization,
      );
      setOrganizations(updatedOrganizations);
    },
    [organizations, setOrganizations],
  );

  const validateOrganizationItemForm = useCallback(
    (index: number, organization: Organization) => {
      const updatedErrors = ['', '', '', '', ''];

      if (organization.name.trim() === '') {
        updatedErrors[0] = `${PROVIDE_NAME}`;
      }

      if ((organization.startDate as string).trim() === '') {
        updatedErrors[1] = `${PROVIDE_START_DATE}`;
      }

      if (
        showEndDate[index] === true &&
        (organization.endDate as string).trim() === '' &&
        organization.endDate !== CURRENT_PLACEHOLDER_DATE
      ) {
        updatedErrors[2] = `${PROVIDE_END_DATE}`;
      }

      if (
        organization.startDate &&
        (organization.startDate < minDate || organization.startDate > maxDate)
      ) {
        updatedErrors[1] = `${DATE_OUT_OF_RANGE}`;
      }

      if (
        organization.endDate &&
        organization.endDate !== CURRENT_PLACEHOLDER_DATE &&
        (organization.endDate < minDate || organization.endDate > maxDate)
      ) {
        updatedErrors[2] = `${DATE_OUT_OF_RANGE}`;
      }

      if (
        organization.endDate &&
        organization.endDate !== CURRENT_PLACEHOLDER_DATE &&
        organization.endDate < organization.startDate
      ) {
        updatedErrors[2] = `${END_DATE_BEFORE_START_DATE}`;
      }

      if (organization.description.trim() === '') {
        updatedErrors[3] = `${PROVIDE_DESCRIPTION}`;
      }

      if (organization.link.trim() === '') {
        updatedErrors[4] = `${PROVIDE_LINK}`;
      }

      setValidationErrors((prev) =>
        prev.map((errors, i) => (i === index ? updatedErrors : errors)),
      );

      return updatedErrors.every((error) => error === '');
    },
    [setValidationErrors, showEndDate],
  );

  const validateOrganizationField = (
    index: number,
    field: string,
    value: string,
  ) => {
    const updatedErrors = [...validationErrors[index]];

    if (field === 'name' && value.trim() === '') {
      updatedErrors[0] = `${PROVIDE_NAME}`;
    } else if (field === 'name') {
      updatedErrors[0] = '';
    }

    if (field === 'startDate') {
      if (value.trim() === '') {
        updatedErrors[1] = `${PROVIDE_START_DATE}`;
      } else if (value < minDate || value > maxDate) {
        updatedErrors[1] = `${DATE_OUT_OF_RANGE}`;
      } else {
        updatedErrors[1] = '';
      }
    }

    if (field === 'endDate') {
      if (value.trim() === '') {
        updatedErrors[2] = `${PROVIDE_END_DATE}`;
      } else if (
        value !== CURRENT_PLACEHOLDER_DATE &&
        (value < minDate || value > maxDate)
      ) {
        updatedErrors[2] = `${DATE_OUT_OF_RANGE}`;
      } else if (
        organizations[index].startDate &&
        value !== CURRENT_PLACEHOLDER_DATE &&
        value < organizations[index].startDate
      ) {
        updatedErrors[2] = `${END_DATE_BEFORE_START_DATE}`;
      } else {
        updatedErrors[2] = '';
      }
    }

    if (field === 'description' && value.trim() === '') {
      updatedErrors[3] = `${PROVIDE_DESCRIPTION}`;
    } else if (field === 'description') {
      updatedErrors[3] = '';
    }

    if (field === 'link' && value.trim() === '') {
      updatedErrors[4] = `${PROVIDE_LINK}`;
    } else if (field === 'link') {
      updatedErrors[4] = '';
    }

    setValidationErrors((prev) =>
      prev.map((errors, i) => (i === index ? updatedErrors : errors)),
    );
  };

  const handleNextClick = useCallback(() => {
    organizations.forEach((organization, index) => {
      validateOrganizationItemForm(index, organization);
    });

    const isAnyCertificationInvalid = organizations.some(
      (organization, index) =>
        !validateOrganizationItemForm(index, organization),
    );

    if (!isAnyCertificationInvalid) {
      handleNext();
    }
  }, [organizations, validateOrganizationItemForm, handleNext]);

  return (
    <section>
      <div className="container my-4">
        <h2>Professional Organizations</h2>
        {organizations.map((organization, index) => (
          <PortfolioItemsWrapper
            addItem={addOrganizationItem}
            removeItem={removeOrganizationItem}
            index={index}
            key={index}
            itemName="Organization"
          >
            <OrganizationItem
              organization={organization}
              errors={validationErrors[index]}
              updateOrganization={(updatedOrganization) =>
                updateOrganizationItem(index, updatedOrganization)
              }
              validateOrganizationField={validateOrganizationField}
              index={index}
              showEndDate={showEndDate}
              setShowEndDate={setShowEndDate}
            />
          </PortfolioItemsWrapper>
        ))}

        <PrevAndNextButtons
          handleNext={handleNextClick}
          handlePrevious={handlePrevious}
        />
      </div>
    </section>
  );
};

export default ProfessionalOrganizations;
