export const generalPrompts = [
  'Can you briefly describe your professional background and experience relevant to your role in your field?',
  'Can you tell me a little about yourself?',
  'What type of roles interest you and why?',
  'What are your key strengths that make you a good fit for the positions you are seeking?',
  'Can you share an example of a challenging work situation you faced and how you handled it?',
  'How do you prioritize tasks and manage your time effectively?',
  'Describe a situation where you had to work as part of a team to achieve a common goal.',
  'How do you handle pressure and tight deadlines?',
  'Can you provide an example of a time when you had to resolve a conflict with a coworker or customer?',
  'How do you stay updated with industry trends and developments?',
  'What are your long-term career goals and how does your current & prospective roles align with them?',
  'Can you discuss your experience in working with diverse teams or customers?',
  'How do you approach problem-solving and decision-making?',
  'Can you provide an example of a time when you had to adapt to change in the workplace?',
  'What steps do you take to ensure accuracy and attention to detail in your work?',
  'Describe a situation where you had to handle a difficult customer or client.',
  'How do you effectively communicate complex information to different stakeholders?',
  'Can you discuss your experience in using technology or specific software relevant to this position?',
  'How do you ensure confidentiality and handle sensitive information?',
  'Describe a time when you took initiative or demonstrated leadership in a work setting.',
  'How do you handle feedback or constructive criticism?',
  'Can you provide an example of a successful project or achievement you are proud of?',
  'Describe a time when you had to meet a challenging goal or target.',
  'How do you stay motivated and maintain a positive attitude at work?',
  'Can you discuss your experience in managing budgets, resources, or expenses?',
  'Describe your approach to customer service and building positive relationships with clients.',
  'How do you handle multiple competing priorities or simultaneous projects?',
  'What are skills you have gained in from being in your current role?',
  'Can you provide an example of a time when you had to negotiate or influence others to reach an agreement?',
  'How do you ensure effective communication and collaboration within a team?',
  'Describe a time when you had to provide feedback or coach a colleague or team member.',
  'How do you approach professional development and continuous learning?',
  'What are some personal hobbies of yours that you like to do outside work?',
  'Can you provide an example of a time when you had to think creatively to solve a problem?',
  'How do you handle ambiguity or situations with limited information?',
  'Can you discuss your experience in conducting research or gathering data for decision-making purposes?',
  'Where do you see yourself in 5 years?',
  'Describe a time when you had to work with limited resources or tight budgets.',
  'Why do you want to leave your current role?',
  'How do you ensure quality control in your work processes?',
  'What has been your greatest professional achievement?',
  'What are your weaknesses?',
  'What motivates you in your current role?',
  'What motivates you in life?',
  'What do you expect from your employer in terms of support and development?',
  'What professional values are most important to you?',
  'Describe a situation where you had to think strategically to achieve a long-term goal.',
  'Can you discuss your experience in managing or overseeing projects from start to finish?',
  'How do you approach building and maintaining professional networks or relationships?',
  'Can you tell me about a personal accomplishment or experience that you feel has positively influenced your professional growth?',
  'How do you maintain a work-life balance and manage personal commitments alongside professional responsibilities?',
  'Can you describe a time when you had to handle a personal challenge or adversity while meeting work obligations? How did you manage the situation?',
  'How do you approach self-care and maintaining your overall well-being in a demanding work environment?',
  'Can you discuss any hobbies or activities outside of work that contribute to your personal and professional development?',
  'How do you incorporate personal goal-setting and self-reflection into your professional development plans?',
  'Can you discuss any volunteer work or community involvement that has influenced your personal and professional values?',
  'How do you assess the success of your projects?',
  'Can you describe a project or situation where you took the initiative?',
  'How do you stay organized and manage your time effectively?',
  'Can you describe a time when you went above and beyond your job duties?',
  'What learning and development opportunities are you looking for in your next job?',
  'How do you define good leadership?',
  'What strategies do you use to motivate your team?',
  'How do you handle underperforming team members?',
  'Can you give an example of how you have developed leadership skills in others?',
  'Can you discuss a leadership decision you made that was not popular and how you handled the feedback?',
];

export const primaryPrompts = [
  'Please walk me through your resume and elaborate on how your role has evolved each year?',
  'What are your day to day tasks in your current role?',
];
