import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Logo from '../../images/SKIP.svg';
import axiosInstance from '../../api/api';
import { API_ROUTES } from '../../config/api-routes';
import './resetPass.css';
import { Button, Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { validationMessages } from '../../config/validationMessages';
import { passwordRegex } from '../../config/regex';

const {
  PASSWORDS_DO_NOT_MATCH,
  ERROR_DURING_PASSWORD_RESET,
  PASSWORD_MUST_CONTAIN_8_CHARACTERS,
  PASSWORD_RESET_FAILED,
} = validationMessages;

const ResetPassword = () => {
  const { token } = useParams<{ token: string }>();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState<boolean>(false);

  const handleResetPassword = async (event: any) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setMessage(`${PASSWORDS_DO_NOT_MATCH}`);
      return;
    }

    if (!passwordRegex.test(password)) {
      setMessage(`${PASSWORD_MUST_CONTAIN_8_CHARACTERS}`);
      return;
    }

    try {
      const response = await axiosInstance.post(
        `${API_ROUTES.AUTH.RESET_PASSWORD}/${token}`,
        { password },
      );
      const data = response.data;
      data.success
        ? setSuccessMessage(true)
        : setMessage(`${PASSWORD_RESET_FAILED} ${data.message}`);
    } catch (error) {
      setMessage(`${ERROR_DURING_PASSWORD_RESET}`);
    }
  };

  return (
    <>
      <div className="reset-main-wrapper">
        <Col sm={12} className="justify-content-center">
          <img
            className="mb-3"
            alt="Logo"
            src={Logo}
            width={150}
            style={{ display: 'block', margin: 'auto' }}
          />
          {successMessage ? (
            <>
              <Card className="p-5 success-wrapper">
                <h4 className="card-title text-center mb-4">Reset Password</h4>
                <Card.Text
                  style={{
                    color: 'primary',
                    textAlign: 'center',
                  }}
                >
                  Your password has been successfully reset. Please{' '}
                  <Link
                    to="/"
                    style={{
                      textDecoration: 'none',
                      color: '#14a077',
                    }}
                  >
                    log in{' '}
                  </Link>{' '}
                  with your new password.{' '}
                </Card.Text>
              </Card>
            </>
          ) : (
            <>
              <Card className="justify-content-center p-5 reset-form">
                <h4 className="card-title text-center mb-4">Reset Password</h4>
                <div className="form-group">
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="newpassword">Confirm Password:</label>
                  <input
                    type="password"
                    id="newpassword"
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <Button
                  variant="success"
                  className="submit-btn-reset"
                  onClick={handleResetPassword}
                >
                  Reset Password
                </Button>
                {message && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {message}
                  </div>
                )}
              </Card>
            </>
          )}
        </Col>
      </div>
    </>
  );
};

export default ResetPassword;
