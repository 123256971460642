import React, { Dispatch, SetStateAction, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';

interface PromptDescriptionModalProps {
  onModalClose?: () => void;
  setDescriptions?: Dispatch<SetStateAction<{ [key: number]: string }>>;
  currentDescription: string;
  setCurrentDescription: Dispatch<SetStateAction<string>>;
  editingIndex?: number | null;
  hideFooter?: boolean;
  onSubmit?: () => void;
}

const PromptDescriptionModal: React.FC<PromptDescriptionModalProps> = ({
  onModalClose,
  setDescriptions,
  currentDescription,
  setCurrentDescription,
  editingIndex,
  hideFooter,
  onSubmit,
}) => {
  const [descriptionValidationError, setDescriptionValidationError] =
    useState<string>('');

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = event.target.value;
    if (value.length > 200) {
      setDescriptionValidationError('Maximum 200 characters allowed');
    } else {
      setDescriptionValidationError('');
      setCurrentDescription(value);
    }
  };

  const handleSaveDescription = () => {
    if (editingIndex != null) {
      setDescriptions?.((prev) => ({
        ...prev,
        [editingIndex]: currentDescription,
      }));
    }
    onSubmit?.();
    onModalClose?.();
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Enter/View Description</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={currentDescription}
            onChange={handleDescriptionChange}
            isInvalid={!!descriptionValidationError}
          />
          {descriptionValidationError && (
            <Alert variant="danger" className="mt-2">
              {descriptionValidationError}
            </Alert>
          )}
        </Form.Group>
      </Modal.Body>
      {!hideFooter && (
        <Modal.Footer>
          <Button variant="secondary" onClick={onModalClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSaveDescription}>
            Submit
          </Button>
        </Modal.Footer>
      )}
    </>
  );
};

export default PromptDescriptionModal;
