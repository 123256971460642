import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './PortfolioControl.css';

import { Form, Button, Spinner } from 'react-bootstrap';
import {
  portfolioSwitchesMapping,
  sectionList,
  sectionsOrder,
} from '../../config/portfolio-section-list';
import { Portfolio } from '../Create-Portfolio/types';
import {
  newAccomplishment,
  newCertification,
  newOrganization,
  newProject,
  newReference,
} from '../Create-Portfolio/initialValues';
import { API_ROUTES } from '../../config/api-routes';
import axiosInstance from '../../api/api';
import { ReactComponent as BackIcon } from '../../images/arrow-back.svg';

interface PortfolioControlProps {
  portfolioId?: number;
  handleNext: () => void;
  setSwitches: React.Dispatch<React.SetStateAction<string[]>>;
  checkedSections: string[];
  sections: string[];
  setCheckedSections: React.Dispatch<React.SetStateAction<string[]>>;
  setPortfolio: React.Dispatch<React.SetStateAction<Portfolio>>;
}

const PortfolioControl: React.FC<PortfolioControlProps> = ({
  portfolioId,
  handleNext,
  setSwitches,
  checkedSections,
  setCheckedSections,
  setPortfolio,
  sections,
}) => {
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isEdit = searchParams.get('mode') === 'edit';
  const [isAllSelected, setIsAllSelected] = useState(false);

  const clearSectionState = (section: string) => {
    setPortfolio((prevPortfolio) => {
      switch (section) {
        case 'Overview':
          return { ...prevPortfolio, overview: '' };
        case 'Projects':
          return { ...prevPortfolio, projects: [newProject] };
        case 'References':
          return { ...prevPortfolio, references: [newReference] };
        case 'Languages':
          return { ...prevPortfolio, languages: [] };
        case 'Certifications':
          return { ...prevPortfolio, certifications: [newCertification] };
        case 'Accomplishments':
          return { ...prevPortfolio, accomplishments: [newAccomplishment] };
        case 'Professional Organizations':
          return { ...prevPortfolio, organizations: [newOrganization] };
        case 'Ideal Work Culture':
          return { ...prevPortfolio, workCulture: [] };
        default:
          return prevPortfolio;
      }
    });
  };

  const handleCheckboxChange = (section: string, checked: boolean) => {
    setCheckedSections((prev) =>
      checked ? [...prev, section] : prev.filter((item) => item !== section),
    );

    setPortfolio((prev) => {
      const [activeSwitchKey] = Object.entries<string>(
        portfolioSwitchesMapping,
      ).find(([_, value]) => value === section)!;
      return {
        ...prev,
        [activeSwitchKey]: checked,
      };
    });

    if (!checked) {
      clearSectionState(section);
    }
  };

  const handleStepAmount = (section: string) => {
    setSwitches((prev) => {
      if (prev.includes(section)) {
        return prev.filter((item) => item !== section);
      } else {
        const sectionIndex = sectionsOrder.indexOf(section);

        const newSwitches = prev.filter(
          (item) => item !== 'PortfolioControl' && item !== 'Preview',
        );

        newSwitches.splice(sectionIndex - 1, 0, section);

        return ['PortfolioControl', ...newSwitches, 'Preview'];
      }
    });
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const section = event.target.value;
    const checked = event.target.checked;
    handleCheckboxChange(section, checked);
    handleStepAmount(section);

    // Update "Select All" state based on current selections
    const allNonMandatorySelected = sectionList
      .filter(({ mandatory }) => !mandatory)
      .every(
        ({ name }) =>
          checkedSections.includes(name) || (name === section && checked),
      );
    setIsAllSelected(allNonMandatorySelected);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setLoader(true);

    try {
      const portfolio: Record<string, boolean> = {};
      Object.entries(portfolioSwitchesMapping).forEach(([key, value]) => {
        portfolio[key] = checkedSections.includes(value);
      });

      const response = await axiosInstance.post(
        `${API_ROUTES.PORTFOLIO.EDIT_PORTFOLIO}/${portfolioId}`,
        { portfolio },
      );

      if (!response || response.status !== 200) {
        throw new Error(`Failed to update profile: ${response.statusText}`);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleSelectAllToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectAll = e.target.checked;
    setIsAllSelected(selectAll);

    if (selectAll) {
      // Select all non-mandatory sections
      const allSectionNames = sectionList
        .filter(({ mandatory }) => !mandatory)
        .map(({ name }) => name);

      // Update checkedSections state and trigger handleCheckboxChange
      allSectionNames.forEach((section) => {
        handleCheckboxChange(section, true); // Ensure each checkbox is selected
        handleStepAmount(section); // Ensure step state is updated as well
      });
    } else {
      // Deselect all non-mandatory sections
      const clearedSections: string[] = [];

      setCheckedSections(clearedSections);

      sectionList
        .filter(({ mandatory }) => !mandatory)
        .forEach(({ name }) => {
          handleCheckboxChange(name, false); // Ensure each checkbox is deselected
          handleStepAmount(name); // Update step state accordingly
        });
    }
  };

  const handleGoBack = () => navigate(-1);

  return (
    <section>
      <div className="container my-4">
        <div>
          <button onClick={handleGoBack} className="go-back-btn">
            <BackIcon width={50} height={50} />
          </button>
          <div className="container">
            <div className="row">
              <div className="col">
                <h1
                  className="d-inline-block ms-3"
                  style={{ position: 'relative' }}
                >
                  <span
                    className="d-inline-block"
                    style={{
                      width: '4px',
                      height: '100%',
                      backgroundColor: '#28a745',
                      position: 'absolute',
                      left: '-20px',
                      top: '0',
                    }}
                  ></span>
                  {isEdit ? 'Edit Portfolio' : 'Create Portfolio'}
                </h1>
              </div>
            </div>
          </div>
          <div className="portfolio-control-content">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <p className="mb-2">
                Please tick the sections you would like to add or remove from
                your resume.
              </p>
              <Form.Check
                type="switch"
                id="select-all"
                label="Select All"
                onChange={handleSelectAllToggle}
                checked={isAllSelected}
                className="select-all-toggle"
              />
            </div>
            <Form className="switch-form row g-3">
              {sectionList.map(({ name, mandatory }) => {
                if (!mandatory) {
                  return (
                    <Form.Check
                      className="col-12 col-md-6"
                      type="switch"
                      id={name}
                      key={name}
                      label={name}
                      onChange={handleSwitch}
                      value={name}
                      checked={checkedSections.includes(name)}
                    />
                  );
                }
                return (
                  <Form.Check
                    className="col-12 col-md-6"
                    type="switch"
                    id={name}
                    key={name}
                    label={name}
                    checked={true}
                    disabled={true}
                  />
                );
              })}
            </Form>
            <div className="control-btn-wrapper">
              <Button
                disabled={loader}
                variant="success"
                size="sm"
                className="portfolio-control-button"
                onClick={isEdit ? handleSubmit : handleNext}
              >
                {isEdit ? 'SAVE' : 'NEXT'}
              </Button>
            </div>
          </div>
        </div>

        {loader && (
          <div className="prompt-spinner">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
    </section>
  );
};

export default PortfolioControl;
