export const formatDate = (date: string) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const day = `0${d.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};

export const areFilesUnchanged = (newArray: any[], index: number): boolean => {
  const arrayToCheck = newArray[index];

  if (arrayToCheck.filesUrl.length === 0) {
    return false;
  }

  const containsFile = arrayToCheck.filesUrl.some(
    (item: any) => item instanceof File,
  );

  if (containsFile) {
    return false;
  }

  const allStrings = arrayToCheck.filesUrl.every(
    (item: any) => typeof item === 'string',
  );

  return allStrings;
};

export const areLinksUnchanged = (
  newArray: any,
  arrayToCompare: any,
  index: number,
) => {
  const currentLinks = newArray[index].links;
  const originalLinks = arrayToCompare[index].links;

  if (currentLinks.length !== originalLinks.length) {
    return false;
  }

  for (let i = 0; i < currentLinks.length; i++) {
    if (currentLinks[i] !== originalLinks[i]) {
      return false;
    }
  }

  return true;
};

export const handleFileChange = (
  index: number,
  files: File[] | File,
  array: any,
  setItems: React.Dispatch<React.SetStateAction<any>>,
) => {
  let adjustedFiles;
  if (files instanceof File) {
    adjustedFiles = files;
  } else {
    adjustedFiles = [...files];
  }
  const updatedFiles = [...array[index].filesUrl, adjustedFiles];

  const updatedItem = {
    ...array[index],
    filesUrl: updatedFiles,
  };

  const updatedItems = [...array];
  updatedItems[index] = updatedItem;

  setItems(updatedItems);
};

export const handleNewItemFileChange = (
  files: File[],
  object: any,
  setItems: React.Dispatch<React.SetStateAction<any>>,
) => {
  const updatedFiles = [...object.filesUrl, ...files];

  const updatedItem = {
    ...object,
    filesUrl: updatedFiles,
  };

  setItems(updatedItem);
};

export const handleDeleteFile = (
  projectIndex: number,
  fileIndex: number,
  array: any[],
  setItems: React.Dispatch<React.SetStateAction<any[]>>,
) => {
  const updatedFiles = array[projectIndex].filesUrl.filter(
    (_: any, index: number) => index !== fileIndex,
  );

  const updatedItem = {
    ...array[projectIndex],
    filesUrl: updatedFiles,
  };

  const updatedItems = [...array];
  updatedItems[projectIndex] = updatedItem;

  setItems(updatedItems);
};

export const handleNewItemFields = (
  field: string,
  value: string,
  setItem: React.Dispatch<React.SetStateAction<any>>,
) => {
  setItem((prev: any) => ({ ...prev, [field]: value }));
};

export const handleDeleteNewItemFile = (
  fileIndex: number,
  object: any,
  setItems: React.Dispatch<React.SetStateAction<any>>,
) => {
  const updatedArray = object.filesUrl.filter(
    (_: any, index: number) => index !== fileIndex,
  );
  setItems((prev: any) => ({ ...prev, filesUrl: updatedArray }));
};

export const handleInputChange = (
  index: number,
  field: string,
  value: string,
  array: any,
  setItems: React.Dispatch<React.SetStateAction<any>>,
) => {
  const itemIndex = array.findIndex(
    (_: any, prIndex: number) => prIndex === index,
  );

  if (itemIndex !== -1) {
    const updatedItems = [...array];
    updatedItems[itemIndex] = {
      ...updatedItems[itemIndex],
      [field]: value,
    };
    setItems(updatedItems);
  }
};

export const areObjectsEqual = (obj1: any, obj2: any) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const getFileName = (file: File | null): string => {
  if (!file) return '';
  if (file instanceof File) {
    return file.name.replace(/_/g, ' ');
  }

  return '';
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
