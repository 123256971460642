import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { ReactComponent as MinusIcon } from '../../images/minus.svg';
import './PortfolioItemsWrapper.css';

type ItemTypes = {
  addItem: () => void;
  removeItem: (index: number) => void;
  index: number;
  children: React.ReactNode;
  itemName: string;
};

const PortfolioItemsWrapper: React.FC<ItemTypes> = ({
  addItem,
  removeItem,
  index,
  children,
  itemName,
}) => {
  const handleRemoveProject = () => {
    removeItem(index);
  };
  const handleAddItem = () => {
    addItem();
    window.scrollBy({
      top: 500, // Scroll down 500 pixels
      behavior: 'smooth', // Smooth scrolling
    });
  };

  return (
    <>
      {children}
      <div className={index > 0 ? 'toggle-project-div' : 'add-project-div'}>
        {index > 0 && (
          <button type="button" onClick={handleRemoveProject}>
            <MinusIcon className="icons-size" />
            <span className="underline-text">Remove {itemName}</span>
          </button>
        )}
        <button type="button" onClick={handleAddItem}>
          <PlusIcon className="icons-size" />
          <span className="underline-text">Add {itemName}</span>
        </button>
      </div>
    </>
  );
};

export default PortfolioItemsWrapper;
