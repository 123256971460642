import React, { useState, useRef, useEffect } from 'react';
import { Row, Form, Button, Card, Col, Alert } from 'react-bootstrap';
import {
  PopupsForAddingState,
  PopupsState,
  ReturnedPortfolio,
} from '../Create-Portfolio/types';
import '../Preview/Preview.css';
import { maxDate, minDate } from '../../config/calendarMinMaxDates';
import FileUploadComponent from '../FileUploadButton/SingleFileUploadButton';
import { formatDate, handleNewItemFields } from '../UserProfile/helpers';
import { validationMessages } from '../../config/validationMessages';
import { newlyAddedCertification } from '../Create-Portfolio/initialValues';
import { ReactComponent as DeleteIcon } from '../../images/trash3-fill.svg';
import { v4 as uuidv4 } from 'uuid';
import { API_ROUTES } from '../../config/api-routes';
import axiosInstance from '../../api/api';

interface Props {
  index: number;
  portfolio: ReturnedPortfolio;
  setOpenedPopups: React.Dispatch<React.SetStateAction<PopupsState>>;
  setPopupsToAdd: React.Dispatch<React.SetStateAction<PopupsForAddingState>>;
  setPortfolioData: React.Dispatch<React.SetStateAction<ReturnedPortfolio>>;
  setSavePortfolioButton: React.Dispatch<React.SetStateAction<boolean>>;
  savePortfolioButton: boolean;
  popupVariant: string;
  getData: () => void;
}

const CertificationsPopup: React.FC<Props> = ({
  index,
  portfolio,
  setOpenedPopups,
  setPopupsToAdd,
  setPortfolioData,
  setSavePortfolioButton,
  savePortfolioButton,
  getData,
  popupVariant,
}) => {
  const [certifications, setCertifications] = useState(
    portfolio.certifications,
  );
  const [certification, setNewCertification] = useState<any>(
    newlyAddedCertification,
  );
  const [disabled, setDisabled] = useState<boolean>(true);
  const [success, setAddingFileSuccess] = useState<boolean>(false);

  const popupRef = useRef<HTMLDivElement>(null);

  const [sizeError, setSizeError] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setPopupsToAdd((prev: PopupsForAddingState) => ({
          ...prev,
          certifications: false,
        }));

        setOpenedPopups((prev) => {
          const newCertificationsPopups = [...prev.certifications];
          newCertificationsPopups[index] = false;
          return {
            ...prev,
            certifications: newCertificationsPopups,
          };
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, setOpenedPopups, setPopupsToAdd, index]);

  const handleCertificationSubmit = (index: number) => {
    if (certifications?.length === undefined) {
      return;
    }

    if (certifications?.length === 0) {
      setFileError(true);
      return;
    }

    setSavePortfolioButton(true);
    scrollToPortfolioButton();

    setPortfolioData((prev) => ({ ...prev, certifications: certifications }));

    setOpenedPopups((prev) => {
      const newCertificationsPopups = [...prev.certifications];
      newCertificationsPopups[index] = false;
      return {
        ...prev,
        certifications: newCertificationsPopups,
      };
    });
  };

  const handleSubmitOfNewCertification = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    setPortfolioData((prev) => ({
      ...prev,
      certifications: [...certifications, certification],
    }));
    setSavePortfolioButton(true);
    scrollToPortfolioButton();

    setPopupsToAdd((prev: PopupsForAddingState) => ({
      ...prev,
      certifications: false,
    }));
  };

  const scrollToPortfolioButton = () => {
    const saveButton = document.getElementById('port-btn');
    if (saveButton) {
      saveButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleDeleteFile = (index: number) => {
    const newCertifications = [...certifications];
    newCertifications[index] = {
      ...newCertifications[index],
      filesUrl: [],
    };
    setCertifications(newCertifications);
    setDisabled(false);
    setSavePortfolioButton(true);
  };

  const handleInputChange = (
    index: number,
    field: string,
    value: string,
    array: any,
    setItems: React.Dispatch<React.SetStateAction<any>>,
  ) => {
    const itemIndex = array.findIndex(
      (_: any, prIndex: number) => prIndex === index,
    );

    if (itemIndex !== -1) {
      const updatedItems = [...array];
      updatedItems[itemIndex] = {
        ...updatedItems[itemIndex],
        [field]: value,
      };
      setItems(updatedItems);
      setDisabled(false);
      setSavePortfolioButton(true);
    }
  };

  const handleFileChange = async (index: number, file: File, array: any[]) => {
    const maxSizeInBytes = 10 * 1024 * 1024;

    if (file.size >= maxSizeInBytes) {
      setSizeError(true);
      return;
    }

    const data: FormData = new FormData();

    const info = {
      certification: { id: array[index].id, fileId: uuidv4() },
    };

    data.append('info', JSON.stringify(info));

    data.append(info.certification.fileId, file, file.name);

    try {
      const response = await axiosInstance.post(
        API_ROUTES.PORTFOLIO.UPLOAD_FILES,
        data,
      );

      if (!response || response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setAddingFileSuccess(true);
      await response.data;
      getData();
    } catch (error) {
      console.error('Failed to upload portfolio files:', error);
    }
  };

  return (
    <Card
      className={`${popupVariant === 'adding' ? '' : 'user-popup'} p-3 popup-card`}
      ref={popupRef}
    >
      <Card.Body className="scrollable-card">
        {popupVariant !== 'adding' && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleCertificationSubmit(index);
            }}
          >
            {/* Certification Name */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Certification Name
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  required
                  type="text"
                  defaultValue={portfolio.certifications[index].name}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'name',
                      e.target.value,
                      certifications,
                      setCertifications,
                    )
                  }
                />
              </Col>
            </Form.Group>

            {/* Date Received */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Date Received
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  required
                  type="date"
                  defaultValue={formatDate(
                    portfolio.certifications[index].date,
                  )}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      'date',
                      e.target.value,
                      certifications,
                      setCertifications,
                    )
                  }
                  min={minDate}
                  max={maxDate}
                />
              </Col>
            </Form.Group>

            {/* Upload Certificate */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={12}>
                Upload Certificate
              </Form.Label>
              {certifications[index].filesUrl &&
                certifications[index].filesUrl.map(
                  (fileItem: any, fileIndex) => {
                    const fileName = fileItem?.name
                      ? fileItem.name.replace(/_/g, ' ')
                      : fileItem
                          .toString()
                          .replace(/_/g, ' ')
                          .split('/')
                          .pop()!;

                    return (
                      <div className="selected-file" key={fileIndex}>
                        <p className="file-name">{fileName}</p>
                        <Button
                          variant="danger"
                          className="prompt-delete-button"
                          onClick={() => handleDeleteFile(index)}
                        >
                          <DeleteIcon
                            style={{ width: '15px', height: '15px' }}
                          />
                        </Button>
                      </div>
                    );
                  },
                )}

              {savePortfolioButton ? (
                <Button disabled variant="secondary-outlined">
                  Please save a project to add file.
                </Button>
              ) : (
                <>
                  {success ? (
                    <Button disabled variant="secondary-outlined">
                      File successfully uploaded.
                    </Button>
                  ) : (
                    <>
                      {certifications[index].filesUrl &&
                        certifications[index].filesUrl.length < 1 && (
                          <div className="select-project-file">
                            <FileUploadComponent
                              handleFileChange={(file) =>
                                handleFileChange(index, file, certifications)
                              }
                            />
                          </div>
                        )}
                      {fileError && <Alert variant="danger">{fileError}</Alert>}
                      {sizeError && (
                        <Alert variant="danger">
                          {validationMessages.FILES_EXCEEDED_LIMIT_10MB}
                        </Alert>
                      )}
                    </>
                  )}
                </>
              )}
            </Form.Group>

            {/* Submit Button */}
            <Button variant="success" type="submit" disabled={disabled}>
              Submit
            </Button>
          </Form>
        )}

        {/* Adding New Certification */}
        {popupVariant === 'adding' && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitOfNewCertification(e);
            }}
          >
            <Card.Title className="mb-3">Add Certification</Card.Title>

            {/* Certification Name */}
            <Form.Group as={Row} className="mb-3">
              <Col sm={12}>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter a name"
                  onChange={(e) =>
                    handleNewItemFields(
                      'name',
                      e.target.value,
                      setNewCertification,
                    )
                  }
                />
              </Col>
            </Form.Group>

            {/* Date */}
            <Form.Group as={Row} className="mb-3">
              <Col sm={12}>
                <Form.Control
                  required
                  type="date"
                  placeholder="Enter a name"
                  onChange={(e) =>
                    handleNewItemFields(
                      'date',
                      e.target.value,
                      setNewCertification,
                    )
                  }
                  min={minDate}
                  max={maxDate}
                />
              </Col>
            </Form.Group>

            {/* Upload Placeholder */}
            <Form.Group as={Row} className="mb-3">
              <Button disabled variant="secondary-outlined">
                To add file please save portfolio first.
              </Button>
            </Form.Group>

            {/* Submit Button */}
            <Button variant="success" type="submit">
              Submit
            </Button>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};

export default CertificationsPopup;
