import React, { useState, useEffect } from 'react';
import { passwordRegex } from '../../config/regex';
import { API_ROUTES } from '../../config/api-routes';
import { validationMessages } from '../../config/validationMessages';
import './register.css';
import { Button, Card, CardBody, CloseButton } from 'react-bootstrap';
import axiosInstance from '../../api/api';

const {
  PASSWORDS_DO_NOT_MATCH,
  USERNAME_PASSWORD_CANT_BE_EMPTY,
  REGISTRATION_SUCCESSFUL,
  REGISTRATION_FAILED,
  ERROR_DURING_REGISTRATION,
  PASSWORD_MUST_CONTAIN_8_CHARACTERS,
  ERROR_DURING_RESEND_EMAIL,
} = validationMessages;

interface RegisterProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Register: React.FC<RegisterProps> = ({ setShowModal }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [message, setMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  const [canResend, setCanResend] = useState<boolean>(false);
  const [resendTimer, setResendTimer] = useState<number>(60);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    } else {
      setCanResend(true);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegister = async (event: any) => {
    event.preventDefault();
    const { email, password, confirmPassword } = formData;

    if (!email || !password) {
      setMessage(`${USERNAME_PASSWORD_CANT_BE_EMPTY}`);
      return;
    }

    if (password !== confirmPassword) {
      setMessage(`${PASSWORDS_DO_NOT_MATCH}`);
      return;
    }

    if (!passwordRegex.test(password)) {
      setPasswordErrorMessage(`${PASSWORD_MUST_CONTAIN_8_CHARACTERS}`);
      return;
    }

    try {
      const response = await axiosInstance.post(API_ROUTES.AUTH.REGISTER, {
        email,
        password,
      });

      const data = response.data;
      if (data.success) {
        setMessage('');
        setSuccessMessage(true);
        setCanResend(false);
        setResendTimer(60);
      } else {
        setMessage(`${REGISTRATION_FAILED} ${data.message}`);
      }
    } catch (error: any) {
      console.error('Error:', error);
      setMessage(`${ERROR_DURING_REGISTRATION}`);
    }
  };

  const handleResendEmail = async () => {
    try {
      const response = await axiosInstance.post(API_ROUTES.AUTH.RESEND_EMAIL, {
        email: formData.email,
        type: 'registration',
      });

      if (response.data.success) {
        setCanResend(false);
        setResendTimer(60);
      } else {
        setMessage(
          `Failed to resend verification email: ${response.data.message}`,
        );
      }
    } catch (error: any) {
      console.error('Error:', error);
      setMessage(`${ERROR_DURING_RESEND_EMAIL}`);
    }
  };

  return (
    <>
      {successMessage ? (
        <Card>
          <Card.Header
            className="p-3"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#14a077',
            }}
          >
            <Card.Title>{REGISTRATION_SUCCESSFUL}</Card.Title>
            <CloseButton onClick={() => setShowModal(false)} />
          </Card.Header>
          <CardBody className="p-4">
            {message && (
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            )}
            <Card.Text>
              Thank you for registering with us. To complete your registration,
              please verify your email address.
              <br />
              <br />
              We have sent a verification email to your inbox. Please check your
              email and follow the instructions to activate your account.
              <br />
              <br />
              If you do not see the email, please check your spam or junk
              folder.
              <br />
              <br />
              <strong>
                Please make sure you have received the verification email before
                closing this modal. Closing this modal will disable the "Resend
                Verification Email" button.
              </strong>
              <br />
              <br />
              {canResend ? (
                <Button
                  variant="success"
                  onClick={handleResendEmail}
                  className="submit-btn-sign-up"
                >
                  Resend Verification Email
                </Button>
              ) : (
                <Button
                  variant="success"
                  className="submit-btn-sign-up"
                  disabled
                >
                  Resend available in {resendTimer}s
                </Button>
              )}
            </Card.Text>
          </CardBody>
        </Card>
      ) : (
        <div className="container">
          <Card.Header
            style={{
              marginBottom: '.5rem',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Card.Title>Create your account</Card.Title>
            <CloseButton onClick={() => setShowModal(false)} />
          </Card.Header>
          {message && (
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          )}
          <form onSubmit={handleRegister}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email*
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password*
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              {passwordErrorMessage !== '' && (
                <div className="alert alert-danger" role="alert">
                  {passwordErrorMessage}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">
                Confirm Password<span>*</span>
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="d-grid gap-2">
              <button type="submit" className="submit-btn-sign-up">
                Register
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Register;
