import React, { useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import Logo from '../../images/SKIP.svg';
import { useNavigate } from 'react-router-dom';
import { API_ROUTES } from '../../config/api-routes';
import { validationMessages } from '../../config/validationMessages';
import './login.css';
import Register from '../Register';
import ModalWindow from '../modal';
import axiosInstance from '../../api/api';
import ForgotPassword from '../ForgotPassword';

const { USERNAME_PASSWORD_CANT_BE_EMPTY, ERROR_DURING_LOGIN, LOGIN_FAILED } =
  validationMessages;

const Login = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null,
  );
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();

  const handleModalClose = () => {
    setShowModal(false);
  };

  const openRegisterModal = () => {
    setModalContent(<Register setShowModal={setShowModal} />);
    setShowModal(true);
  };

  const openForgotPasswordModal = () => {
    setModalContent(<ForgotPassword setShowModal={setShowModal} />);
    setShowModal(true);
  };

  const handleLogin = async (event: any) => {
    event.preventDefault();
    setDisabled(true);
    setMessage('');
    setErrorMessage(false);
    setVerificationError(false);

    if (!email || !password) {
      setMessage(USERNAME_PASSWORD_CANT_BE_EMPTY);
      setDisabled(false);
      return;
    }
    try {
      const response = await axiosInstance.post(API_ROUTES.AUTH.LOGIN, {
        email,
        password,
      });
      const data = response.data;
      if (response && response.status === 200) {
        localStorage.setItem('accessToken', data.access_token);
        localStorage.setItem('refreshToken', data.refresh_token);
        setMessage('Login successful');
        setErrorMessage(false);

        navigate('/user-profile');
        setDisabled(false);
      } else {
        if (
          response.status === 400 &&
          data?.error &&
          data.error.includes('Verification Failed')
        ) {
          setVerificationError(true);
          setMessage('Please verify your email first!');
        } else {
          setMessage(data.message || LOGIN_FAILED);
          setErrorMessage(true);
        }
        setDisabled(false);
      }
    } catch (error: any) {
      console.error('Error_mm:', error);
      setMessage(ERROR_DURING_LOGIN);
      setErrorMessage(true);
      setDisabled(false);
    }
  };

  return (
    <div className="login-main-wrapper">
      <Col sm={{ span: 6, offset: 6 }} className="justify-content-center">
        <Card className="justify-content-center p-5 login-form">
          <img
            className="mb-3"
            alt="Logo"
            src={Logo}
            width={150}
            style={{ display: 'block', margin: 'auto' }}
          />
          <h4 className="card-title mb-4">LOG IN</h4>

          <form onSubmit={handleLogin}>
            <div className="form-group">
              {message && (
                <div
                  className={`alert ${errorMessage || verificationError ? 'alert-danger' : 'alert-success'}`}
                  role="alert"
                >
                  {message}
                </div>
              )}

              <label htmlFor="username">Email:</label>
              <input
                type="email"
                className="form-control"
                id="username"
                name="username"
                value={email}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <Button
              variant="success"
              type="submit"
              className="submit-btn-login login-form-button"
              disabled={disabled}
            >
              Sign in
            </Button>
            <div className="divider">
              <span style={{ color: '#727176' }}>or</span>
            </div>

            <div className="btn-box">
              <button
                type="button"
                className="login-to-register-btn"
                onClick={openRegisterModal}
              >
                Sign up now!
              </button>
              <button
                type="button"
                className="login-to-register-btn"
                onClick={openForgotPasswordModal}
              >
                Forgot password?
              </button>
            </div>
          </form>
        </Card>
      </Col>
      {showModal && (
        <ModalWindow showModal={showModal} handleModalClose={handleModalClose}>
          {modalContent}
        </ModalWindow>
      )}
    </div>
  );
};

export default Login;
