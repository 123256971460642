import axiosInstance from '../../api/api';
import { API_ROUTES } from '../../config/api-routes';

export const fetchUserData = async () => {
  try {
    const response = await axiosInstance.get(API_ROUTES.USER.GET_CURRENT_USER);

    const { user } = response.data;
    return user;
  } catch (error: any) {
    console.error('Error fetching user data:', error);
  }
};
