import React, { useState } from 'react';
import { emailRegex } from '../../config/regex';
import { validationMessages } from '../../config/validationMessages';
import { newReference } from '../Create-Portfolio/initialValues';
import { Portfolio, Reference } from '../Create-Portfolio/types';
import PortfolioItemsWrapper from '../Portfolio-Items-Wrapper';
import PrevAndNextButtons from '../Prev-Next-Buttons';
import ReferenceItem from './ReferenceItem';
import './References.css';

interface ReferenceProps {
  handleNext: () => void;
  handlePrevious: () => void;
  references: Reference[];
  setReferences: React.Dispatch<React.SetStateAction<Portfolio>>;
}

const {
  PROVIDE_EMAIL,
  PROVIDE_VALID_EMAIL,
  PROVIDE_NAME,
  PROVIDE_ROLE,
  PROVIDE_SCHOOL_OR_ORGANIZATION,
} = validationMessages;

const References: React.FC<ReferenceProps> = ({
  handleNext,
  handlePrevious,
  references,
  setReferences,
}) => {
  const [validationErrors, setValidationErrors] = useState<string[][]>(
    references.map(() => ['', '', '', '']),
  );

  const addReferenceItem = () => {
    const allReferences = [...references, newReference];
    setReferences((prevPortfolio) => ({
      ...prevPortfolio,
      references: allReferences,
    }));
    setValidationErrors((prev) => [...prev, ['', '', '', '']]);
  };

  const removeReferenceItem = (index: number) => {
    const updatedReferences = references.filter((_, i) => i !== index);
    setReferences((prevPortfolio) => ({
      ...prevPortfolio,
      references: updatedReferences,
    }));
    setValidationErrors((prev) => prev.filter((_, i) => i !== index));
  };

  const updateReferenceItem = (index: number, updatedReference: Reference) => {
    const updatedReferences = references.map((project, i) =>
      i === index ? updatedReference : project,
    );

    setReferences((prevPortfolio) => ({
      ...prevPortfolio,
      references: updatedReferences,
    }));
  };

  const validateReferenceItemForm = (index: number, reference: Reference) => {
    const updatedErrors = ['', '', '', ''];

    if (reference.name.trim() === '') {
      updatedErrors[0] = `${PROVIDE_NAME}`;
    }

    if (reference.email.trim() === '') {
      updatedErrors[1] = `${PROVIDE_EMAIL}`;
    } else if (!emailRegex.test(reference.email)) {
      updatedErrors[1] = `${PROVIDE_VALID_EMAIL}`;
    }

    if (reference.role.trim() === '') {
      updatedErrors[2] = `${PROVIDE_ROLE}`;
    }

    if (reference.organization.trim() === '') {
      updatedErrors[3] = `${PROVIDE_SCHOOL_OR_ORGANIZATION}`;
    }

    setValidationErrors((prev) =>
      prev.map((errors, i) => (i === index ? updatedErrors : errors)),
    );

    return updatedErrors.every((error) => error === '');
  };

  const validateReferenceField = (
    index: number,
    field: string,
    value: string,
  ) => {
    const updatedErrors = [...validationErrors[index]];

    if (field === 'name' && value.trim() === '') {
      updatedErrors[0] = `${PROVIDE_NAME}`;
    } else if (field === 'name') {
      updatedErrors[0] = '';
    }

    if (field === 'email' && value.trim() === '') {
      updatedErrors[1] = `${PROVIDE_EMAIL}`;
    } else if (field === 'email' && !emailRegex.test(value)) {
      updatedErrors[1] = `${PROVIDE_VALID_EMAIL}`;
    } else if (field === 'email') {
      updatedErrors[1] = '';
    }

    if (field === 'role' && value.trim() === '') {
      updatedErrors[2] = `${PROVIDE_ROLE}`;
    } else if (field === 'role') {
      updatedErrors[2] = '';
    }

    if (field === 'organization' && value.trim() === '') {
      updatedErrors[3] = `${PROVIDE_SCHOOL_OR_ORGANIZATION}`;
    } else if (field === 'organization') {
      updatedErrors[3] = '';
    }

    setValidationErrors((prev) =>
      prev.map((errors, i) => (i === index ? updatedErrors : errors)),
    );
  };

  const handleNextClick = () => {
    references.forEach((reference, index) => {
      validateReferenceItemForm(index, reference);
    });

    const isAnyReferenceInvalid = references.some(
      (reference, index) => !validateReferenceItemForm(index, reference),
    );

    if (!isAnyReferenceInvalid) {
      handleNext();
    }
  };

  return (
    <section>
      <div className="container my-4">
        <h2>References</h2>
        <h6>
          A list of individuals who can vouch for your skills, work ethic, and
          professional experience. These are typically former employers,
          colleagues, or mentors.
        </h6>
        {references.map((reference, index) => (
          <PortfolioItemsWrapper
            addItem={addReferenceItem}
            removeItem={removeReferenceItem}
            index={index}
            key={index}
            itemName="Reference"
          >
            <ReferenceItem
              reference={reference}
              errors={validationErrors[index]}
              updateReference={(updatedReference) =>
                updateReferenceItem(index, updatedReference)
              }
              validateReferenceField={validateReferenceField}
              index={index}
            />
          </PortfolioItemsWrapper>
        ))}
        <PrevAndNextButtons
          handleNext={handleNextClick}
          handlePrevious={handlePrevious}
        />
      </div>
    </section>
  );
};

export default References;
