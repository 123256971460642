import React, { useState } from 'react';
import { Col, Stack } from 'react-bootstrap';
import './Preview.css';

import { ReactComponent as UserIcon } from '../../images/user-in-circle.svg';
import { ReactComponent as MessageIcon } from '../../images/messages.svg';
import { ReactComponent as PromptIcon } from '../../images/search-prompts.svg';
import { ReactComponent as AnalyticsIcon } from '../../images/analytics.svg';
import { ReactComponent as PasswordIcon } from '../../images/lock.svg';
import { ReactComponent as LogoutIcon } from '../../images/logout.svg';
import { ReactComponent as CalendarIcon } from '../../images/calendar-event-fill.svg';
import { useNavigate } from 'react-router-dom';

type MenuType = 'user' | 'prompts' | null;

const Sidebar: React.FC = () => {
  const [openMenu, setOpenMenu] = useState<MenuType>(null);
  const navigate = useNavigate();

  const handleMenuClick = (menu: MenuType) => {
    // Toggle the clicked menu and close others
    setOpenMenu(openMenu === menu ? null : menu);
  };

  const handleNavigation = (path: string) => {
    navigate(path); // Navigate to the specified path
  };

  return (
    <Col xs="auto" className="sidebar-col">
      <Stack gap={4} className="sidebar-stack">
        <div
          className={`sidebar-icon-container ${openMenu === 'user' ? 'active' : ''}`}
          onClick={() => handleMenuClick('user')}
        >
          <UserIcon style={{ width: '30px', height: '30px' }} />
          {openMenu === 'user' && (
            <div className="menu-items">
              <div
                className="menu-item"
                onClick={() => handleNavigation('/user-profile')}
              >
                My Profile
              </div>
              <div
                className="menu-item"
                onClick={() => handleNavigation('/user-settings')}
              >
                Settings
              </div>
            </div>
          )}
        </div>

        {/* Prompts Menu */}
        <div
          className={`sidebar-icon-container ${openMenu === 'prompts' ? 'active' : ''}`}
          onClick={() => handleMenuClick('prompts')}
        >
          <PromptIcon style={{ width: '30px', height: '30px' }} />
          {openMenu === 'prompts' && (
            <div className="menu-items">
              <div
                className="menu-item"
                onClick={() => handleNavigation('/create-prompt')}
              >
                My Screen Prompts
              </div>
            </div>
          )}
        </div>
        <div className="sidebar-icon-container">
          <MessageIcon
            style={{ width: '30px', height: '30px' }}
            className="sidebar-icon"
          />
        </div>
        <div className="sidebar-icon-container">
          <CalendarIcon
            style={{ width: '30px', height: '30px' }}
            className="sidebar-icon"
          />
        </div>

        <div className="sidebar-icon-container">
          <AnalyticsIcon
            style={{ width: '30px', height: '30px' }}
            className="sidebar-icon"
          />
        </div>
        <div className="sidebar-icon-container">
          <PasswordIcon
            style={{ width: '30px', height: '30px' }}
            className="sidebar-icon"
          />
        </div>
        <div className="sidebar-icon-container">
          <LogoutIcon
            style={{ width: '30px', height: '30px' }}
            className="sidebar-icon"
          />
        </div>
      </Stack>
    </Col>
  );
};

export default Sidebar;
