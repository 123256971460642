import axiosInstance from '../../api/api';
import { API_ROUTES } from '../../config/api-routes';

export const fetchPortfolioData = async () => {
  try {
    const response = await axiosInstance.get(
      API_ROUTES.PORTFOLIO.GET_PORTFOLIO,
    );

    if (!response || response.status !== 200) {
      return;
    }

    const { portfolio } = response.data;
    return portfolio;
  } catch (error: any) {
    console.error('Error fetching portfolio data:', error);
    return;
  }
};

export const fetchPromptsData = async () => {
  try {
    const response = await axiosInstance.get(API_ROUTES.PROMPTS.GET_PROMPTS);

    if (!response || response.status !== 200) {
      return;
    }

    const prompts = response.data;
    return prompts;
  } catch (error: any) {
    console.error('Error fetching prompts data:', error);
    return;
  }
};
